import { applyMiddleware, compose, createStore } from "redux"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'  // defaults to localStorage for web and AsyncStorage for react-native
// import storage from 'redux-persist/lib/storage/session'

import multi from 'redux-multi'

import thunk from "redux-thunk"
import rootReducer from "./reducers"

export default (initialState = {}) => {
 // ======================================================
 // Middleware Configuration
 // ======================================================

 const middleware = [thunk, multi]

 let composeEnhancers = compose

 if (window.__DEV__) {
   const composeWithDevToolsExtension =
     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
   if (typeof composeWithDevToolsExtension === "function") {
     composeEnhancers = composeWithDevToolsExtension
   }
 }
 const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['addApartmentsModal', 'requestTourModal', 'extendedContext'] // navigation will not be persisted
};
 // ======================================================
 // Store Instantiation and HMR Setup
 // ======================================================
 const persistedReducer = persistReducer(persistConfig, rootReducer)


 let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)))
 let persistor = persistStore(store)
 return { store, persistor }
}
