import React, { Component } from 'react'
import config from '../../config'

class CurrencySwitcher extends Component {

  render() {
    const { experimentSearch, setExperimentSearch } = this.props
    const indexOption = config.CURRENCIES.findIndex(item => item === experimentSearch.currency_bias)
    const option = experimentSearch.currency_bias
    const newOption = indexOption + 2 <= config.CURRENCIES.length ? config.CURRENCIES[indexOption+1] : config.CURRENCIES[0]

    return(
      <div
        className={`${(experimentSearch.currency_bias || config.DEFAULT_CURRENCY) === option ? 'active ' : ''}pagination-row__item`}
        onClick={() =>
          setExperimentSearch({
            ...experimentSearch,
            currency_bias: newOption
          })}
        key={option}
        style={{width: '45px'}}
      >
        {config.CURRENCY_SIGNS[option]}{/*<Translate value={`experimentSearch.in ${option}`} />*/}
      </div>
    )
  }
}

export default CurrencySwitcher
