import React, {Component} from 'react'
// import moment from "moment/moment"

// import ExpansionPanel from '@material-ui/core/ExpansionPanel'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
// import Typography from '@material-ui/core/Typography'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import config from '../../config'

// import FindYourHomeQuiz from '../FindYourHomeQuiz'

export default class StartPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (<article>
              <h1 className='text-center'><span className='wayleap-logo'>Wayleap Киев</span></h1>
              {config.SHOW_SHIT && <p className='text-center'>Лучшие квартиры</p>}
              {/* <div style={{marginBottom: '5px'}}>When friends ask you how much does your apartment cost - they won't believe you, considering how much faster you found it. But we will, because we know how you did it:</div> */}
              <div style={{marginBottom: '5px'}}>Когда друзья спросят вас сколько вы платите за свою квартиру - они не поверят, учитывая как быстро вы ее нашли. Но мы поверим, зная как вы это сделали:</div>

              <a href={ '/rent' } className='link-to-search-page shine-animation'>
                <Button variant="contained" color="primary"><p>поиск квартир для аренды</p></Button>
              </a>

              {config.SHOW_SHIT && <a href={ '/sale' } className='link-to-search-page shine-animation'>
                <Button variant="contained"><p>Продажа</p></Button>
              </a>}

              {/* <div style={{marginBottom: '20px'}}>Find a rental in Kyiv tailored to you - describe it in the same way as you use Google, but feel free to be elaborate in as many details as possible, even if you know, that an apartment that is everything you want is improbable. And it is. It is improbable. But we'll find the solution that is as exciting as possible.</div> */}
              <div style={{marginBottom: '20px'}}>Найдите жилье в аренду в Киеве подобранное специально под вас - опишите то, что вы хотите, как вы бы это сделали в Google, но можете описать желания в стольки деталях, в скольки угодно, даже если знаете, что такое вряд ли найдется. И это верно, вряд ли такое найдется. Но мы найдем то решение, которое будет максимально захватывающим!</div>
              <div style={{marginBottom: '5px'}}>Written by Vitaliy Korbut: Social Mobility Researcher</div>
              <div style={{marginBottom: '10px'}}> 
              <span style={{'overflow': 'hidden'}}>
              <div style={{
    'background': '#E8E6D6',
    'border-radius': '2.5px',
    '-moz-border-radius': '2.5px',
    'height': '18px',
    'width': '24.5px',
    'overflow': 'hidden',
    'margin': '5px auto',
    'position': 'relative',
    'float':'left',
}}>
    <div style={{
          'border-right': '9px solid #9B9C91',
          'border-top': '6.5px solid transparent',
          'border-bottom': '6.5px solid transparent',
          'position': 'absolute',
          'left': '12px',
          'top': '5px',
    }}>
    </div>
    <div style={{
          'background': '#9B9C91',
          'width':'0.25px',
          'height':'13px',
          'position': 'absolute',
          'top': '11px',
          'left': '12px',
          'transform-origin': '50% 0',
          '-ms-transform-origin': '50% 0',
          '-webkit-transform-origin': '50% 0',
          'transform':'rotate(55.3deg)',
          '-ms-transform':'rotate(55.3deg)',
          '-webkit-transform':'rotate(55.3deg)',
    }}>
    </div>
    <div style={{
          'border-right': '3px solid #98110C',
          'border-top': '3px solid transparent',
          'width': '3px',
          'height':'25px',
          'position': 'absolute',
          'left': '21px',
    }}>
    </div>
    <div style={{
      'border-right': '3px solid #E41F1A',
      'border-bottom': '2px solid transparent',
      'height':'16px',
      'position': 'absolute',
      'top': '1px',
      'left': '22.5px',
      'transform-origin': '50% 0',
      '-ms-transform-origin': '50% 0',
      '-webkit-transform-origin': '50% 0',
      'transform':'rotate(55.3deg)',
      '-ms-transform':'rotate(55.3deg)',
      '-webkit-transform':'rotate(55.3deg)',
    }}>
    </div>
    <div style={{
      'border-left': '3px solid #E41F1A',
      'border-bottom': '2px solid transparent',
      'height':'16px',
      'position': 'absolute',
      'top': '1px',
      'left': '-1.5px',
      'transform-origin': '50% 0',
      '-ms-transform-origin': '50% 0',
      '-webkit-transform-origin': '50% 0',
      'transform':'rotate(-55.3deg)',
      '-ms-transform':'rotate(-55.3deg)',
      '-webkit-transform':'rotate(-55.3deg)',
    }}>
    </div>
    <div style={{
       'border-left': '3px solid #E41F1A',
       'border-top': '3px solid transparent',
       'width': '3px',
       'height':'25px',
       'position': 'absolute',
    }}>
    </div>
</div><div style={{'float':'left'}}>wayleap</div></span>
<div><i class="fa fa-telegram" style={{'font-size': '24px', 'color': '#0088cc', 'float': 'right'}}></i> <span style={{'float': 'right'}}>@vitkorbut</span></div></div>
            </article>)
  }
}
