const translationObject = {
  'ru-RU': {
    general: {
      yes: 'Да',
      no: 'Нет',
      iThinkYes: 'Думаю, да',
      iThinkNo: 'Думаю, нет',
      iDontKnow: 'Не знаю',
    },
    experimentSearchProduct: {
      bestApartments: 'Лучшее жильё',
      withMinimalCommute: 'Поиск жилья по лучшей цене и с минимальным временем в дороге',
      bestSearch: 'Крупнейший поиск квартир в Киеве по лучшей цене и с минимальным временем в дороге с 86 лучших сайтов Украины. Более 60 000 актуальных предложений',
      callFree: 'Позвоните опытному профессионалу по недвижимости Киева и он максимально упростит вам поиск. Бесплатно. Непредвзято. Идеально.',
      callFreePrice: 'Цена - это то, что вы платите. Ценность - это то, что вы получаете.',
      callFreeValueShort: 'Wayleap оценивает недвижимость по её способности помочь развиваться вам и вашей семье',
      callFreeValue: 'Wayleap оценивает недвижимость по её способности помочь развиваться вам и вашей семье, чтобы предоставить вам максимум ценности за ту цену, что вы платите. Позвоните в Wayleap, чтобы формализовать ваши желания, нужды и предпочтения.',
      callFreeSearch: 'С учётом этого, поиск недвижимости станет для вас понятным процессом, и вы чётко будете понимать за что платите, c уверенностью, что вы нашли лучшую для себя квартиру.',
    },
    amenity: {
      'Быстрое Питание': 'Быстрое Питание',
      'Магазин': 'Магазин',
      'Метро': 'Метро',
      'Школа': 'Школа',
      'Супермаркет': 'Супермаркет',
      'Фитнес': 'Фитнес',
      'Парк': 'Парк',
      'Транспорт': 'Транспорт',
      hours: 'ч',
      minutes: 'мин',
      minToSubway: 'мин. до м.',
    },
    listing: {
      UAH: 'грн',
      USD: '$',
      expandCommuteReport: 'Посмотреть затраты на дорогу' ,
      bedrooms: '%{count}-комн.',
      bedrooms_0: 'Студия',
      bedrooms_1: '%{count}-комн.',
      bathrooms: '%{count} ванных',
      bathrooms_0: 'нет ванной',
      bathrooms_1: '%{count} ванная',
      squareMetersShort: '%{count} м²',
      hoursPerMonth: 'часов в месяц',
      inTotalCommuteTime: 'сожители и их гости проведут в дороге',
      adSource: '<a href=%{href} rel=%{rel} target="_blank">%{name}</a>',
      showDetails: 'детали',
      hideDetails: 'скрыть детали',
      floor: 'Этаж  %{count}%{totalCount}',
      kitchenArea: 'Кухня',
      livingSpace: 'Жилая',
      ads_published_by_phone_number: 'Опубликовано объявлений этим же абонентом',
      rating: 'оценка: ',
      rentalCosts: 'Затраты на Аренду',
      showOnMap: 'на карте',
      month: 'мес'
    },
    exactPlaceSearch: {
      placeName: 'Имя либо название места',
      address: 'Адрес',
      work: 'работа',
      partnersWork: 'работа',
      family: 'семья',
      friends: 'друзья',
      school: 'школа',
      kindergarten: 'детский сад',
      uni: 'университет',
      other: 'другое',
      roundTripsPerWeek: 'посещений в неделю',
      membersPerTrip: 'по сколько человек',
    },
    experimentItem: {
      isInactive: 'неактуально',
      isScam: 'мошенничество',
      recommendation: 'Рекомендация',
      details: 'Подробнее..',
      hoursPerMonthInCommute: 'ч/мес',
      minutesPerWorkdayInCommute: 'мин/день',
      onMap: 'на карте',
      object: 'объект',
      Vitaliy: 'Виталий',
      yourInterestsWillBeRepresentedBy: 'Ваши интересы будет представлять',
      agentOfTheMonth: '(Агент Месяца)',
      nearbyAmenities: 'Ближайшая инфраструктура',
      showMore: 'в избранные',
      addToFavorites: 'в избранные',
      removeFromFavorites: 'из избранных',
      showLess: 'убрать',
      recover: 'вернуть',
      totallyRemove: 'убрать',
      itemWasDeleted: 'Это объявление было удалено вами из списка',
      book: 'записаться',
      bookATour: 'запись на просмотр',
      // bookATour: 'просмотр',
      compare: 'сравнить',
      call: 'позвонить',
      notShare: 'не делиться',
      share: 'поделиться',
      shareThisPage: 'поделиться этой страницей для совместного поиска дома'
    },
    experimentSearch: {
      next: 'далее',
      who: 'домохозяйство⁺',
      what: 'параметры',
      whither: 'возле⁺',
      where: 'где',
      near: 'инфраструктура⁺',
      priorities: 'приоритеты⁺',
      sharePage: 'поделиться этой страницей для совместного поиска дома',
      delayCall: 'пока не звонить',
      specifyPlaces: 'куда',
      extra: 'еще ',
      youVisit: 'будете ездить',
      nearby: 'какую инфраструктуру будете использовать',
      indicateTheDesiredPrice: 'ВАЖНО: укажите желаемую стоимость',
      indicateTheDesiredPricePerMonth: 'укажите желаемую стоимость за месяц',
      indicateTheDesiredPricePerDay: 'укажите желаемую стоимость в день',
      personKnowsHourWorth: 'укажите сколько стоит ваш час',
      rentLongTermApartmentsinKiev: 'аренда квартиры в Киеве',
      studioMin: '1',
      studio: 'Студия',
      price: 'цена',
      numberOfBedrooms: 'количество комнат',
      districts: 'предпочитаемые районы:',
      microdistricts: 'микрорайоны:',
      metroStations: 'станции метро:',
      minimum: 'минимум',
      min: 'мин',
      max: 'макс',
      perMonthShort: '/мес',
      perHourShort: '/час',
      whoWillHoldHouse: 'кто будет проживать ',
      selectAllThatApply: ' (выберите все что подходит)',
      exactPlaceSearchesExplanation: 'Куда нужно ездить: ',
      place: 'Место',
      roundTripsPerWeek: 'Поездок туда-обратно в неделю',
      membersPerTrip: 'Участников на поездку',
      search: 'Поиск',
      showMore: 'Подробнее',
      limitedScopeAlgorithm: 'Только цена, время в дороге, метраж, возраст дома, безопасность места и качество окружающей транспортной, социальной, образовательной и бизнес инфраструктуры учитывались при оценке. Обратите особое внимание 1) на качество внутриквартирных удобств и материалов, отвечают ли они вашим стандартам 2) how expensive and time-intensive it is to tweak it to your needs, if at all possible 3) шум от соседей и дорог, 4) возраст ремонта и техники.',
      optimisticTrafficAssumption: 'При оптимистичном условии поездок на машине вне часов-пик.',
      moreDetailsNeeded: 'Чтобы улучшить качество ранжирования, предоставьте больше деталей и повторите поиск:',
      currentEarnings: 'Сколько вы можете зарабатывать в час в настоящее время?',
      earningsExpectation: 'Сколько вы ожидаете зарабатывать в час в будущем(5-10 лет)?',
      isSameLineOfWorkInFuture: 'Будет ли эта та же карьера, что и сейчас. Получаете ли вы сейчас ключевые навыки необходимые для работы в будущем?',
      isFinancingAvailableForLineOfWorkSwitch: 'Можете ли вы получить финансирование в ближайшее время, для того чтобы поменять карьеру или учиться будущей работе?',
      directCostsHowDoesItWork: 'Как это работает?',
      changeCity: 'сменить город',
      'is_household_with_children_under_school_age': 'с детьми дошкольного возраста',
      'is_household_with_children_in_school': 'с детьми школьного возраста',
      'is_household_a_couple': 'пара',
      'is_household_pet_owner': 'с животными',
      'is_household_with_student': 'студент',
      'is_household_with_retiree': 'пенсионер',
      'is_household_a_group': 'группа',
      'is_household_a_male': 'один мужчина',
      'is_household_a_female': 'одна женщина',
      'is_household_with_car': 'с машиной',
      number_of_people_per_household: 'сколько человек',
      'long-term': 'на длительный срок',
      'short-term': 'на короткий срок',
      'For Rent': 'аренда',
      'For Sale': 'покупка',
      toSale: 'купить квартиру',
      toRent: 'снять квартиру',
      rent: 'аренда',
      sale: 'покупка',
      'apartment': 'квартиры',
      'room': 'комнату',
      'in UAH': 'гривен',
      'in RUB': 'рублей',
      'in USD': 'долларов',
      'in EUR': 'евро',
      'per hour': 'в час',
      'per month': 'в месяц',
      // showSearchSettings: 'доп инструменты (макс цена)',
      showSearchSettings: 'доп инструменты (возле метро?, макс цена)',
      notShowSearchSettings: 'скрыть',
      pricePerMonthMin: 'Цена за месяц от:',
      pricePerMonthMax: 'Цена за месяц до:',
      rooms: 'комнат',
      sqwMet: 'м² ',
      bedroomsMin: 'Комнат Минимум:',
      bedroomsMax: 'Комнат Максимум:',
      squareMetersMin: 'Площадь Минимум:',
      squareMetersMax: 'Площадь Максимум:',
      mailMe: 'подписаться и получать квартиры на почту по этому поиску',
      notMailMe: 'отписаться от рассылки',
      notNecessary: 'не нужно новых',
      send: 'подписаться на поиск',
      notSend: 'отписаться',
      asAppears: 'по мере появления',
      onceADay: 'раз в день',
      morePreferences: 'детальнее',
      opportunityCostOfOneHour: 'альтернативные издержки одного часа в денежном эквиваленте',
      costOfOneHour: 'сколько вы зарабатываете сейчас',
      costOfOneHourInTheFuture: 'сколько вы планируете зарабатывать',
      seeWhatThisMeans: 'посмотреть что это значит',
      seeHowThisIsUsed: 'посмотреть как это используется',
      AlternaticeCosts: 'к какому доходу в час вы стремитесь в ближайщем будущем / Во сколько вы оцениваете свой час в денежном эквиваленте.',
      Roughly: 'Ориентировочно это ваш потенциальный доход в ближайщем будущем.',
      toCalculateOptimalTradeOffs: 'для выбора оптимальных компромиссов при подсчетах',
      step: 'шаг',
    },
    contextSection: {
      'работа': 'работы',
      'семья': 'семьи',
      'друзья': 'друзей',
      'школа': 'школы',
      'детский сад': 'детского сада',
      'университет': 'университета',
    },
    compareSection: {
      compareApartments: 'Сравнить варианты',
    },
    experimentList: {
      showOnMap: 'Показать на карте',
      noResults: 'Нет результатов.',
      searchCallToAction: 'Осуществите поиск чтобы найти недвижимость',
      resultsCount: ' %{experiment_count} результатов из %{total_count} ',
      executionTime: '(%{execution_time} сек)',
      relevantSuggestions: '%{number_of_suggestions} релевантных предложения)',
      onlyWorthwhile: ' Некоторые записи скрыты, чтобы показать наиболее релевантные результаты.',
      x: 'Your search - ]b]e]s]t]-]a]p]a]r]t]m]e]n]t]s]-]i]n]-]m]o]s]c]o]w] - did not match any options. Suggestions: Make sure that all words are spelled correctly. Try different keywords. Try more general keywords. Try fewer keywords. In order to show you the most relevant results, we have omitted some entries very similar to the 90 already displayed. If you like, you can repeat the search with the omitted results included.',
      resultsForUltraCheapFlatsListPartOne: 'Wayleap собирает базу квартир со всех источников, включая все известные украинские сайты и агенства. Здесь список: ',
      resultsForUltraCheapFlatsListPartTwo: '. Порядка 11-17 тысяч квартир представлены для сдачи в Киеве.',
      resultsForUltraCheapFlatsOnly: 'ТОЛЬКО 0.25% объявлений имеют цену 6000UAH. Не найдено квартир с ценой ниже 6000UAH, такие квартиры можно найти только по личному знакомству с хозяином.',
      resultsForUltraCheapFlatsSave: 'Стоит учивать это при составлении бюджета. Пожалуйста, имейте в виду, что на других сайтах есть мошенники, которые пытаются сдать в аренду квартиры, особенно по ценам в 6000 грн и 9000 грн. Никогда не вносите предоплату ни под каким предлогом. Wayleap гарантирует, что здесь вы на 100% защищены.',
      websites: 'веб-сайтов'
    },
    cities: {
      'Moscow': 'Москва',
      'in Moscow': ' в Москве ',
      'St Petersburg': 'Санкт-Петербург',
      'in St Petersburg': ' в Санкт-Петербурге ',
      'Kiev': 'Киев',
      'in Kiev': ' в Киеве ',
    },
    unsupportedLocation: {
      unfortunately: 'К сожалению ',
      isNotSupportedAtTheMoment: ' не поддерживается в данный момент.',
      selectAnotherCityIfYouWantToRentOrBuyProperty: 'Выберите другой город, если вы хотите снять или купить жильё.',
    },
    error: {
      unableToContactServer: 'Приложение, похоже, грузится дольше, чем обычно.',
      unableToLoadPage: 'Похоже, что страница не может быть загружена. Возможно, объявление удалено или помечено как мошенническое. Попробуйте повторить поиск еще раз'
    },
    published: {
      published: 'Опубликовано',
      // daysAgo: '<table><tbody><tr><th>Dnei nazad:</th><td>%{count}</td></tr></tbody></table>',
      // hoursAgo: '<table><tbody><tr><th>4asov nazad:</th><td>%{count}</td></tr></tbody></table>',
      // minutesAgo: '<table><tbody><tr><th>Minut nazad:</th><td>%{count}</td></tr></tbody></table>',
      daysAgo: '%{count} дней назад',
      hoursAgo: '%{count} часов назад',
      minutesAgo: '%{count} минут назад',
      // daysAgo: '%{count} дней',
      // hoursAgo: '%{count} часов',
      // minutesAgo: '%{count} минут',
    },
    placeApartments: {
      place: 'Разместите бесплатное объявление на Wayleap и вы сможете быстро сдать квартиру надежным квартирантам.',
      adv: 'Разрекламируем на 32 самых посещаемых сайтах, включая olx.ua и lun.ua, а затем подберем клиентов, которыми вы будете довольны.',
      photo: 'При желании, профессиональный фотограф выедет и сделает качественные фотографии квартиры.',
      demand: '',
      phone: 'Достаточно позвонить по телефону %{phone} и сообщить о том, что вы хотите сдать квартиру.',
      freePartOne: 'Для вас это ',
      freePartTwo: ', Wayleap получит одноразовую комиссию от арендатора*, благодаря чему будет улучшаться сервис.',
      absolutelyFree: 'абсолютно бесплатно',
      call: 'Позвоните по телефону и сообщите о том, что вы хотите сдать квартиру [ +380 66 851 43 59 ]',
      star: '*в размере 25-50% от стоимости квартиры в зависимости от того, будет ли присутствовать агент со стороны квартиросъемщика.',
    },
  },
  'uk-UA': {
    general: {
      yes: 'Да',
      no: 'Нет',
      iThinkYes: 'Думаю, да',
      iThinkNo: 'Думаю, нет',
      iDontKnow: 'Не знаю',
    },
    experimentSearchProduct: {
      bestApartments: 'Лучшее жильё',
      withMinimalCommute: 'Научный подход к поиску лучшего жилья',
      bestSearch: 'Крупнейший поиск квартир в Киеве по лучшей цене и с минимальным временем в дороге с 86 лучших сайтов Украины. Более 60 000 актуальных предложений',
      callFree: 'Позвоните нам и мы максимально упростим поиск идеальной для вас квартиры. Бесплатно. Непредвзято.',
      callFreePrice: 'Цена - это то, что вы платите. Ценность - это то, что вы получаете.',
      callFreeValueShort: 'Wayleap оценивает недвижимость по её способности помочь развиваться вам и вашей семье',
      callFreeValue: 'Wayleap оценивает недвижимость по её способности помочь развиваться вам и вашей семье, чтобы предоставить вам максимум ценности за ту цену, что вы платите. Позвоните нам и мы свяжем вас с профессионалом, чтобы формализовать ваши желания, нужды и предпочтения.',
      callFreeSearch: 'С учётом этого, мы сделаем, чтобы поиск недвижимости стал для вас понятным процессом, и вы чётко понимали за что платите с уверенностью, что вы нашли лучшую для себя квартиру.',
    },
    amenity: {
      'Быстрое Питание': 'Быстрое Питание',
      'Магазин': 'Магазин',
      'Метро': 'Метро',
      'Школа': 'Школа',
      'Супермаркет': 'Супермаркет',
      'Фитнес': 'Фитнес',
      'Парк': 'Парк',
      'Транспорт': 'Транспорт',
      hours: 'ч',
      minutes: 'мин',
      minToSubway: 'мин. до м.',
    },
    listing: {
      expandCommuteReport: 'Посмотреть затраты на дорогу' ,
      bedrooms: '%{count}-комн.',
      bedrooms_0: 'Студия',
      bedrooms_1: '%{count}-комн.',
      bathrooms: '%{count} ванных',
      bathrooms_0: 'нет ванной',
      bathrooms_1: '%{count} ванная',
      squareMetersShort: '%{count} м²',
      hoursPerMonth: 'часов в месяц',
      inTotalCommuteTime: 'сожители и их гости проведут в дороге',
      adSource: '<a href=%{href} rel=%{rel} target="_blank">%{name}</a>',
      showDetails: 'детали',
      hideDetails: 'скрыть детали',
      floor: 'Этаж  %{count}%{totalCount}',
      kitchenArea: 'Кухня',
      livingSpace: 'Жилая',
      ads_published_by_phone_number: 'Опубликовано объявлений этим же абонентом',
      rating: 'оценка: ',
      rentalCosts: 'Затраты на Аренду',
      showOnMap: 'на карте',
      month: 'мес'
    },
    exactPlaceSearch: {
      placeName: 'Имя либо название места',
      address: 'Адрес',
      work: 'работа',
      "partner's work": 'работа партнера',
      "family": 'семья',
      "friends": 'друзья',
      "school": 'школа',
      "kindergarten": 'детский сад',
      "uni": 'университет',
      other: 'другое',
      roundTripsPerWeek: 'посещений в неделю',
      membersPerTrip: 'по сколько человек',
    },
    experimentItem: {
      isInactive: 'неактуально',
      isScam: 'мошенничество',
      recommendation: 'Рекомендация',
      details: 'Подробнее..',
      hoursPerMonthInCommute: 'ч/мес',
      minutesPerWorkdayInCommute: 'мин/день',
      onMap: 'на карте',
      object: 'объект',
      Vitaliy: 'Виталий',
      book: 'записаться',
      yourInterestsWillBeRepresentedBy: 'Ваши интересы будет представлять',
      agentOfTheMonth: '(Агент Месяца)',
      nearbyAmenities:'Ближайшая инфраструктура',
      showMore: 'в избранные',
      addToFavorites: 'в избранные',
    },
    experimentSearch: {
      next: 'далее',
      who: 'домохозяйство⁺',
      what: 'параметры',
      whither: 'возле⁺',
      where: 'где',
      near: 'инфраструктура⁺',
      priorities: 'приоритеты⁺',
      sharePage: 'поделиться этой страницей для совместного поиска дома',
      specifyPlaces: 'куда',
      extra: 'еще ',
      youVisit: 'будете ездить',
      nearby: 'какую инфраструктуру будете использовать',
      indicateTheDesiredPrice: 'укажите желаемую стоимость',
      indicateTheDesiredPricePerMonth: 'укажите желаемую стоимость за месяц',
      indicateTheDesiredPricePerDay: 'укажите желаемую стоимость в день',
      personKnowsHourWorth: 'укажите сколько стоит ваш час',
      rentLongTermApartmentsinKiev: 'аренда квартиры в Киеве',
      studioMin: '1',
      studio: 'Студия',
      price: 'цена',
      numberOfBedrooms: 'количество комнат',
      min: 'мин',
      max: 'макс',
      perMonthShort: '/мес',
      perHourShort: '/час',
      whoWillHoldHouse: 'кто будет проживать ',
      selectAllThatApply: ' (выберите все что подходит)',
      exactPlaceSearchesExplanation: 'Куда нужно ездить:',
      place: 'Место',
      roundTripsPerWeek: 'Поездок туда-обратно в неделю',
      membersPerTrip: 'Участников на поездку',
      search: 'Поиск',
      limitedScopeAlgorithm: 'Только цена, время в дороге, метраж, возраст дома, безопасность места и качество окружающей транспортной, социальной, образовательной и бизнес инфраструктуры учитывались при оценке. Обратите особое внимание 1) на качество внутриквартирных удобств и материалов, отвечают ли они вашим стандартам 2) how expensive and time-intensive it is to tweak it to your needs, if at all possible 3) шум от соседей и дорог, 4) возраст ремонта и техники.',
      optimisticTrafficAssumption: 'При оптимистичном условии поездок на машине вне часов-пик.',
      moreDetailsNeeded: 'Чтобы улучшить качество ранжирования, предоставьте больше деталей и повторите поиск:',
      currentEarnings: 'Сколько вы можете зарабатывать в час в настоящее время?',
      earningsExpectation: 'Сколько вы ожидаете зарабатывать в час в будущем(5-10 лет)?',
      isSameLineOfWorkInFuture: 'Будет ли эта та же карьера, что и сейчас. Получаете ли вы сейчас ключевые навыки необходимые для работы в будущем?',
      isFinancingAvailableForLineOfWorkSwitch: 'Можете ли вы получить финансирование в ближайшее время, для того чтобы поменять карьеру или учиться будущей работе?',
      directCostsHowDoesItWork: 'Как это работает?',
      changeCity: 'сменить город',
      'is_household_with_children_under_school_age': 'с детьми дошкольного возраста',
      'is_household_with_children_in_school': 'с детьми школьного возраста',
      'is_household_a_couple': 'пара',
      'is_household_pet_owner': 'с животными',
      'is_household_with_student': 'студент',
      'is_household_with_retiree': 'пенсионер',
      'is_household_a_group': 'группа',
      'is_household_a_male': 'один мужчина',
      'is_household_a_female': 'одна женщина',
      'is_household_with_car': 'с машиной',
      number_of_people_per_household: 'сколько человек',
      'long-term': 'на длительный срок',
      'short-term': 'на короткий срок',
      'For Rent': 'аренда',
      'For Sale': 'покупка',
      'apartment': 'квартиры',
      'room': 'комнату',
      'in UAH': 'гривен',
      'in RUB': 'рублей',
      'in USD': 'долларов',
      'in EUR': 'евро',
      'per hour': 'в час',
      'per month': 'в месяц',
      // showSearchSettings: 'доп инструменты (макс цена)',
      showSearchSettings: 'доп инструменты (возле метро?, макс цена)',
      pricePerMonthMin: 'Цена за месяц от:',
      pricePerMonthMax: 'Цена за месяц до:',
      bedroomsMin: 'Комнат Минимум:',
      bedroomsMax: 'Комнат Максимум:',
      squareMetersMin: 'Площадь Минимум:',
      squareMetersMax: 'Площадь Максимум:',
      mailMe: 'подписаться и получать квартиры на почту по этому поиску',
      notMailMe: 'отписаться от рассылки',
      notNecessary: 'не нужно новых',
      send: 'подписаться на поиск',
      asAppears: 'по мере появления',
      onceADay: 'раз в день',
      opportunityCostOfOneHour: 'альтернативные издержки одного часа в денежном эквиваленте',
      seeWhatThisMeans: 'посмотреть что это значит',
      seeHowThisIsUsed: 'посмотреть как это используется',
      AlternaticeCosts: 'к какому доходу в час вы стремитесь в ближайщем будущем / Во сколько вы оцениваете свой час в денежном эквиваленте.',
      Roughly: 'Ориентировочно это ваш потенциальный доход в ближайщем будущем.',
      toCalculateOptimalTradeOffs: 'для выбора оптимальных компромиссов при подсчетах',
      step: 'шаг',
    },
    contextSection: {
      'работа': 'работы',
      'семья': 'семьи',
      'друзья': 'друзей',
      'школа': 'школы',
      'детский сад': 'детского сада',
      'университет': 'университета',
    },
    compareSection: {
      compareApartments: 'Сравнить варианты',
    },
    experimentList: {
      showOnMap: 'Показать на карте',
      noResults: 'Нет результатов.',
      searchCallToAction: 'Осуществите поиск чтобы найти недвижимость',
      resultsCount: ' %{experiment_count} результатов из %{total_count} ',
      executionTime: '(%{execution_time} сек)',
      relevantSuggestions: '%{number_of_suggestions} релевантных предложения)',
      onlyWorthwhile: ' Некоторые записи скрыты, чтобы показать наиболее релевантные результаты.',
      x: 'Your search - ]b]e]s]t]-]a]p]a]r]t]m]e]n]t]s]-]i]n]-]m]o]s]c]o]w] - did not match any options. Suggestions: Make sure that all words are spelled correctly. Try different keywords. Try more general keywords. Try fewer keywords. In order to show you the most relevant results, we have omitted some entries very similar to the 90 already displayed. If you like, you can repeat the search with the omitted results included.',
      resultsForUltraCheapFlatsListPartOne: 'Wayleap собирает базу квартир со всех источников, включая все известные украинские сайты и агенства. Здесь список: ',
      resultsForUltraCheapFlatsListPartTwo: '. Порядка 11-17 тысяч квартир представлены для сдачи в Киеве.',
      resultsForUltraCheapFlatsOnly: 'ТОЛЬКО 0.25% объявлений имеют цену 6000UAH. Не найдено квартир с ценой ниже 6000UAH, такие квартиры можно найти только по личному знакомству с хозяином.',
      resultsForUltraCheapFlatsSave: 'Стоит учивать это при составлении бюджета. Пожалуйста, имейте в виду, что на других сайтах есть мошенники, которые пытаются сдать в аренду квартиры, особенно по ценам в 6000 грн и 9000 грн. Никогда не вносите предоплату ни под каким предлогом. Мы гарантируем, что здесь вы на 100% защищены.',
      websites: 'веб-сайтов'
    },
    cities: {
      'Moscow': 'Москва',
      'in Moscow': ' в Москве ',
      'St Petersburg': 'Санкт-Петербург',
      'in St Petersburg': ' в Санкт-Петербурге ',
      'Kiev': 'Киев',
      'in Kiev': ' в Киеве ',
    },
    unsupportedLocation: {
      unfortunately: 'К сожалению',
      isNotSupportedAtTheMoment: 'не поддерживается в данный момент.',
      selectAnotherCityIfYouWantToRentOrBuyProperty: 'Выберите другой город, если вы хотите снять или купить жильё.',
    },
    error: {
      unableToContactServer: 'Приложение, похоже, грузится дольше, чем обычно.',
    },
    published: {
      published: 'Опубликовано',
      // daysAgo: '<table><tbody><tr><th>Dnei nazad:</th><td>%{count}</td></tr></tbody></table>',
      // hoursAgo: '<table><tbody><tr><th>4asov nazad:</th><td>%{count}</td></tr></tbody></table>',
      // minutesAgo: '<table><tbody><tr><th>Minut nazad:</th><td>%{count}</td></tr></tbody></table>',
      daysAgo: 'Дней Назад %{count}',
      hoursAgo: 'Часов Назад %{count}',
      minutesAgo: 'Минут Назад %{count}',    }
  },
  'en-US': {
    general: {
      yes: 'Yes',
      no: 'No',
      iThinkYes: 'I think, Yes',
      iThinkNo: 'I think, No',
      iDontKnow: 'I don\'t know',
    },
    experimentSearchProduct: {
      bestApartments: 'Best Apartments',
      withMinimalCommute: 'Scientific approach to apartment search. Best Accomodation with Minimal Commute',
      callFreePrice: 'Price is what you pay. Value is what you get.',
      callFreeValueShort: 'Wayleap measures apartments by their ability of helping you and your family',
      callFreeValue: 'Wayleap measures apartments by their ability of helping you and your family in upwards social mobility to give you the best value for the price you pay. Give us a call and we’ll match you with a professional to formalize your wants, needs and preferences.',
      callFreeSearch: 'With this, we’ll make a property search a clear process for you with clarity of what exactly you pay for with confidence in that you found the best home for you.',
    },
    amenity: {
      'Быстрое Питание': 'Fast Food',
      'Магазин': 'Convenience',
      'Метро': 'Subway',
      'Школа': 'School',
      'Супермаркет': 'Supermarket',
      'Фитнес': 'Fitness Centre',
      'Парк': 'Park',
      'Транспорт': 'Public Transit',
      hours: 'h',
      minutes: 'min',
      minToSubway: 'min to subway',
    },
    listing: {
      expandCommuteReport: 'Expand commute report',
      bedrooms: '%{count}br',
      bedrooms_0: 'Studio',
      bedrooms_1: '%{count}br',
      bathrooms: '%{count} bathrooms',
      bathrooms_0: 'no bathrooms',
      bathrooms_1: '%{count} bathroom',
      squareMetersShort: '%{count} m²',
      hoursPerMonth: 'hours per month',
      inTotalCommuteTime: 'of commute by cohabitants and their guests',
      adSource: '<a href=%{href} rel=%{rel} target="_blank">%{name}</a>',
      hideDetails: 'Hide Details',
      showDetails: 'Show Details',
      floor: 'Floor %{count}%{totalCount}',
      kitchenArea: 'Kitchen Area',
      livingSpace: 'Living Space',
      ads_published_by_phone_number: '',
      rating: 'rating: ',
      rentalCosts: 'Rental Costs',
      showOnMap: 'show on map',
    },
    exactPlaceSearch: {
      placeName: 'Name',
      address: 'Address',
      work: 'work',
      "partner's work": "partner's work",
      "family/ friends": 'family/friends',
      "school/ uni": 'school/uni',
      other: 'other',
      roundTripsPerWeek: 'round trips per week',
      membersPerTrip: 'members per trip',
    },
    experimentItem: {
      recommendation: 'Recommendation',
      details: 'details..',
      hoursPerMonthInCommute: 'h/month in commute',
      minutesPerWorkdayInCommute: 'min/day',
      onMap: 'on map',
      object: 'object',
      Vitaliy: 'Vitaliy',
      yourInterestsWillBeRepresentedBy: 'Your interests will be represented by',
      agentOfTheMonth: '(Agent of the Month)',
      nearbyAmenities:'Nearby amenities',
    },
    experimentSearch: {
      who: 'who',
      whither: 'whither',
      where: 'where',
      near: 'near',
      personal: 'личное',
      specifyPlaces: 'specify places',
      extra: ' ',
      youVisit: 'you visit',
      indicateTheDesiredPrice: 'indicate the desired price',
      indicateTheDesiredPricePerMonth: 'indicate the desired price per month',
      indicateTheDesiredPricePerDay: 'indicate the desired price per day',
      personKnowsHourWorth: 'what is one hour worth',
      rentLongTermApartmentsinKiev: 'rent apartments in Kiev',
      studioMin: '0',
      studio: 'Studio',
      price: 'price',
      numberOfBedrooms: 'number of bedrooms',
      min: 'мин',
      max: 'макс',
      perMonthShort: '/mo',
      perHourShort: '/hour',
      whoWillHoldHouse: 'Tell us who will hold house ',
      nearby: 'I need to be near…',
      place: 'Place',
      roundTripsPerWeek: 'Round Trips per Week',
      membersPerTrip: 'Members per Trip',
      search: 'Search',
      limitedScopeAlgorithm: 'Only price and commute are taken into consideration for ranking.',
      optimisticTrafficAssumption: 'Assuming commute by car during optimistic non-rush hour traffic.',
      moreDetailsNeeded: 'To refine results please provide more details and redo search:',
      currentEarnings: 'How much can you earn per hour currently?',
      earningsExpectation: 'How much do you expect to earn per hour in the future(5-10 years)?',
      isSameLineOfWorkInFuture: 'Is it the same career, do you learn core skills needed in your future line of work/activity?',
      isFinancingAvailableForLineOfWorkSwitch: 'Are you able to find financing in the near future for your career switch to work or study on your future line of work/activity? ',
      directCostsHowDoesItWork: 'How does it work?',
      number_of_people_per_household: 'how many people',
      'is_household_with_children': 'with children',
      'is_household_a_couple': 'couple',
      'is_household_pet_owner': 'pet owner',
      'is_household_with_student': 'student',
      'is_household_with_retiree': 'retiree',
      'is_household_a_group': 'group',
      'is_household_a_male': 'male',
      'is_household_a_female': 'female',
      changeCity: 'change city',
      'long-term': 'long-term',
      'short-term': 'short-term',
      'For Rent': 'For Rent',
      'For Sale': 'For Sale',
      'apartment': 'apartment',
      'room': 'room',
      'in UAH': 'UAH',
      'in USD': 'USD',
      'in RUB': 'RUB',
      'in EUR': 'EUR',
      'per hour': 'в час',
      'per month': 'в месяц',
      // showSearchSettings: 'show search settings(max price)',
      showSearchSettings: 'show search settings(near metro station?, max price)',
      pricePerMonthMin: 'Price per month min:',
      pricePerMonthMax: 'Price per month max:',
      bedroomsMin: 'Bedrooms Minimum:',
      bedroomsMax: 'Bedrooms Maximum:',
      squareMetersMin: 'Square Meters Minimum:',
      squareMetersMax: 'Square Meters Maximum:',
      opportunityCostOfOneHour: 'opportunity cost of one hour',
      seeWhatThisMeans: 'see what this means',
      seeHowThisIsUsed: 'see how this is used',
      AlternaticeCosts: 'How much do you lose in expected earnings by not utilising one hour.',
      Roughly: 'Roughly, it is your estimated earning potential in the nearest future.',
      toCalculateOptimalTradeOffs: 'to calculate optimal trade-offs',
      step: 'step',
    },
    compareSection: {
      compareApartments: 'compare apartments',
    },
    experimentList: {
      showOnMap: 'show on map',
      noResults: 'No Results.',
      searchCallToAction: 'Search to find properties.',
      resultsCount: '%{experiment_count} results out of %{total_count} ',
      executionTime: '%{execution_time} sec)',
      relevantSuggestions: '%{number_of_suggestions} relevant suggestions)',
      onlyWorthwhile: ' In order to show you the most relevant results, we have omitted some entries.',
      x: 'Your search - ]b]e]s]t]-]a]p]a]r]t]m]e]n]t]s]-]i]n]-]m]o]s]c]o]w] - did not match any options. Suggestions: Make sure that all words are spelled correctly. Try different keywords. Try more general keywords. Try fewer keywords. In order to show you the most relevant results, we have omitted some entries very similar to the 90 already displayed. If you like, you can repeat the search with the omitted results included.',
      resultsForUltraCheapFlatsListPartOne: 'Wayleap aggregates listings from all ukrainian websites and agencies. Here is the list of ',
      resultsForUltraCheapFlatsListPartTwo: ' we source our apartments. Usually there are about 11k-17k apartments for rent in Kyiv.',
      resultsForUltraCheapFlatsOnly: 'ONLY 0.25% of listings in Kyiv are priced at 7000UAH. No apartments where found below 6000 UAH. Please consider increasing your budget.',
      resultsForUltraCheapFlatsSave: 'Please beware that there are some scammers on other sites that try to rent out apartments, especially at the price point of 6000UAH. By searching for apartments on Wayleap we guarantee, that you\'ll be 100% safe.',
      websites: 'websites',
    },
    cities: {
      'Moscow': 'Moscow',
      'in Moscow': ' in Moscow ',
      'St Petersburg': 'St Petersburg',
      'in St Petersburg': 'in St Petersburg ',
      'Kiev': 'Kiev',
      'in Kiev': ' in Kiev ',
    },
    unsupportedLocation: {
      unfortunately: 'Unfortunately',
      isNotSupportedAtTheMoment: 'is not supported at the moment.',
      selectAnotherCityIfYouWantToRentOrBuyProperty: 'Select another city if you want to rent or buy property.',
    },
    error: {
      unableToContactServer: 'This seems to be taking longer than normal.',
    },
    published: {
      published: 'Published',
      // daysAgo: '<table><tbody><tr><th>Days ago:</th><td>%{count}</td></tr></tbody></table>',
      // hoursAgo: '<table><tbody><tr><th>Hours ago:</th><td>%{count}</td></tr></tbody></table>',
      // minutesAgo: '<table><tbody><tr><th>Minutes ago:</th><td>%{count}</td></tr></tbody></table>',
      daysAgo: '%{count} Days Ago',
      hoursAgo: '%{count} Hours Ago',
      minutesAgo: '%{count} Minutes Ago',
    }
  }
}

export default translationObject
