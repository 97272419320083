import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'
import {withRouter} from 'react-router-dom'
import togleShowAllMap from '../../components/TogleShowAllMap'
import howerObserver from '../../components/HowerObserver'

import PeoplePerHouseholdOptions from '../../components/PeoplePerHouseholdOptions'
import LifestyleOptions from '../../components/LifestyleOptions'
import ShowExactPlaceSearch from '../../components/ShowExactPlaceSearch'
import AmenityTrips from '../../components/AmenityTrips'
import DistrictsSelect from '../../components/DistrictsSelect'
import MicrodistrictsSelect from '../../components/MicrodistrictsSelect'
import MetroSelect from '../../components/MetroSelect'
import Priorities from '../Priorities'
import MinAndMaxPriceSelect from '../../containers/MinAndMaxPriceSelect'
import MinAndMaxBedroomsAndMetersSelect from '../../containers/MinAndMaxBedroomsAndMetersSelect'

import SearchIcon from '@material-ui/icons/Search'

import * as experimentSearchesActions from '../../actions/experimentSearches'
import * as amenityTripsActions from '../../actions/amenityTrips'
import * as placeSearchesActions from '../../actions/placeSearches'

import config from '../../config'

import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { I18n } from 'react-redux-i18n'
import { withStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

// const sections = ['price', 'what', 'whither'] // 05.12.2020 modified
const sections = ['price', 'what', 'who', 'whither', 'where', 'near', 'priorities']
// цена > что > кто > куда > где > возле > личное

const useStyles = createStyles(theme => ({
  rootTab: {
    textTransform: 'initial',
    minWidth: '100px'
  }
}))

class ExtendedContextAndPreferences extends Component {
  state = {
    currentSection: 'price',
    value: 0
  }

  componentDidMount() {
    this.props.setLoading(false)
  }

  doSearch = (experimentSearch, config) => {
    if (parseInt(experimentSearch.price_per_month_min, 10) < config.MIN_SEARCH_PRICE[experimentSearch.currency_bias]) {
      this.props.setIsPriceLowerThanMinValue(true)
    } else {
      this.props.setIsPriceLowerThanMinValue(false)
    }
      this.props.setLoading(true)
      this.props.performExperimentSearch(experimentSearch)
        .then(() => this.props.setLoading(false))
  }

  handleShowDetails = () => {
    this.setState({isShowDetailsExpanded: !this.state.isShowDetailsExpanded})
  }

  formButtonValue = () => {
    if (this.state.isShowDetailsExpanded) {
      return 'experimentSearch.notShowSearchSettings'
    } else return 'experimentSearch.showSearchSettings'
  }

  // goToNextSection = (currentSection) => {
  //   this.setState({currentSection: sections[sections.indexOf(currentSection) + 1]})
  // }

  setSection = (section ) => {
    this.setState({currentSection: section})
  }

  handleChange = (event, newValue) => {
    this.setState({value: newValue})
  }

  render() {
    const [ experimentSearch ] = this.props.experimentSearches
    const { currentSection, value } = this.state
    const { placeSearches, classes } = this.props
    const activatedPlaceSearches = placeSearches.filter(ps => ps.is_activated && ps.place_id)
    // this part is for section names if they ll change after user selection
    // const { amenities, amenityTrips } = this.props
    // const activatedAmenityTrips = amenityTrips.filter(at => at.is_activated).sort((a, b)=> b.round_trips_per_week - a.round_trips_per_week)
    // const filteredAmenityTrips = activatedAmenityTrips.length > 0 && amenities.length > 0 ? activatedAmenityTrips.map(item => amenities.find(a => a.id === item.amenity_id)).slice(0, 3) : []
    // const dots = activatedAmenityTrips.length > 3 ? '...' : ''

     return (
      <div className='middle-block_row-wrap_list middle-block_row-wrap' onKeyDown={e => e.stopPropagation()}>
          <div>
            <AppBar position='static' color='default'>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant="scrollable"
                scrollButtons="auto"
             >
              {sections.map((section, i) => {
                if (section === 'where') {return <Tab
                  key={section}
                  label={
                          <div>
                            <Typography variant="subtitle1">
                              район
                            </Typography>
                            <Typography variant="caption">
                                {/*I18n.t(`experimentSearch.step`)*/}шаг {i+1} из {sections.length}
                            </Typography>
                          </div>
                        }
                  className={classes.rootTab}/>
                }
                // if (section === 'near') {return <Tab key={section} label={filteredAmenityTrips.length > 0 ? `${filteredAmenityTrips.map(a => a.name).join(', ')}${dots}` : I18n.t(`experimentSearch.${section}`)} />}
                if (section === 'whither') {return <Tab
                    key={section}
                    label={
                            <div>
                              <Typography variant="subtitle1">
                                {activatedPlaceSearches.length > 0 ?
                                   `возле ${activatedPlaceSearches.map(ps => I18n.t(`contextSection.${ps.name}`)).join(', ')}`
                                    :
                                    I18n.t(`experimentSearch.${section}`)}
                              </Typography>
                              <Typography variant="caption">
                                  {/*I18n.t(`experimentSearch.step`)*/}шаг {i+1} из {sections.length}
                              </Typography>
                            </div>
                          }
                    className={classes.rootTab}/>
                    }
                return <Tab
                        key={section}
                        label={
                          <div>
                            <Typography variant="subtitle1">
                              {I18n.t(`experimentSearch.${section}`)}
                            </Typography>
                            <Typography variant="caption">
                                {/*I18n.t(`experimentSearch.step`)*/}шаг {i+1}  из {sections.length}
                            </Typography>
                          </div>
                        }
                       className={classes.rootTab} />
               })
              }
              </Tabs>
            </AppBar>
          </div>
          <SwipeableViews
            axis={'x'}
            index={value}
            slideStyle={{ overflow: 'unset !important'}}
            onChangeIndex={this.handleChange}
          >
            <MinAndMaxPriceSelect
              experimentSearch={experimentSearch}
              setExperimentSearch={this.props.setExperimentSearch}/>

            <MinAndMaxBedroomsAndMetersSelect
              experimentSearch={experimentSearch}
              setExperimentSearch={this.props.setExperimentSearch}
              isExtendedContextAndPreferences={true}/>


            {true && <div className='infrastructure-info pagination-row'>
              <LifestyleOptions
                experimentSearch={experimentSearch}
                placeSearches={this.props.placeSearches}
                updatePlaceSearch={this.props.updatePlaceSearch}
                createPlaceSearch={this.props.createPlaceSearch}
                updateExperimentSearch={this.props.updateExperimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}/>

              <PeoplePerHouseholdOptions
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}/>
            </div>}


            <div className='infrastructure-info pagination-row' style={{height: '400px', overflowY: 'scroll'}}>
              <ShowExactPlaceSearch
                experimentSearch={experimentSearch}
                createPlaceSearch={this.props.createPlaceSearch}
                isExtendedContextAndPreferences={true}
              />
            </div>
            <div className='infrastructure-info'>
              <DistrictsSelect
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
                updateExperimentSearch={this.props.updateExperimentSearch}
              />
              <MicrodistrictsSelect
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
                updateExperimentSearch={this.props.updateExperimentSearch}
              />
              <MetroSelect
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
                updateExperimentSearch={this.props.updateExperimentSearch}
              />
            </div>
            <div style={{height: '400px', overflowY: 'scroll'}}>
              <AmenityTrips
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
                updateAmenityTrip={this.props.updateAmenityTrip}
                amenityTrips={this.props.amenityTrips}
                amenities={this.props.amenities}
                isExtendedContextAndPreferences={true}
              />
            </div>
            <div className='infrastructure-info pagination-row' style={{height: '400px'}}>
              <Priorities
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
              />
            </div>
          </SwipeableViews>


        <div className='middle-block-search'>

          <div className='loadingBlock'>
            {this.props.isLoading ?
              <div>
                <div> выполняется подбор оптимальных квартир </div>
                <div className='lds-spinner'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              :
              <div className='search-button_block'>
                <div className='call-buttons'>
                  <Button
                    variant='text'
                    color={currentSection !== sections[sections.length - 1] ? 'default' : 'primary'}
                    className=''
                    type='search'
                    value='Search'
                    onClick={() => {
                      this.doSearch(experimentSearch, config)}
                    }
                    >
                    <SearchIcon />
                    <Translate value='experimentSearch.search'/>&nbsp;>
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
  placeSearches: state.placeSearches,
  amenityTrips: state.amenityTrips,
  amenities: state.amenities,
  isLoading: state.isLoading
})

export default withStyles(useStyles())(howerObserver(togleShowAllMap(connect(mapStateToProps, { ...experimentSearchesActions, ...amenityTripsActions, ...placeSearchesActions})(withRouter(ExtendedContextAndPreferences)))))
