import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'
import { Provider } from 'react-redux'
import createStore from './store/createStore'
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import translationsObject from "./i18n.js"
import { PersistGate } from 'redux-persist/integration/react'
import Rollbar from "rollbar"


window.__DEV__ = process.env.NODE_ENV === "development"
const {store, persistor} = createStore()

const config = {
  accessToken: '4aa22e6fd6f9415f84b9a8d693325426',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
      environment: "production"
  }
}

if (!window.__DEV__) {
  const initRollbar = Rollbar.init(config)
  window.Rollbar = initRollbar
}

store.dispatch(loadTranslations(translationsObject))
syncTranslationWithStore(store)
store.dispatch(setLocale('ru-RU'))

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
, document.getElementById('root'))

unregister()
// registerServiceWorker();
