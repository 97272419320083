import React, {Component} from 'react'
import {highlight, highlightArray, list_findings, list_findings_by_array} from '../../containers/Highlighter'

export default class TestAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
          should_highlight: false,
          highlighted_array: ['specific_geo_subtype_street', 'specific_geo_subtype_residential_complex', 'specific_geo_subtype_subway', 'specific_geo_subtype_subway_routes',
                              'specific_geo_subtype_district', 'specific_geo_subtype_microdistrict', 'specific_geo_subtype_county'],
          // highlighted_array: ['price_range_hryvnas', 'price_range_thousands_hryvnas', 'price_hryvnas', 'price_thousands_hryvnas', 'price_range_dollars', 'price_dollars', 'price_thousands_dollars', 'price_euros'],
        };
    
        this.handleClick = this.handleClick.bind(this);
      }
      handleClick() {
        this.setState(state => ({ should_highlight: !state.should_highlight}))
      }  
  
    render() {
        const {ad, first_parse_datetime, published_time_ago_abbreviation, links_in_text=[]} = this.props
      // This syntax ensures `this` is bound within handleClick
      return (
        <div className="apartment-item-box apartment-item-amenities">
            <h4 onClick={this.handleClick}>Highlight</h4>
            <div> {this.state.should_highlight ? highlight(ad) : highlightArray(ad, this.state.highlighted_array) } </div> <div style={{display: 'table', margin: '0 auto'}}> {this.state.should_highlight ? list_findings(ad) : list_findings_by_array(ad, this.state.highlighted_array)}</div> 
            {/* <div>{first_parse_datetime}</div> */}
            <div>{published_time_ago_abbreviation}</div>
            {links_in_text.map((link, index) => <div key={index}>{link}</div>)}
        </div>
      );
    }
  }
