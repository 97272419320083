import { UPDATE_LISTINGS, SET_LISTING } from '../../actions/experimentSearches'
import { deserialize } from '../utils/deserialize'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LISTINGS:
      return deserialize(action.payload)
    case SET_LISTING:
      const newListings = state
      const listingId = state.findIndex(item => item.id === parseInt(action.payload.data[0].id, 10))
      const newListing = { ...action.payload.data[0].attributes, id: parseInt(action.payload.data[0].id, 10)}
      newListings[listingId] = newListing
      return newListings
    default:
      return state
  }
}
