import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class FreezeContext extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const {experimentSearch, updateExperimentSearch } = this.props

    if (experimentSearch.is_context_frozen) {
      return (
        <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
          <div>
            Разморозить контекст / Разрешить изменения настроек поиска
          </div>
          <div className='call-buttons'>
            <Button
              style={{padding: "5px 5px", minHeight: "35px"}}
              variant="text"
              color="primary"
              className=''
              onClick={()=>updateExperimentSearch({...experimentSearch, is_context_frozen: false})}
            >
              Разморозить контекст&nbsp;>
            </Button>
          </div>
        </div> )
    } else {
      return (
        <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
          <div>
            Заморозить контекст / Запретить изменения настроек поиска
          </div>

          <div className='call-buttons'>
            <Button
            style={{padding: "5px 5px", minHeight: "35px"}}
            variant="text"
            color="primary"
            className=''
            onClick={()=>updateExperimentSearch({...experimentSearch, is_context_frozen: true})}
            >
              Заморозить контекст&nbsp;>
              </Button>
          </div>

        </div>
      )
    }
  }
}

export default howerObserver(togleShowAllMap(FreezeContext))
