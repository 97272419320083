import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import {withRouter} from 'react-router-dom'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class CheckoutRentGuide extends Component {
  render() {
    const {experimentSearch} = this.props
    return (
      <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
        <div>
          {/* <Translate value='experimentSearch.xxxxxx' /> */}
          Хотите узнать больше об аренде? {/*Want to learn more about renting?*/}
        </div>
        <div className='call-buttons'>
          {/*<div className=''>
            <Button style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="default" className=''>
            <Clear />
            </Button>
          </div>*/}
          <div className=''>
            <Button
              onClick={() => this.props.history.push(`/rent-guide?c=${experimentSearch.id}`)}
              style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="primary" className=''>
              {/*<Translate value='experimentSearch.send'/>*/}{/*Check out Rent Guide*/}Смотреть статьи&nbsp;>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default howerObserver(togleShowAllMap(withRouter(CheckoutRentGuide)))
