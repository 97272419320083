import React, { Component } from 'react'
import { connect } from 'react-redux'

// import Select from 'react-select'
// import InputMask from 'react-input-mask'

// import userIcon from '../../assets/user.svg'
// import callIcon from '../../assets/call-black.svg'

// import emailIcon from '../../assets/mail.svg'

// import vitaliyIcon from '../../assets/vitaliy.jpg'
import handWavingIcon from '../../assets/handWaving.png'
import CallIcon from '../../assets/CallIcon'
// import CustomDatePicker from '../../components/CustomDatePicker'

import moment from 'moment'
import * as requestTourModalActions from '../../store/reducers/requestTourModal'
import * as experimentSearchesActions from '../../actions/experimentSearches'

// const options = [
//   { value: '1', label: `Завтра ${moment().add(1, 'days').locale('en').format('D MMM')}` },
//   { value: '2', label: `${moment().add(2, 'days').locale('ru').format('dddd D MMM')}` },
//   { value: '3', label: `${moment().add(3, 'days').locale('ru').format('dddd D MMM')}` },
//   { value: '4', label: `${moment().add(4, 'days').locale('ru').format('dddd D MMM')}` },
//   { value: '5', label: `${moment().add(5, 'days').locale('ru').format('dddd D MMM')}` },
// ]

class RequestTourModal extends Component {
  constructor(props) {
      super(props)
      this.state = {
        secondDate: moment().add(1, 'days'),
        isShowMessage: false,
        todayParts: [],
        nextDayParts: [],
        name: this.props.user.length >0 && this.props.user[0].name ? this.props.user[0].name : '',
        phone: this.props.user.length >0 && this.props.user[0].phone ? this.props.user[0].phone : '',
        email: this.props.user.length >0 && this.props.user[0].email ? this.props.user[0].email : '',
        message: null,
        isTRSent: false
      }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.requestTourModal.isRequestSuccesful) {
      this.setState({
        secondDate: moment().add(1, 'days'),
        isShowCalendar: false,
        isShowMessage: false,
        todayParts: [],
        nextDayParts: [],
        message: null,
        isTRSent: false
      })
    }
  }

  setPartOfDay = (dayPart, part) => {
    const newDayPart = this.state[dayPart].includes(part) ?
      this.state[dayPart].filter(item => item !==part) :
      [
        ...this.state[dayPart],
        part
      ]
    this.setState({[dayPart]: newDayPart})
  }

  setSecondDate = (secondDate) => {
    this.setState({isShowCalendar: false, secondDate: moment(new Date(moment().add(parseInt(secondDate.value, 10), 'days'))), nextDayParts: [] })
  }

  setIsShowCalendar = (value) => {
    this.setState({isShowCalendar: value})
  }

  setIsShowMessage = (value) => {
    this.setState({isShowMessage: !this.state.isShowMessage})
  }

  setInput = (name, value) => {
    this.setState({[name]: value})
  }

  sendTourRequest = () => {
    const { secondDate, todayParts, nextDayParts, phone, name, email, message } = this.state
    const [ experimentSearch ] = this.props.experimentSearches
    const { updateExperimentSearch } = this.props

    const user = {
      user: {
        name: name,
        phone: phone,
        email: email
      }
    }
    const request = {
      date_1: moment().format('YYYY-MM-DD'),
      date_2: secondDate.format('YYYY-MM-DD'),
      part_of_the_day_1: todayParts,
      part_of_the_day_2: nextDayParts,
      message: message,
      place_id: this.props.experiment.place_id,
      listing_id: this.props.experiment.listing_id,
      listing_entity_id: this.props.experiment.listing_entity_id,
      suggested_experiment_id: this.props.experiment.id,
      experiment_search_id: this.props.experiment.experiment_search_id,
    }
    const notCorrectEmail = !!email ? !(email.includes('@') && email.includes('.')) : false
    this.setState({ isTRSent: true, notCorrectEmail })
    const userId = this.props.user[0] ? this.props.user[0].id : null
    const shouldUserUpdate = userId ? !(name === this.props.user[0].name &&
                             phone === this.props.user[0].phone &&
                             email === this.props.user[0].email)
                             : null
    this.props.fetchCreateRequest(user, userId, shouldUserUpdate, request, experimentSearch, updateExperimentSearch)
  }

  // render () {
  //   const [ experimentSearch ] = this.props.experimentSearches
  //   const { isRequestSuccesful } = this.props.requestTourModal
  //   const { secondDate, isShowMessage, name, todayParts, nextDayParts, phone, isTRSent, notCorrectEmail , email} = this.state

  //   return (
  //      <div>
  //         <p className='request-tour_header'>
  //           Записаться на просмотр
  //         </p>
  //         <p className='request-tour_day'>
  //           СЕГОДНЯ, {moment().locale('ru').format('MMM DD')}
  //         </p>
  //         <div className='request-tour_day-time'>

  //           <button
  //             className={todayParts.includes('morning') ? 'day-time_btn_chosen' : 'day-time_btn'}
  //             onClick={()=>this.setPartOfDay('todayParts','morning')}>
  //             Утро
  //           </button>
  //           <button
  //             className={todayParts.includes('afternoon') ? 'day-time_btn_chosen' : 'day-time_btn'}
  //             onClick={()=>this.setPartOfDay('todayParts','afternoon')}>
  //             День
  //           </button>
  //           <button
  //             className={todayParts.includes('evening') ? 'day-time_btn_chosen' : 'day-time_btn'}
  //             onClick={()=>this.setPartOfDay('todayParts','evening')}>
  //             Вечер
  //           </button>
  //         </div>

  //           {/*<div className='request-tour_day'>

  //             <p>
  //             ЛИБО В ДРУГОЙ ДЕНЬ, {secondDate.locale('ru').format('MMM DD')}

  //             {!isShowCalendar && <img src={arrowDownGrey} alt="" onClick={()=>this.setIsShowCalendar(true)}/>}
  //           </p>
  //           {isShowCalendar &&
  //             <CustomDatePicker
  //               setSecondDate={this.setSecondDate}
  //               secondDate={secondDate}
  //             />}
  //             </div> */}
  //           <div className='select-calendar'>
  //             <Select
  //              value={ { value: `${secondDate.locale('ru').format('dddd D MMM')}`, label: `${secondDate.locale('ru').format('dddd D MMM')}` }}
  //              onChange={(e)=>this.setSecondDate(e)}
  //              options={options}
  //              isSearchable={false}
  //             />
  //          </div>
  //         <div className='request-tour_day-time'>
  //           <button
  //             className={nextDayParts.includes('morning') ? 'day-time_btn_chosen' : 'day-time_btn'}
  //             onClick={()=>this.setPartOfDay('nextDayParts','morning')}>
  //             Утро
  //           </button>
  //           <button
  //             className={nextDayParts.includes('afternoon') ? 'day-time_btn_chosen' : 'day-time_btn'}
  //             onClick={()=>this.setPartOfDay('nextDayParts','afternoon')}>
  //             День
  //           </button>
  //           <button
  //             className={nextDayParts.includes('evening') ? 'day-time_btn_chosen' : 'day-time_btn'}
  //             onClick={()=>this.setPartOfDay('nextDayParts','evening')}>
  //             Вечер
  //           </button>
  //         </div>

  //         <div className='request-tour_input'>
  //           <img src={userIcon} alt="" />
  //           <input type="text" placeholder='Имя' className='' value={name} onChange={(e)=>this.setInput('name', e.target.value)}/>
  //         </div>
  //         <div className='request-tour_input'>
  //           <InputMask mask="+38\0 99 999 99 99" placeholder='Телефон' value={phone} onChange={(e)=>this.setInput('phone', e.target.value)}/>
  //           <img src={callIcon} alt="" />
  //         </div>
  //         {!phone && isTRSent && <p style={{color: 'red'}}>
  //           Пожалуйста, введите свой номер телефона
  //         </p>}
  //         <div className='request-tour_input'>
  //           <img src={emailIcon} alt="" />
  //           <input type="email" placeholder='Email' value={email} onChange={(e)=>this.setInput('email', e.target.value)}/>
  //         </div>
  //         {(!email || notCorrectEmail) && isTRSent && <p style={{color: 'red'}}>
  //           Пожалуйста, введите правильный email
  //         </p>}
  //         <p className='request-tour_message' onClick={()=>this.setIsShowMessage()}>+ ДОБАВИТЬ СООБЩЕНИЕ</p>
  //         {isShowMessage &&
  //           <div className='request-tour_input'>
  //             <input type="message" placeholder='Сообщение' onChange={(e)=>this.setInput('message', e.target.value)} />
  //           </div>
  //         }
  //         <button className='request-tour_send-btn' onClick={()=>this.sendTourRequest()}>
  //           Записаться на просмотр
  //         </button>
  //         { isRequestSuccesful &&
  //           <p className='request-tour_day'>
  //             Спасибо, ваша заявка отправлена и будет обработана в течение часа
  //           </p>
  //         }
  //         <div className='request-tour_agent-block'>
  //           <img src={handWavingIcon} alt=""/>
  //           <div className='request-tour_agent-block_info'>
  //             <p className='request-tour_agent-block_name'>Виталий</p>
  //             <p className='request-tour_agent-block_name'>{/*Agent*/}</p>
  //             {/*<span>AGENT</span>*/}
  //             <p>
  //               <a
  //                 className='call_btn'
  //                 href={`tel:${experimentSearch.dedicated_agent_phone_number_international_format || '+380 66 851 43 59'}`}>
  //                 <CallIcon />
  //                 066 851 43 59
  //               </a>
  //               {/*<span className='wayleap-logo'>Wayleap</span>*/}
  //             </p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  render () {
    const [ experimentSearch ] = this.props.experimentSearches

    return (
       <div>
          <p className='request-tour_header'>
            Записаться на просмотр
          </p>
          <div className='request-tour_agent-block'>
            <img src={handWavingIcon} alt=""/>
            <div className='request-tour_agent-block_info'>
              <p className='request-tour_agent-block_name'>Виталий</p>
              <p className='request-tour_agent-block_name'>{/*Agent*/}</p>
              {/*<span>AGENT</span>*/}
              <p>
                <a
                  className='call_btn'
                  href={`tel:${experimentSearch.dedicated_agent_phone_number_international_format || '+380 66 851 43 59'}`}>
                  <CallIcon />
                  066 851 43 59
                </a>
                {/*<span className='wayleap-logo'>Wayleap</span>*/}
              </p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
  requestTourModal: state.requestTourModal,
  user: state.user
})

export default connect(mapStateToProps, { ...requestTourModalActions, ...experimentSearchesActions})(RequestTourModal)
