import { combineReducers } from "redux"
import { i18nReducer } from 'react-redux-i18n'
import experimentSearches from './reducers/experimentSearches'
import placeSearches from './reducers/placeSearches'
import suggestedExperiments from './reducers/suggestedExperiments'
import listingEntities from './reducers/listingEntities'
import listings from './reducers/listings'
import places from './reducers/places'
import user from './reducers/user'
import active from './reducers/setActive'
import isLoading from './reducers/setLoading'
import amenities from './reducers/amenities'
import expectations from './reducers/expectations'
import amenityTrips from './reducers/amenityTrips'
import amenityRoutes from './reducers/amenityRoutes'
import experiment from './reducers/experiment'
import requestTourModal from './reducers/requestTourModal'
import addApartmentsModal from './reducers/addApartmentsModal'
import editListingModal from './reducers/editListingModal'
import amenityLocations from './reducers/amenityLocations'
import routeStops from './reducers/routeStops'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
}

const appReducer = combineReducers({
	experimentSearches,
  placeSearches,
  suggestedExperiments,
  listingEntities,
  listings,
  places,
  active,
  isLoading,
  routeStops,
  amenities,
  amenityTrips,
  amenityRoutes,
  amenityLocations,
  i18n: i18nReducer,
	experiment,
	requestTourModal,
  addApartmentsModal,
  editListingModal,
  user,
  expectations,
})

const makeRootReducer = (state, action = {}) => {
 return appReducer(state, action)
}
export default persistReducer(rootPersistConfig, makeRootReducer)
