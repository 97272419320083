import React, {Component} from 'react'
// import { Translate, I18n } from 'react-redux-i18n'
// import config from '../../config'
// import TrafficIcon from '@material-ui/icons/Traffic'
// import DepartureBoardIcon from '@material-ui/icons/DepartureBoard'
// import CommuteIcon from '@material-ui/icons/Commute'
// import HotelIcon from '@material-ui/icons/Hotel'

export default class AgentApartmentsInfo extends Component {
  render() {
    // const {listing, listingEntity, place} = this.props
    return(<div/>
    )
  }
}

      // <div className='details-block'>
      //   <div className='description_row'>
      //     <div className='details-block description_row'>
      //       <div className='description_row-item'>
      //        &nbsp;
      //       </div>
      //       {/* I18n.t('experimentItem.object')*/} {/*experiment.experiment_search_id*/}{/*experiment.rank*/}

      //       {listing.kitchen_area_square_meters ? <div className='description_row-item'>
      //          <Translate value='listing.kitchenArea' /> <Translate value='listing.squareMetersShort' count={Math.round(listing.kitchen_area_square_meters)} />
      //       </div> : ''}

      //       {listing.living_space_square_meters ? <div className='description_row-item'>
      //          <Translate value='listing.livingSpace' /> <Translate value='listing.squareMetersShort' count={Math.round(listing.living_space_square_meters)} />
      //       </div> : ''}

      //       {false && (listingEntity.listings_count ? <div className='description_row-item'>
      //         <Translate value='listing.ads_published_by_phone_number' />: {listingEntity.listings_count}
      //         </div> : '')}

      //       {/* <div className='description_row-item'>
      //         <a href={`tel:${listingEntity.phone_number}`}>{listingEntity.phone_number}</a>
      //       </div> */}

      //       {false && <div className='description_row-item'>
      //         {listing.source && (config.AGENT || window.location.hostname === "agent.imbadom.com" || window.location.hostname === "agent.wayleap.com") ?
      //           <Translate
      //             value='listing.adSource'
      //             name={listing.source}
      //             href={listing.source_url}
      //             rel="noreferrer"
      //             dangerousHTML
      //           /> : ''
      //         }
      //       </div>
      //     }
      //     </div>

      //   </div>
      // </div>
