import React, {Component} from 'react'
import Button from '@material-ui/core/Button'

export default class ShowMoreDetails extends Component {
  render() {
    const { experiment, buttonWord } = this.props
    return(
      <Button 
        variant="contained"
        size="medium"
        color="primary"
        onClick={()=> sessionStorage.setItem('id', `${experiment.id}`)}
        href={`listing/?c=${experiment.experiment_search_id}&i=${experiment.listing_id}`}>
        {buttonWord}
     </Button>
    )
  }
}
