import React from 'react'
import {Route, Redirect} from 'react-router-dom'

import TestPage from './components/TestPage'
import StartPage from './components/StartPage'
import MainPage from './containers/MainPage'
// import AdPage from './components/AdPage'
// import AboutPage from './components/AboutPage'
// import BuyingProcessPage from './components/BuyingProcessPage'
// import Logout from './containers/Logout'
// import ExperimentItemSingle from './containers/ExperimentItemSingle'
// import ListPropertyForRent from './components/ListPropertyForRent'
// import ListPropertyForSale from './components/ListPropertyForSale'
// import RentProperty from './components/RentProperty'
// import BuyProperty from './components/BuyProperty'
// import LayoutExample from './components/LayoutExample'
// import RentGuide from './components/RentGuide'
// import RenterProfile from './components/RenterProfile'
// import HomeBuyingGuide from './components/HomeBuyingGuide'
// import ServicesComparison from './components/ServicesComparison'
// import CareersPage from './components/CareersPage'
// import ContactPage from './components/ContactPage'
// import QuizPage from './components/QuizPage'
// import CompareWithNextBestAlternativePage from './containers/CompareWithNextBestAlternativePage'

const routes = [
// Основные страницы
    // {path: '/poisk', component: MainPage, exact: true},
    {path: '/rent', component: MainPage, exact: true},
    {path: '/sale', component: MainPage, exact: true},
    {path: '/test', component: TestPage, exact: true},
    // {path: '/kvartira', component: ExperimentItemSingle, exact: true},
    // {path: '/sravnenie', component: CompareWithNextBestAlternativePage},

// Основные лендинги
    {path: '/', component: StartPage, exact: true},
    // {path: '/ad', component: AdPage, exact: true},
    // {path: '/list-your-rental', component: ListPropertyForRent, exact: true},
    // {path: '/sdat-kvartiru-v-kieve', component: ListPropertyForRent, exact: true},
    // {path: '/prodat-kvartiru-v-kieve', component: ListPropertyForSale, exact: true},
    // {path: '/snyat-kvartiru-v-kieve', component: RentProperty, exact: true},
    // {path: '/kupit-kvartiru-v-kieve', component: BuyProperty, exact: true},

// Преднастроенные массовые популярные контексты

// ЖК Киева

// О компании
//      Миссия
//      История
//      Риэлторы
//      Лучшие риэлторы
//      Карьера
//      Контакты

// Сервисы

// Владельцам
//      Продать недвижимость
//      Сдать в аренду
//      Оценка недвижимости
//      Выезд риэлтора

// Статистика цен
// Новости недвижимости

// Другое
    // {path: '/search', component: MainPage, exact: true},
    // {path: '/listing', component: ExperimentItemSingle, exact: true},

    // {path: '/layout-example', component: LayoutExample, exact: true},
    // {path: '/rent-guide', component: RentGuide, exact: true},
    // {path: '/home-buying-guide', component: HomeBuyingGuide, exact: true},
    // {path: '/renter-profile', component: RenterProfile, exact: true},
    // {path: '/wayleap', component: ServicesComparison, exact: true},
    // {path: '/careers', component: CareersPage, exact: true},
    // {path: '/about', component: AboutPage, exact: true},
    // {path: '/contact', component: ContactPage, exact: true},
    // {path: '/exit', component: Logout},
    // {path: '/compare', component: CompareWithNextBestAlternativePage},
    // {path: '/luchshie-30-kvartir-kieva-na-segodnya', component: QuizPage},
    // {path: '/buying-process', component: BuyingProcessPage}

// Запланированные
//      make me move(for owners: they can set high price, and if buyer is interested, we'll match them)
//      shoild I move (For renters and owners, to compare their current home with homes available on the market, to deside whether it's worth the hassle to move)
//      living in Kiyv, do I need a car (For car owners and non-owners to see if the car is really worth it)
//      should I rent or buy
//      is it better to buy outright or finance somehow differently
//      subscribe to great refinancing opportunities

]

export const renderRoutes = () => routes.map(({path, from, exact, title, routes, ...route}, index) => {
    if (from) return <Redirect key={index} {...{from}} exact to={path} />
    return (
        <Route
            key={index}
            {...{path}}
            exact={exact}
            render={(props)=>
                <route.component {...props} {...{title, routes}} />
            }
        />
    )
})

export const renderSubRoutes = ({url}, routes) => routes.map(({path, exact, component, isRedirect}, index) => {
    if (isRedirect) return <Redirect key={index} from={url} to={`${url}${path}`} />
    return <Route key={index} {...{exact, component}} path={`${url}${path}`} />
})
