import React, {Component} from 'react'
import checkbox from '../../assets/checkbox.png'

// const highlights = ['Лучшая комбинация цены и местоположения.',
// 'По сравнению с другими вариантами, вы проведете на 2 часа меньше времени в дороге в месяц.',
// '3 мин до остановки общ. транспорта.',
// 'Возле парка(7 мин ).',
// 'Рядом супермаркет Ашан(8 мин).',
// 'Фитнес центр SportLife (10 мин).',
// 'Самая просторная квартира среди аналогичных предложений.',
// 'Самая большая цена за квадратный метр (13USD/m² в месяц) среди аналогичных предложений.',
// 'Первая сдача.',
// 'Самое новое здание (2013г) среди других альтернатив.']

const highlights = []

export default class Highlights extends Component {
  render() {
    const mainHighlights = highlights.slice(0, 5)
    const {isSinglePage } = this.props
    return (
       <div>
        <div>
          {!isSinglePage &&
            <div>
              {mainHighlights.map(item =>
                <div key={item}>
                  <img src={checkbox} alt="" width={15} height={15} />
                  {item}
                </div>
               )}
              </div>}
            {isSinglePage && highlights.map(item =>
              <div key={item}>
                <img src={checkbox} alt="" width={15} height={15} />
                {item}
              </div>
            )}
        </div>
      </div>
    )
    }
  }
// <div className='description_row-item break-text'>  place.full_address}{place.city ? `, ${place.city}` : '
