import React from 'react'
import { I18n } from 'react-redux-i18n'
import PublishedTimeAgo from '../../components/PublishedTimeAgo'
import CachedIcon from '@material-ui/icons/Cached'
import config from '../../config'
import NearbyMetroStationOrStreetName from '../../components/NearbyMetroStationOrStreetName'
import SubwayLineIcon from '../../components/SubwayLineIcon'
import ApartmentItemHighlights from './../ApartmentItemHighlights'

export default function RecommendationRankAndAddress({experiment={}, listing, place, isAgent, amenityRoutes, amenities, amenityLocations, experimentSearch, placeSearches, places}) {
  const address = place.street ? `${place.street} ${place.house}` : (place.street_address || place.full_address || '')
  const addressIsShit = address.startsWith('Прода') || address.startsWith('Сда')  || address.startsWith('Зда')
  const isNew = experimentSearch.new_listing_ids && experimentSearch.new_listing_ids.map(Number).includes(Number(listing.group_id))
  const subway_station = experiment.highlights && experiment.highlights.data && experiment.highlights.data.find(({name}) => name === 'nearest subway station') && experiment.highlights.data.find(({name}) => name === 'nearest subway station').value

  return (
    <div  className='recommendation'>
      <span className="ranking">
        № {experiment.rank}{experiment.rank === 1 && ` ${I18n.t('experimentItem.recommendation')} `}
      </span>

      { false && experiment.highlights && experiment.highlights.data && experiment.highlights.data.find(({name}) => name === 'nearest subway station') && <span>
          <span className='miniseparator'>•</span><SubwayLineIcon name={subway_station}></SubwayLineIcon> {subway_station}
      </span>}

      <ApartmentItemHighlights
              experimentSearch={experimentSearch}
              place={place}
              amenityRoutes={amenityRoutes}
              amenities={amenities}
              amenityLocations={amenityLocations}
              placeSearches={placeSearches}
              experiment={experiment}
              places={places}
      />
      {config.SHOW_SHIT && <span style={{marginLeft: '15px'}}>
        <i className="fa fa-map-marker"></i>{` ${addressIsShit ? '' : address.substring(0, 30)}`}
      </span>}

      {config.SHOW_SHIT && config.SHOW_STATS_DEBUG && <span style={{fontSize: '0.7rem'}}>
        <span className='miniseparator'>•</span>{place.year_built}
      </span>}

      {config.SHOW_SHIT && <span style={{fontSize: '1rem'}}>
        <span className='miniseparator'>•</span>оценка <span className='wayleap-logo'>Wayleap</span>: <span style={{fontSize: '1.2rem'}}><span style={{color: experiment.calculated_value > 0 ? 'green' : 'red'}}> {' '}{Math.round(experiment.calculated_value)}</span></span>
      </span>}

      { false && experiment.highlights && experiment.highlights.data && experiment.highlights.data.find(({name}) => name === 'nearest subway station') && <span>
      {addressIsShit ? '' : <span className='miniseparator'>•</span>} ст.м. {experiment.highlights.data.find(({name}) => name === 'nearest subway station').value}
        </span>}



      {config.SHOW_SHIT && <div className="no-fee" title="Resident does not pay a broker fee for this property"><i class="fa fa-star"></i>&nbsp;Без комиссии Wayleap</div>}
      {config.SHOW_SHIT && (place.year_built > 2014) && <div className="new-construction"><i className="fa fa-star"></i>&nbsp;Новый дом</div>}
      {(isNew) && <div className="new-result"><i className="fa fa-star"></i>&nbsp;New</div>}

      {config.SHOW_SHIT && <div><NearbyMetroStationOrStreetName
                    place={place}
                    amenityRoutes={amenityRoutes}
                    amenities={amenities}
                    amenityLocations={amenityLocations}
                    />
        </div>}

      {isAgent && <span>
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>listing: {listing.id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>place: {place.id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>experiment: {experiment.id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>aggregator: {listing.aggregator_listing_id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>rent_hours/year: {Math.round(experiment.rental_costs_seconds_per_year / 360) / 10}
        </span>

        {experiment.costs && <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>commute_hours/year: {Math.round(experiment.commute_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> from which to amenities: {Math.round(experiment.costs.data.find(({name}) => name === 'commute_to_amenity_locations').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> to exact places: {Math.round(experiment.costs.data.find(({name}) => name === 'commute_to_exact_places').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> subway-based remoteness: {Math.round(experiment.costs.data.find(({name}) => name === 'subway_based_remoteness').costs_in_seconds_per_year / 360) / 10}
        </span>}

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>missing_space_utility_hours/year: {Math.round(experiment.missing_space_utility_seconds_per_year / 360) / 10}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>
          <CachedIcon style={{display: 'inline-block', fontSize: '0.7rem', verticalAlign: 'text-bottom'}} />
          <PublishedTimeAgo last_updated={listing.listing_updated_at} fontSize={'0.7rem'}/>
        </span>

        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_in_usd_per_year') &&
            experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_usd_per_year') &&
            experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car') &&
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span> whether to buy or sell car: <span style={{fontSize: '0.7rem'}}>{experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car').value}</span>
          <span className='miniseparator'>•</span> <span className='wayleap-logo'>Wayleap</span> score bumped by: {Math.round((
            experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_seconds_per_year').costs_in_seconds_per_year -
              experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_seconds_per_year').costs_in_seconds_per_year)/360.0)/10.0}
          <span className='miniseparator'>~</span> {Math.round(
            experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_in_usd_per_year').in_usd_per_year -
            experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_usd_per_year').in_usd_per_year)}$
        </span>}

        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'secondary_effects') &&
            experiment.costs.data.find(({name}) => name === 'year_built_aging_building_maintenance_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'wall_type_noise_and_water_from_neighbors_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'ceiling_height_penalty_on_creativity_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'info_uncovered_utility_penalty_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'missing_rooms_utility_seconds_per_year') &&
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>secondary_effects_hours/year: {Math.round(experiment.costs.data.find(({name}) => name === 'secondary_effects').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> from which year_built: {Math.round(experiment.costs.data.find(({name}) => name === 'year_built_aging_building_maintenance_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> wall_type: {Math.round(experiment.costs.data.find(({name}) => name === 'wall_type_noise_and_water_from_neighbors_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> ceiling_height: {Math.round(experiment.costs.data.find(({name}) => name === 'ceiling_height_penalty_on_creativity_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> info: {Math.round(experiment.costs.data.find(({name}) => name === 'info_uncovered_utility_penalty_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> missing rooms: {Math.round(experiment.costs.data.find(({name}) => name === 'missing_rooms_utility_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
        </span>}


        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'upper_secondary_school_amenity_commute_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'upper_secondary_school_quality_penalty') &&
            experiment.costs.data.find(({name}) => name === 'upper_secondary_school_score') &&
            experiment.costs.data.find(({name}) => name === 'upper_secondary_school_short_name') &&

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>upper_secondary_school_commute_hours/year: {Math.round(experiment.costs.data.find(({name}) => name === 'upper_secondary_school_amenity_commute_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span>upper_secondary_school_quality_penalty: {Math.round(experiment.costs.data.find(({name}) => name === 'upper_secondary_school_quality_penalty').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span>upper_secondary_school_score: {Math.round(experiment.costs.data.find(({name}) => name === 'upper_secondary_school_score').value)}
          <span className='miniseparator'>•</span>upper_secondary_school_short_name: {experiment.costs.data.find(({name}) => name === 'upper_secondary_school_short_name').value}
        </span>}

        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'not_preferred_location_penalty') &&
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span> not_preferred_location_penalty: {Math.round(experiment.costs.data.find(({name}) => name === 'not_preferred_location_penalty').costs_in_seconds_per_year / 3600)}
        </span>}


      </span>}
    </div>
  )
}
