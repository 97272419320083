import React, { Component } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import axios from 'axios'
import { connect } from 'react-redux'
import _ from 'lodash'
import config from '../../config'
import { I18n, Translate } from 'react-redux-i18n'
import { performExperimentSearch } from '../../actions/experimentSearches'
import { updatePlaceSearch, addPlaces } from '../../actions/placeSearches'
import ShowPlacesAutocomplete from '../../components/ShowPlacesAutocomplete'
import Select from '../../components/Select'

const HOST = config.HOST
const round_trips_options = [0,1,2,3,4,5,6,7]

class ExactPlaceSearch extends Component {

  handleSelect = (address) => {
    console.log('address: ', address)
    const placeSearch = _.cloneDeep(this.props.placeSearch)
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng =>
      axios.post(`${HOST}api/places/`, {
        latitude: parseFloat(latLng.lat.toFixed(7)),
        longitude: parseFloat(latLng.lng.toFixed(7)),
        full_address: address,
        street_address: address,
        address_completeness_level: 0,
        is_address_complete: true,
      })
    )
    .then(response => {
      const places = response.data.data
      this.props.addPlaces(places)

      const [ place ] = places
      console.log('place: ', place)
      console.log('address: ', address)
      placeSearch.place = place
      placeSearch.place_id = parseInt(place.id, 10)
      placeSearch.input = address
      return axios.put(`${HOST}api/place_searches/${placeSearch.id}/`, placeSearch)
    })
    .then(res => {
      this.props.updatePlaceSearch(placeSearch, this.props.index)
    })
    .catch(error => console.error('Error', error))
    .then(()=> this.props.performExperimentSearch(this.props.experimentSearches[0], true))
  }

  prepareForUpdate = (value, type = 'name') => {
    const { index } = this.props
    const placeSearch = _.cloneDeep(this.props.placeSearch)

    switch(type) {
      case 'name':
        placeSearch.name = value
        break
      case 'members_per_trip':
        placeSearch.members_per_trip = value
        placeSearch.round_trips_per_week = parseInt(placeSearch.round_trips_per_week, 10)
        break
      case 'address':
        placeSearch.input = value
        if (value === '') {
          placeSearch.place_id = null
          placeSearch.place = null
        }
        break
      case 'round_trips_per_week':
        placeSearch.round_trips_per_week = parseInt(value, 10)
        placeSearch.round_trips_per_year = parseInt(value, 10) * 52
        placeSearch.members_per_trip = parseInt(placeSearch.members_per_trip, 10)
        break
      case 'round_trips_per_year':
        placeSearch.round_trips_per_year = parseInt(value, 10)
        placeSearch.round_trips_per_week = Math.round(parseInt(value, 10) / 52, 10)
        break
      default:
        placeSearch[type] = value
    }
    this.props.updatePlaceSearch(placeSearch, index)
  }

  showNameOptions = (experimentSearch, name) =>{
    return <div className='pagination-row'>
      { name ?
        <div
          className='active pagination-row__item'
          // onClick={() => this.prepareForUpdate(false, 'is_activated')} 07.12.2020 MODIFIED
        >
          { name }
        </div>
        :
        [ I18n.t('exactPlaceSearch.work'),
          experimentSearch.is_household_a_couple ? I18n.t("exactPlaceSearch.partnersWork") : false,
          experimentSearch.is_household_with_student ? I18n.t('exactPlaceSearch.uni') : false,
          experimentSearch.is_household_with_children_under_school_age ? I18n.t('exactPlaceSearch.kindergarten') : false,
          experimentSearch.is_household_with_children_in_school ? I18n.t('exactPlaceSearch.school') : false,

          I18n.t('exactPlaceSearch.family'),
          I18n.t('exactPlaceSearch.friends'),
          I18n.t('exactPlaceSearch.other')
        ].filter(Boolean).map(option =>
          <div
            className={`${name === option ? 'active ' : ''} pagination-row__item`}
            onClick={() => this.prepareForUpdate(option, 'name')}
            key={option}
          >
            { option }
          </div>
        )}
    </div>
}
  showRoundTripsOptions = () => {
    const round_trips_per_week = parseInt(this.props.placeSearch.round_trips_per_week, 10)

    return (
      <div className='pagination-row'>
        { round_trips_options.map(option =>
          <div
            className={`${round_trips_per_week === option ? 'active ' : ''} pagination-row__item`}
            onClick={() => this.prepareForUpdate(option, 'round_trips_per_week')}
            key={option}
          >
            { option }
          </div>
        )}
        {/* <div
          className='pagination-row__item'
          key={'roundTripsPerWeekOption'}
        >
        </div> */}
        <Translate value='exactPlaceSearch.roundTripsPerWeek' />
      </div>
    )
  }


  showFieldForRoundTripsPerYear = () => {
    const { placeSearch } = this.props
    const round_trips_per_year = parseInt(placeSearch.round_trips_per_year, 10)

   return (<div className='price-row-second'>
                <span className='price-text'>
                 или в год:
                </span>
                <Select
                 onChange = {(e) => {
                   this.prepareForUpdate(e, 'round_trips_per_year')}}
                 value={round_trips_per_year || ''}
                 label={round_trips_per_year ? `${round_trips_per_year}` : ''}
                 key={'round_trips_per_year'}
                 values={[
                  {value: 0, label:'0'},
                  {value: 1, label:'1'},
                  {value: 2, label:'2'},
                  {value: 6, label:'6'},
                  {value: 12, label:'12'},
                  {value: 26, label:'26'},
                  {value: 52, label:'52'},
                  {value: 104, label:'104'},
                  {value: 156, label:'156'},
                  {value: 208, label:'208'},
                  {value: 260, label:'260'},
                  {value: 312, label:'312'},
                  {value: 364, label:'364'}
                  ]}/>
               </div>)
  }

  showMembersPerTripOptions = (name) => {
    const members_per_trip = parseInt(this.props.placeSearch.members_per_trip, 10)

    const [experimentSearch] = this.props.experimentSearches
    const maxNumberOfPeoplePerTrip = experimentSearch.number_of_people_per_household || 5
    const membersPerTripOptions = [...Array(maxNumberOfPeoplePerTrip).keys()]
    const isMultipleOptions = membersPerTripOptions.length > 1 && (experimentSearch.is_household_with_children_under_school_age || experimentSearch.is_household_with_children_in_school || experimentSearch.is_household_a_couple || experimentSearch.is_household_a_group)

    return ([
        I18n.t('exactPlaceSearch.work'),
        I18n.t('exactPlaceSearch.partnersWork'),
        I18n.t('exactPlaceSearch.family'),
        I18n.t('exactPlaceSearch.friends'),
        I18n.t('exactPlaceSearch.other'),
        I18n.t('exactPlaceSearch.school'),
        I18n.t('exactPlaceSearch.uni'),
      ].includes(name) && isMultipleOptions &&
      <div className='members-per-trip_row'>
        <div className='pagination-row'>
          {
            membersPerTripOptions.map(option => {
              const membersPerTripOption = option + 1
              return <div
                className={`${members_per_trip === membersPerTripOption || experimentSearch.number_of_people_per_household === 1 ? 'active ' : ''} pagination-row__item`}
                onClick={() => this.prepareForUpdate(membersPerTripOption, 'members_per_trip')}
                key={membersPerTripOption}
              >
                { membersPerTripOption }
              </div>
            })
          }
          {/* <div
            className='pagination-row__item'
            key={'membersPerTripOption'}
          >
          </div> */}
          <Translate value='exactPlaceSearch.membersPerTrip' />
        </div>
      </div>
    )
  }

  render() {
    const { experimentSearch, placeSearch } = this.props
    const { name, input } = placeSearch
    const place = this.props.places.find(place => place.id === placeSearch.place_id)
    const default_input = placeSearch.place ? placeSearch.place.attributes.full_address : (place ? place.full_address :'')


    return (
      <div className="exact-place-search">
        <div className='exact-place-search_block'>
          { this.showNameOptions(experimentSearch, name) }
          <ShowPlacesAutocomplete
            key={placeSearch.id}
            value={input || default_input}
            prepareForUpdate={this.prepareForUpdate}
            handleSelect={this.handleSelect}
            experimentSearch={experimentSearch}/>
        </div>

        {this.props.isExtendedContextAndPreferences && default_input && this.showRoundTripsOptions() }
        {config.SHOW_SHIT && this.props.isExtendedContextAndPreferences && default_input && this.showFieldForRoundTripsPerYear() }
        {this.props.isExtendedContextAndPreferences && default_input && this.showMembersPerTripOptions(name) }

      </div>
    );
  }
}

const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
  places: state.places,
})

export default connect(mapStateToProps, { updatePlaceSearch, addPlaces, performExperimentSearch })(ExactPlaceSearch)
