import React from 'react'
import { parseDate } from '../../utils.js'
import { Translate } from "react-redux-i18n"

export default function PublishedTimeAgo({last_updated, fontSize}) {
    const publishedMinutesAgo = parseInt((new Date() - parseDate(last_updated))/60000, 10)
    const publishedHoursAgo = parseInt(publishedMinutesAgo/60, 10)
    const publishedDaysAgo = parseInt(publishedHoursAgo/24, 10)

    if (publishedDaysAgo > 0) {
      return <Translate value='published.daysAgo' style={{fontSize: fontSize ? fontSize : '0.9rem'}} count={publishedDaysAgo} dangerousHTML />
    }
    if (publishedHoursAgo > 0) {
      return <Translate value='published.hoursAgo' style={{fontSize: fontSize ? fontSize : '0.9rem'}} count={publishedHoursAgo} dangerousHTML />
    }
    return <Translate value='published.minutesAgo' style={{fontSize: fontSize ? fontSize : '0.9rem'}} count={publishedMinutesAgo} dangerousHTML />
  }
