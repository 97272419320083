import React, {Component} from 'react'

export default class ComparePageLink extends Component {
  render() {
    const { experimentSearch } = this.props
    const buttonWord = this.props.buttonWord ? this.props.buttonWord : 'сравнить'
    return(
      <div>
        <a href={`compare?c=${experimentSearch ? experimentSearch.id : null}`} rel="noreferrer" style={{textTransform: 'uppercase', color: '#3F51B5', textDecoration: 'none', fontWeight: '500', fontSize: '0.875rem'}}>{buttonWord}</a>
      </div>
    )
  }
}
