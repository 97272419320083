import axios from 'axios'
import config from '../../config'

const HOST = config.HOST

export const FETCH_CREATE_REQUEST_START = 'editListingModal/FETCH_CREATE_REQUEST_START'
export const FETCH_CREATE_REQUEST_SUCCESS = 'editListingModal/FETCH_CREATE_REQUEST_SUCCESS'
export const FETCH_CREATE_REQUEST_ERROR = 'editListingModal/FETCH_CREATE_REQUEST_ERROR'

const initialState = {
    isFetch: false,
    isFetchRequest: false,
    error: null,
    isRequestSuccesful: false,
    user: null
  }

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CREATE_REQUEST_START:
            return {...state, isFetchRequest: true, error: null, isRequestSuccesful: false}
        case FETCH_CREATE_REQUEST_SUCCESS:
            return {...state, isFetchRequest: false, isRequestSuccesful: true}
        case FETCH_CREATE_REQUEST_ERROR:
            return {...state, isFetchRequest: false, error: action.payload, isRequestSuccesful: false}
        default: return state
    }
}

export let cancel = null;

export const fetchAddApartments = (request, experimentSearch, performExperimentSearch, setListing) => (dispatch, getState) => {
  dispatch({type: FETCH_CREATE_REQUEST_START})
    axios.post(`${HOST}api/listings`, request)
        .then((data) => {
          dispatch({type: FETCH_CREATE_REQUEST_SUCCESS})

          const newListingId = parseInt(data.data.data[0].id, 10)

          const newExperimentSearch =  {
            ...experimentSearch,
            benchmark_listing_id: newListingId,
            favorited_listing_ids: [ ...experimentSearch.favorited_listing_ids, newListingId ]
          }
          performExperimentSearch(newExperimentSearch, true).then(() => {
            setListing(newListingId, {is_active: true})
          }
        )

        })
        .catch(error => dispatch({type: FETCH_CREATE_REQUEST_ERROR, payload: error}))
}
