import React from 'react'

const Icon = () =>
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" height="512px" viewBox="0 0 512.00046 512" width="512px" className='likeIcon'>
		<g>
			<path d="m255.925781 496.003906s239.929688-127.964844
				239.929688-351.898437c3.148437-67.523438-49.039063-124.816407-116.5625-127.960938-3.800781-.179687-7.601563-.179687-11.398438
				0-46.5625.007813-89.445312 25.308594-111.96875 66.058594-22.523437-40.75-65.40625-66.050781-111.96875-66.058594-69.679687 2.332031-125.625
				58.28125-127.960937 127.960938 0 223.933593 239.929687 351.898437 239.929687 351.898437zm0 0"
				fill="transparent" data-original="transparent" data-old_color="transparent" className='filledHeart'
			/>
			<path d="m367.890625.148438c-43.488281.027343-84.636719 19.703124-111.964844
				53.535156-27.328125-33.832032-68.476562-53.507813-111.96875-53.535156-78.667969 1.984374-141.972656 65.289062-143.957031
				143.957031 0 230.875 238.328125 360.597656 248.390625 366.019531 4.714844 2.496094 10.355469 2.496094 15.070313 0
				10.058593-5.421875 248.390624-135.144531
				248.390624-366.019531 3.515626-75.988281-55.230468-140.441407-131.21875-143.957031-4.246093-.195313-8.496093-.195313-12.742187
				0zm-111.964844 477.410156c-39.859375-23.785156-223.933593-143.894532-223.933593-333.453125 2.3125-60.851563 51.113281-109.652344
				111.964843-111.96875 40.734375.039062 78.242188 22.171875 97.972657 57.808593 4.964843 7.730469 15.257812
				9.96875 22.984374 5.003907 2.007813-1.289063 3.714844-2.996094 5.007813-5.003907 19.726563-35.640624 57.234375-57.769531 97.96875-57.808593
				58.445313-3.390625 108.574219 41.234375 111.96875 99.679687.238281 4.09375.238281 8.195313 0 12.289063 0 189.558593-184.074219
				309.667969-223.933594 333.453125zm0 0"
				fill="#ffffff" data-original="#ffffff" data-old_color="#ffffff"
			/>
		</g>
	</svg>

export default Icon
