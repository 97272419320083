import { SET_ACTIVE } from '../../actions/experimentSearches'

const initialState = null

export default (state = initialState, action) => {
  if (action.type === SET_ACTIVE) {
    return action.payload
  } else {
    return state
  }
}
