import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Text} from 'react-form';

import {loginInputs} from '../../fixtures';

export default class Login extends Component {
    state = {
        formError: ''
    };

    static propTypes = {
        auth: PropTypes.object.isRequired,
        fetchLogin: PropTypes.func.isRequired,
    };

    handleFormSubmit = ({name, password}) => {
        const nameValue = name && name.trim();
        if (!nameValue || !password) {
            this.setState({formError: 'Укажите логин и пароль'});
            return;
        }
        if (this.state.formError) this.setState({formError: ''});
        const auth = {
            login: name,
            pwd: password
        };
        this.props.fetchLogin(auth);
    };

    renderFields = () => loginInputs.map(loginInput => {
        const inputType = loginInput.field === 'password' ? 'password' : 'text';
        return (
            <div key={loginInput.id}>
                <label htmlFor={loginInput.id}>{loginInput.text}</label>
                <Text type={inputType} field={loginInput.field} id={loginInput.id}/>
            </div>
        );
    });

    render() {
        const {auth} = this.props;
        const {formError} = this.state;
        return (
            <div>
                <Form onSubmit={this.handleFormSubmit}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <h1>Авторизация</h1>
                            {this.renderFields()}
                            <div>
                                <button type="submit" value="Submit" disabled={auth.isFetch}>Вход</button>
                            </div>
                        </form>
                    )}
                </Form>
                {formError && <div>{formError}</div>}
                {!formError && auth.error && <div>Логин или пароль указан неверно</div>}
            </div>
        );
    }
}
