import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'
import expansionPanel from '../ExpansionPanel'
import config from '../../config'

class SuggestedExperimentsStatistics extends Component {
  render() {
    const {suggested_experiments_statistics={}, all_experiments_statistics, results_count, category_bias} = this.props
    const total_commute_percentage = [1, 5, 10, 25, 50, 75, 90, 95, 99].find((pct) => ((suggested_experiments_statistics || {}).total_commute_seconds_per_year || {}).max < ((all_experiments_statistics || {}).approximate_total_commute_seconds_per_year || {})[`${pct}%`]) || 100
    const price_per_month_percentage = [1, 5, 10, 25, 50, 75, 90, 95, 99].find((pct) => ((suggested_experiments_statistics || {}).price_per_month_in_usd || {}).max < ((all_experiments_statistics || {}).price_per_month_in_usd || {})[`${pct}%`]) || 100
    const square_meters_percentage = 100 - ([99, 95, 90, 75, 50, 25, 10, 5, 1].find((pct) => ((suggested_experiments_statistics || {}).square_meters || {}).min > ((all_experiments_statistics || {}).square_meters || {})[`${pct}%`]) || 0)
    const price_per_month_in_usd = (suggested_experiments_statistics || {}).price_per_month_in_usd || {}
    const price_in_usd = (suggested_experiments_statistics || {}).price_in_usd || {}
    const square_meters = (suggested_experiments_statistics || {}).square_meters || {}
    const total_rooms = (suggested_experiments_statistics || {}).total_rooms || {}
    const upper_secondary_school_score = (suggested_experiments_statistics || {}).upper_secondary_school_score || {}
    const calculated_value = (suggested_experiments_statistics || {}).calculated_value || {}
    const year_built = (suggested_experiments_statistics || {}).year_built || {}
    const year_built_aging_building_maintenance_seconds_per_year = (suggested_experiments_statistics || {}).year_built_aging_building_maintenance_seconds_per_year || {}
    const commute_hours = (suggested_experiments_statistics || {}).total_commute_seconds_per_year || {}
    const rental_costs = (suggested_experiments_statistics || {}).rental_costs_seconds_per_year || {}

    return(
      <div>
        {suggested_experiments_statistics && Object.keys(suggested_experiments_statistics).length <= 0 && <div></div>}

        {Object.keys(suggested_experiments_statistics).length > 0 && <div className='infrastructure-info'>
<table>
  <colgroup span="4"></colgroup>
  <tbody>
  <tr>
    <th></th>
    <th>среднеe</th>
    <th>медиана</th>
    <th>min</th>
    <th>max</th>
  </tr>

  {(category_bias !== 'For Sale') && Math.round(price_per_month_in_usd.max, 10) > 0  && <tr>
    <td>арендная плата в грн</td>
    <td>{Math.round(price_per_month_in_usd.mean * 27, 10).toLocaleString()} грн в месяц</td>
    <td>{Math.round(price_per_month_in_usd['50%'] * 27, 10).toLocaleString()} грн в месяц</td>
    <td>{Math.round(price_per_month_in_usd.min * 27, 10).toLocaleString()} грн в месяц</td>
    <td>{Math.round(price_per_month_in_usd.max * 27, 10).toLocaleString()} грн в месяц</td>
  </tr>}

  {(category_bias !== 'For Sale') && Math.round(price_per_month_in_usd.max, 10) > 0 && <tr>
    <td>арендная плата в $</td>
    <td>{Math.round(price_per_month_in_usd.mean, 10).toLocaleString()} $ в месяц</td>
    <td>{Math.round(price_per_month_in_usd['50%'], 10).toLocaleString()} $ в месяц</td>
    <td>{Math.round(price_per_month_in_usd.min, 10).toLocaleString()} $ в месяц</td>
    <td>{Math.round(price_per_month_in_usd.max, 10).toLocaleString()} $ в месяц</td>
  </tr>}

  {(category_bias !== 'For Rent') && Math.round(price_in_usd.max, 10) > 0  && <tr>
    <td>цена в грн</td>
    <td>{Math.round(price_in_usd.mean * 27, 10).toLocaleString()} грн</td>
    <td>{Math.round(price_in_usd['50%'] * 27, 10).toLocaleString()} грн</td>
    <td>{Math.round(price_in_usd.min * 27, 10).toLocaleString()} грн</td>
    <td>{Math.round(price_in_usd.max * 27, 10).toLocaleString()} грн</td>
  </tr>}

  {(category_bias !== 'For Rent') && Math.round(price_in_usd.max, 10) > 0  && <tr>
    <td>цена в $</td>
    <td>{Math.round(price_in_usd.mean, 10).toLocaleString()} $</td>
    <td>{Math.round(price_in_usd['50%'], 10).toLocaleString()} $</td>
    <td>{Math.round(price_in_usd.min, 10).toLocaleString()} $</td>
    <td>{Math.round(price_in_usd.max, 10).toLocaleString()} $</td>
  </tr>}

  {config.SHOW_STATS_DEBUG && <tr>
    <td>rental_costs</td>
    <td>{Math.round(rental_costs.mean*10/3600, 10)/10}</td>
    <td>{Math.round(rental_costs['50%'] * 10/3600, 10)/10}</td>
    <td>{Math.round(rental_costs.min/3600, 10)}</td>
    <td>{Math.round(rental_costs.max/3600, 10)}</td>
  </tr>}

  <tr>
    <td>кв.м.</td>
    <td>{Math.round(square_meters.mean, 10)} кв.м.</td>
    <td>{Math.round(square_meters['50%'], 10)} кв.м.</td>
    <td>{Math.round(square_meters.min, 10)} кв.м.</td>
    <td>{Math.round(square_meters.max, 10)} кв.м.</td>
  </tr>

  <tr>
    <td>комнат</td>
    <td>{Math.round(total_rooms.mean*10, 10)/10}</td>
    <td>{Math.round(total_rooms['50%'] * 10, 10)/10}</td>
    <td>{Math.round(total_rooms.min, 10)}</td>
    <td>{Math.round(total_rooms.max, 10)}</td>
  </tr>

  {config.SHOW_STATS_DEBUG && <tr>
    <td>year_built</td>
    <td>{Math.round(year_built.mean*10, 10)/10}</td>
    <td>{Math.round(year_built['50%'] * 10, 10)/10}</td>
    <td>{Math.round(year_built.min, 10)}</td>
    <td>{Math.round(year_built.max, 10)}</td>
  </tr>}

  {config.SHOW_STATS_DEBUG && <tr>
    <td>year_built_maintenance</td>
    <td>{Math.round(year_built_aging_building_maintenance_seconds_per_year.mean*10/3600, 10)/10}</td>
    <td>{Math.round(year_built_aging_building_maintenance_seconds_per_year['50%'] * 10/3600, 10)/10}</td>
    <td>{Math.round(year_built_aging_building_maintenance_seconds_per_year.min/3600, 10)}</td>
    <td>{Math.round(year_built_aging_building_maintenance_seconds_per_year.max/3600, 10)}</td>
  </tr>}

  <tr>
    <td>часов в дороге</td>
    <td>{Math.round(commute_hours.mean*10/3600, 10)/10}</td>
    <td>{Math.round(commute_hours['50%'] * 10/3600, 10)/10}</td>
    <td>{Math.round(commute_hours.min/3600, 10)}</td>
    <td>{Math.round(commute_hours.max/3600, 10)}</td>
  </tr>

  {Math.round(upper_secondary_school_score.mean*10, 10) > 0 && <tr>
    <td>ЗНО школы</td>
    <td>{Math.round(upper_secondary_school_score.mean*10, 10)/10 || ''}</td>
    <td>{Math.round(upper_secondary_school_score['50%'] * 10, 10)/10 || ''}</td>
    <td>{Math.round(upper_secondary_school_score.min, 10)}</td>
    <td>{Math.round(upper_secondary_school_score.max, 10)}</td>
  </tr>}

  <tr>
    <td>Оценка <span className='wayleap-logo'>Wayleap</span></td>
    <td>{Math.round(calculated_value.mean*10, 10)/10 || ''}</td>
    <td>{Math.round(calculated_value['50%'] * 10, 10)/10 || ''}</td>
    <td>{Math.round(calculated_value.min, 10)}</td>
    <td>{Math.round(calculated_value.max, 10)}</td>
  </tr>

  {false && <tr>
    <td></td>
    <td>среднеe</td>
    <td>Медиана</td>
    <td>Min</td>
    <td>Max</td>
  </tr>}

  {false && <tr>
    <td></td>
    <td>среднеe</td>
    <td>Медиана</td>
    <td>Min</td>
    <td>Max</td>
  </tr>}
</tbody>
</table>
          {config.SHOW_SHIT && <div>
            Все подобранные квартиры входят в
          </div>}

          {config.SHOW_SHIT && <div>
            {Math.ceil(20*100.0*10/results_count)/10}% лучших квартир Киева
          </div>}

          {config.SHOW_SHIT && total_commute_percentage <= 50 && <div>
            {total_commute_percentage}% лучших квартир Киева по удобному месторасположению
          </div>}

          {config.SHOW_SHIT && price_per_month_percentage <= 50 && <div>
            {price_per_month_percentage}% лучших квартир Киева по цене
          </div>}

          {config.SHOW_SHIT && square_meters_percentage <= 50 && <div>
            {square_meters_percentage}% лучших квартир Киева по просторности квартиры
          </div>}

          {config.SHOW_SHIT && <div className='call-buttons'>
            <div className=''>

            </div>
          </div>}
        </div>
        }
      </div>
    )
  }
}

export default howerObserver(togleShowAllMap(withRouter(expansionPanel(SuggestedExperimentsStatistics, 'Cтатистика по результатам запроса'))))
