import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import CurrencySwitcher from '../../components/CurrencySwitcher'
import * as actions from '../../actions/experimentSearches'
import Select from '../../components/Select'
import DialogSelect from '../../components/DialogSelect'
import { pricesForRentMin, pricesForRentMax,
  pricesForSaleMin, pricesForSaleMax } from '../../fixtures'

class MinAndMaxPriceSelect extends Component {
  constructor(props) {
    super(props)
    const category_from_url = window.location.pathname.substring(1)
    const category_bias = `For ${category_from_url[0].charAt(0).toUpperCase() + category_from_url.slice(1)}`

    this.state = {
     price_per_month_min: this.props.experimentSearch.price_per_month_min || '',
     price_per_month_max: this.props.experimentSearch.price_per_month_max || '',
     price_min: this.props.experimentSearch.price_min || '',
     price_max: this.props.experimentSearch.price_max || '',
     category_bias: category_bias,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.experimentSearch !== prevProps.experimentSearch) {
      const { price_per_month_min, price_per_month_max, price_min, price_max } = this.props.experimentSearch
      this.setState({ price_per_month_min, price_per_month_max, price_min, price_max })
    }
  }

  toggleRental(type) {
    this.setState({
      categoryBias: type
    })
  }

  getTitle = (min, max) => {
    if (max && min) {
      return `цена ${min/1000}-${max/1000}k`
    } else if (max) {
      return `цена до ${max/1000}k`
    } else if (min) {
      return `цена от ${min/1000}k`
    } else {
      return 'цена'
    }
  }

  render(){
    const [ experimentSearch ] = this.props.experimentSearches
    const { setExperimentSearch } = this.props
    const { category_bias, price_per_month_min, price_per_month_max } = this.state
    const title = this.getTitle(price_per_month_min, price_per_month_max)
  //   <div className='rent-type'>
  //   <div onClick={()=> {
  //     this.toggleRental('For Rent')
  //     setExperimentSearch({
  //        ...experimentSearch,
  //        category_bias: 'For Rent'
  //      })}}
  //    className={`${category_bias === 'For Rent' ? 'active ' : 'inactive '}section`}>снять квартиру{/*I18n.t('experimentSearch.toRent')*/}</div>
  //   <div onClick={()=>{
  //     this.toggleRental('For Sale')
  //     setExperimentSearch({
  //        ...experimentSearch,
  //        category_bias: 'For Sale'
  //      })}}
  //    className={`${category_bias === 'For Sale' ? 'active ' : 'inactive '}section`}>купить квартиру{/*I18n.t('experimentSearch.toSale')*/}</div>
  // </div>
    return (
      <DialogSelect title={title}>
    <div className='infrastructure-info pagination-row'>

      {category_bias === 'For Rent' && <div className='price-select'>

        <div className='price-row'>
          <span className='price-text'>
            {I18n.t('experimentSearch.price')} от
          </span>
           <Select
             onChange = {(e) => {
              this.setState({price_per_month_min: e})
              setExperimentSearch({
                 ...experimentSearch,
                 category_bias,
                 price_per_month_min: e
               })}}
             value={this.state.price_per_month_min || ''}
             label={this.state.price_per_month_min || ''}
             values={pricesForRentMin[experimentSearch.currency_bias]}
             />
           <CurrencySwitcher
             experimentSearch={experimentSearch}
             setExperimentSearch={setExperimentSearch}/>
         </div>

         <div className='price-row-second'>
           <span className='price-text'>
            до
           </span>
           <Select
             onChange = {(e) => {
               this.setState({price_per_month_max: e})
               setExperimentSearch({
                 ...experimentSearch,
                 category_bias,
                 price_per_month_max: e
               })}}
             value={this.state.price_per_month_max || ''}
             label={this.state.price_per_month_max || ''}
             values={pricesForRentMax[experimentSearch.currency_bias]}/>
            <CurrencySwitcher
              experimentSearch={experimentSearch}
              setExperimentSearch={setExperimentSearch}/>
          </div>

        </div>}
       {category_bias === 'For Sale' && <div className='price-select'>

         <div className='price-row'>
           <span className='price-text'>
           {I18n.t('experimentSearch.price')} от
           </span>
           <Select
             onChange = {(e) => {
              this.setState({price_min: e})
              setExperimentSearch({
                 ...experimentSearch,
                 category_bias,
                 price_min: e
               })}}
             value={this.state.price_min || ''}
             label={this.state.price_min || ''}
             values={pricesForSaleMin[experimentSearch.currency_bias]}/>
           <CurrencySwitcher
             experimentSearch={experimentSearch}
             setExperimentSearch={setExperimentSearch}/>
         </div>

         <div className='price-row-second'>
           <span className='price-text'>
            до
           </span>
           <Select
             onChange = {(e) => {
               this.setState({price_max: e})
               setExperimentSearch({
                 ...experimentSearch,
                 category_bias,
                 price_max: e
               })}}
             value={this.state.price_max || ''}
             label={this.state.price_max || ''}
             values={pricesForSaleMax[experimentSearch.currency_bias]}/>
            <CurrencySwitcher
              experimentSearch={experimentSearch}
              setExperimentSearch={setExperimentSearch}/>
          </div>
      </div>}
    </div>

</DialogSelect>

    )
  }
}
const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
})
export default connect(mapStateToProps, { ...actions })(MinAndMaxPriceSelect)
