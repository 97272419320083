import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {Switch, Route} from 'react-router-dom'

// import MainNavigation from '../components/MainNavigation'
import {renderRoutes} from './routes'

export default class AppContent extends Component {
    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         isOpen: false
    //     }
    //     this.logoutTimer = null
    // }

    static propTypes = {
        history: PropTypes.object.isRequired,
    }

    // componentDidMount() {
    //     if (process.env.NODE_ENV === 'development') this.setLogoutTimer()
    // }
    //
    // componentDidUpdate(prevProps) {
    //     if (prevProps.location !== this.props.location) {
    //         clearTimeout(this.logoutTimer)
    //         if (process.env.NODE_ENV === 'development') this.setLogoutTimer()
    //     }
    // }
    //
    // componentWillUnmount() {
    //     clearTimeout(this.logoutTimer)
    // }
    //
    // handleSideNavSelect = (location, history) => selected => {
    //     if (location.pathname !== selected) history.push(selected)
    // }
    //
    // handleSideNavToggle = () => this.setState((prevState) => ({
    //     isOpen: !prevState.isOpen
    // }))
    //
    // setLogoutTimer = () => {
    //     const time = 60000 * 15
    //     this.logoutTimer = setTimeout(() => {this.props.history.push('/exit')}, time)
    // }

    render() {
        return (
            <Route render={({location, history}) => (
                <Fragment>
                        <div>
                            <Switch>
                                {renderRoutes()}
                            </Switch>
                        </div>
                </Fragment>
            )}
            />
        )
    }
}
