import React, { Component } from 'react'
import { Router, Route, Redirect, Switch  } from 'react-router-dom'
import createBrowserHistory from 'history/createBrowserHistory'
import { MuiThemeProvider } from '@material-ui/core/styles'
// import {connect} from 'react-redux'
import moment from "moment/moment"
import 'moment/locale/ru'

// import * as authActions from "../redux/auth"
// import BurgerMenu from './components/BurgerMenu'
// import { scaleRotate as Menu } from 'react-burger-menu'
import Login from './components/Login'
import Loader from './components/Loader'
import AppContent from './AppContent'

const history = createBrowserHistory()
window.routerHistory = history

moment.locale('ru')

export default class App extends Component {
    // static propTypes = {
    //     auth: PropTypes.object.isRequired,
    //     fetchLogin: PropTypes.func.isRequired,
    //     fetchLogout: PropTypes.func.isRequired,
    // }

    // componentDidMount() {
    //     window.onbeforeunload = () => {
    //         const {auth, fetchLogout} = this.props
    //         if (process.env.NODE_ENV !== 'development' && auth.session) fetchLogout()
    //     }
    // }
    //
    renderLogin = props => {
        const {auth, fetchLogin} = this.props
        return !auth.session
            ? <Login {...props} {...{auth, fetchLogin}} />
            : <Redirect to="/" />
    }

    renderAppContent = props => <AppContent {...props} />
       // this.props.auth.session
       //  ? <AppContent {...props} />
       //  : <Redirect to="/login" />


       // change
       // const auth = {}
       // to
       // const {auth} = {this.props}


    render() {
        const auth = {}

        return (
          <div id="outer-container">
            {/* <BurgerMenu /> */}
            {/*<Menu pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } />*/}
            <main id="page-wrap">
              {/* <MuiThemeProvider> */}
                <Router {...{history}}>
                    <div className="App">
                      {auth.isFetch && <Loader />}
                      <Switch>
                        <Route path="/login" render={this.renderLogin} />
                        <Route path="/" render={this.renderAppContent} />
                      </Switch>
                    </div>
                </Router>
              {/* </MuiThemeProvider> */}
            </main>
          </div>
        )
    }
}

// export default connect(
//     ({auth}) => ({
//         auth
//     }),
//     {
//         ...authActions
//     }
// )(App)





// ~70.000$

// ne viwe 7ogo etaja
// 1-2 komnatnuyu

// 2000+ goda postroiki
// s remontom kosmeti4eskim
// mebel' mojno bez i s mebel'yu

// dlya sda4i, i 4erez let 7 dlya odnogo iz 2-uh vnukov.


// School
// Metro
// --------- ostal'noe na moe usmotrenie


// V lyubom meste goroda

// +380 99 027 32 77