import { cloneDeep } from 'lodash'
import { deserialize } from '../utils/deserialize'
import { SET_AMENITY_TRIPS } from '../../actions/experimentSearches'
import { UPDATE_AMENITY_TRIP } from '../../actions/amenityTrips'

const initialState = []

export default (state = initialState, action) => {
  if (action.type === SET_AMENITY_TRIPS) {
    return deserialize(action.payload)
  } else if (action.type === UPDATE_AMENITY_TRIP) {
    const amenityTrips = cloneDeep(state)
    const objIndex = amenityTrips.findIndex((obj => obj.id === action.amenityTrip.id))
    amenityTrips[objIndex] = action.amenityTrip

    return amenityTrips
    
  } else {
    return state
  }
}
