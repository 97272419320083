import React, { Component } from 'react'
import { Translate } from 'react-redux-i18n'

class UnableToContactServer extends Component{
    constructor(props) {
    super(props)

    this.state = {
      display : "none",
    }
  }

  componentDidMount() {
    this._timer = setTimeout(() => { this.show() }, this.props.wait)
  }

  componentWillUnmount() {
    clearTimeout(this._timer)
  }

  show(){
    this.setState({display : ""})
  }

  render() {
    return (
      <div style={{ display: this.state.display }}>
        <p><Translate value='error.unableToContactServer' /></p>
      </div>
    )
  }
}

export default UnableToContactServer
