import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import CurrencySwitcher from '../../components/CurrencySwitcher'
import { I18n } from 'react-redux-i18n'

import ShowPlacesAutocomplete from '../../components/ShowPlacesAutocomplete'

import * as addApartmentsModalActions from '../../store/reducers/addApartmentsModal'
import * as experimentSearchesActions from '../../actions/experimentSearches'
import * as placeSearchesActions from '../../actions/placeSearches'
import config from '../../config'
const HOST = config.HOST

class AddApartmentsModal extends Component {
  constructor(props) {
      super(props)
      this.state = {
        price: '',
        rooms: '',
        meters: '',
        place: {},
        address: '',
        isRequestSent: false,
        isRental: false,
        localExperimentSearch: {currency_bias:  this.props.experimentSearches[0].currency_bias || null},
      }
  }

  setInput = (name, value) => {
    this.setState({[name]: value})
  }

  toggleRental() {
    this.setState({
      isRental: !this.state.isRental
    })
  }

  sendRequest = () => {
    const { price, rooms, meters, place, isRental } = this.state
    const [ experimentSearch ] = this.props.experimentSearches
    const { performExperimentSearch, user, setListing } = this.props
    const listingEntity = user.length > 0 ?
      { listing_entity: {email : user[0].email, entity_type: 'individual'} }
      : {listing_entity: {name: 'unauthorized user', entity_type: 'individual'}}

    axios.post(`${HOST}api/listing_entities/`, listingEntity)
    .then(response => {
      const request = {
        listing: {
          is_active: false,
          [isRental ? 'price_per_month': 'price']: price,
          total_rooms: rooms,
          bedrooms: rooms,
          square_meters: meters,
          place_id: place.id,
          listing_entity_id: response.data.data[0].id,
          listing_type: isRental ? 'For Rent' : 'For Sale',
          source_title: place.attributes ? place.attributes.full_address : '',
          currency_code: experimentSearch.currency_bias,
          source_url: '',
          is_preprocessed: true,
          category: 'apartment',
          aggregator_name: 'agent',
          lease_term: 'long-term',
        }
      }
      this.setState({ isRequestSent: true })
      if ( price !== '' && rooms !== '' && meters !== '' && Object.keys(place).length>0) {
        this.props.toggleShowModal()
        this.props.fetchAddApartments(request, experimentSearch, performExperimentSearch, setListing)
      }
    })
  }

  handleSelect = (address) => {
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng =>
      axios.post(`${HOST}api/places/`, {
        latitude: parseFloat(latLng.lat.toFixed(7)),
        longitude: parseFloat(latLng.lng.toFixed(7)),
        full_address: address,
        address_completeness_level: 0,
        is_address_complete: true
      })
    )
    .then(response => {
      const places = response.data.data
      const [ place ] = places

      this.props.addPlaces(places)
      this.setState({ place, address})
    })
  }

  prepareForUpdate = (value) => {
    this.setState({ address: value  })
  }
  setLocalExperimentSearch = (experimentSearch) => {
    this.setState({localExperimentSearch: experimentSearch})
  }

  render () {
    const [ experimentSearch ] = this.props.experimentSearches
    const { isRequestSuccesful } = this.props.addApartmentsModal
    const { isRequestSent, price, rooms, meters, place, address, isRental, localExperimentSearch } = this.state

    return (
       <div className='request-add_apartments'>
          <p className='request-tour_header'>
            Добавить для сравнения
          </p>

          <div className='section-row'>
            <div onClick={() => this.toggleRental()}
             className={`${isRental ? 'active ' : ''} section`}>{I18n.t('experimentSearch.rent')}</div>
            <div onClick={() => this.toggleRental()}
             className={`${!isRental ? 'active ' : ''} section`}>{I18n.t('experimentSearch.sale')}</div>
          </div>

          <div className='request-tour_input'>
            <input type="text" placeholder={isRental ? 'Цена за месяц' : 'Цена'} className='' value={price} onChange={(e)=>this.setInput('price', e.target.value)}/>
          <CurrencySwitcher
            experimentSearch={localExperimentSearch}
            setExperimentSearch={this.setLocalExperimentSearch}
          />
          </div>

          {price === ''  && isRequestSent && <p style={{color: 'red'}}>
            введите цену
          </p>}
          <div className='request-tour_input'>
            <input type="text" placeholder='Количество комнат' className='' value={rooms} onChange={(e)=>this.setInput('rooms', e.target.value)}/>
          </div>
          {rooms === ''  && isRequestSent && <p style={{color: 'red'}}>
            введите количество комнат
          </p>}
          <div className='request-tour_input'>
            <input type="text" placeholder='Метров' className='' value={meters} onChange={(e)=>this.setInput('meters', e.target.value)}/>
          </div>
          {meters === '' && isRequestSent && <p style={{color: 'red'}}>
            введите количество метров
          </p>}
          <div className='request-tour_input'>
          <ShowPlacesAutocomplete
            isModal={true}
            value={address}
            prepareForUpdate={this.prepareForUpdate}
            handleSelect={this.handleSelect}
            experimentSearch={experimentSearch}/>
          </div>
          {Object.keys(place).length < 1 && isRequestSent && <p style={{color: 'red'}}>
            введите адрес
          </p>}
          <button className='request-tour_send-btn' style={{ margin: '10px'}} onClick={()=>this.sendRequest()}>
            Добавить
          </button>
          { isRequestSent && isRequestSuccesful &&
            <p className='request-tour_day'>
              Спасибо, ваша заявка отправлена
            </p>
          }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  placeSearches: state.placeSearches,
  experimentSearches: state.experimentSearches,
  addApartmentsModal: state.addApartmentsModal,
  user: state.user
})

export default connect(mapStateToProps, { ...addApartmentsModalActions, ...experimentSearchesActions, ...placeSearchesActions})(AddApartmentsModal)
