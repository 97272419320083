import React, { PureComponent } from 'react'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import CreatableSelect from 'react-select/lib/Creatable'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 50,
  },
  input: {
    display: 'flex',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 16,
    minWidth: 100,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    width: 23,

  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    minWidth: 55,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 8,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
})
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props}/>
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      onDelete={props.removeProps.onClick}
    />
  )
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class IntegrationReactSelect extends PureComponent {
  constructor(props) {
      super(props)
      this.state = {
       inputValue: '',
       isOpen: false,
       label: this.props.label || null,
       value: this.props.value || null,
      }
  }
  handleChange = (newValue: any, actionMeta: any) => {
    if (newValue !== '') {
      this.props.onChange(newValue.value)
    }
  }
  handleInputChange = (inputValue: any, actionMeta: any) => {
    if (inputValue !== '') {
      this.props.onChange(parseInt(inputValue, 10))
    }
  }
  onMenuOpen = (props) => {
    this.setState({label: '', value: ''})
  }
  componentDidUpdate(prevProps) {
    if (this.props.label !== prevProps.label) {
      this.setState({ label: this.props.label})
    }
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value})
    }
  }

  render() {
    const { classes } = this.props

    return (
      <NoSsr>
        <CreatableSelect
          classes={classes}
          components={components}
          placeholder={''}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onMenuOpen={this.onMenuOpen}
          options={this.props.values}
          menuPortalTarget={document.body}
          value={{label: this.state.label, value: this.state.value}}
        />
      </NoSsr>

    )
  }
}
export default withStyles(styles)(IntegrationReactSelect)
