import React, { PureComponent } from 'react'

export default function togleShowAllMap(InputComponent) {
  return class extends PureComponent {
    componentDidUpdate(prevProps) {
      if (this.props.isHovering !== prevProps.isHovering && this.props.isHovering) {
         if (this.props.showAllMap) {
           this.props.showAllMap(true)
         } else {
           // leave this console log for components that dont have to update map
           console.log('this.props.showAllMap not defined')
         }
      }
    }
    render() {
      return <InputComponent {...this.props}/>
    }
  }
}
