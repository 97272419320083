import { SET_AMENITIES } from '../../actions/experimentSearches'
import { deserialize } from '../utils/deserialize'

const initialState = []

export default (state = initialState, action) => {
  if (action.type === SET_AMENITIES) {
    return deserialize(action.payload)
  } else {
    return state
  }
}
