import { UPDATE_PLACES, SET_PLACES } from '../../actions/experimentSearches'
import { ADD_PLACES } from '../../actions/placeSearches'
import { deserialize } from '../utils/deserialize'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PLACES:
      return deserialize(action.payload)
    case SET_PLACES:
    case ADD_PLACES:
      const combined = state.concat(deserialize(action.payload))
      return Array.from(new Set(combined))
    default:
      return state
  }
}
