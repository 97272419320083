import React, { Component } from 'react'
import { connect } from 'react-redux'

import emailIcon from '../../assets/mail.svg'
import Button from '@material-ui/core/Button'

import * as experimentSearchesActions from '../../actions/experimentSearches'

class RequestEmailModal extends Component {
  constructor(props) {
      super(props)
      this.state = {
        email: this.props.user[0] ? this.props.user[0].email : '',
        isEmailCorrect: false,
        isSubscribeRequestSent: false,
        isUnSubscribeRequestSent: false,
      }
  }

  setInput = (name, value) => {
    this.setState({[name]: value})
  }

  sendSubscribeRequest = () => {
    const {email} = this.state
    const [ experimentSearch ] = this.props.experimentSearches
    const { updateExperimentSearch } = this.props

    const isEmailCorrect = !!email ? (email.includes('@') && email.includes('.')) : false

    this.setState({ isSubscribeRequestSent: true, isEmailCorrect })

    const userId = this.props.user[0] ? this.props.user[0].id : null
    const shouldUserUpdate = userId ? !(email === this.props.user[0].email)
                             : null
    const user = this.props.user[0] ? this.props.user[0] : {}
    user.email = email
    
    isEmailCorrect && this.props.sendSubscribeRequest(userId, shouldUserUpdate, user, experimentSearch, updateExperimentSearch)
    isEmailCorrect && this.props.toggleShowModal()
  }
  sendUnSubscribeRequest = () => {
    const [ experimentSearch ] = this.props.experimentSearches
    const { updateExperimentSearch } = this.props

    experimentSearch.is_subscribed = false
    updateExperimentSearch(experimentSearch)

    this.props.toggleShowModal()
  }

  render () {
    const { isEmailCorrect , email, isSubscribeRequestSent } = this.state
    const [ experimentSearch ] = this.props.experimentSearches

    if (experimentSearch.is_subscribed) {
      return (
       <div>
          <div className='request-tour_input'>
            <img src={emailIcon} alt="" />
            <input type="email" placeholder='Email' value={email} onChange={(e)=> {
              e.preventDefault()
              this.setInput('email', e.target.value)
            }
            }/>
          </div>

          <Button className='request-tour_send-btn'
              style={{padding: "5px 5px", minHeight: "30px"}}
              variant="contained"
              color="default"
              onClick={()=>this.sendUnSubscribeRequest()}>
            Отписаться
          </Button>
      </div>
      )
    } else {
      return (
       <div>
          Подписаться на новые объявления, которые попадают в топ 30 по этому запросу
          <div className='request-tour_input'>
            <img src={emailIcon} alt="" />
            <input type="email" placeholder='Email' value={email} onChange={(e)=> {
              e.preventDefault()
              this.setInput('email', e.target.value)
            }
            }/>
          </div>
          {((!email || !isEmailCorrect) && isSubscribeRequestSent) && <p style={{color: 'red'}}>
            Пожалуйста, введите правильный email
          </p>}
          <Button className='request-tour_send-btn'
              style={{padding: "5px 5px", minHeight: "30px"}}
              variant="contained"
              color="default"
              onClick={()=>this.sendSubscribeRequest()}>
            Подписаться
          </Button>
      </div>
      )

    }
  }
}
const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
  user: state.user,
})

export default connect(mapStateToProps, { ...experimentSearchesActions})(RequestEmailModal)
