/* global google */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGoogleMap, GoogleMap } from "react-google-maps"   // withScriptjs, Marker, InfoBox
import memoize from 'memoize-one'
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel"
import config from '../../config'
import * as _ from 'lodash'

import red_icon from '../../assets/map/red_icon.png'
import blue_icon from '../../assets/map/blue_icon.png'
// import likeFilled from '../../assets/heartFilled.svg'

const listingIconSettings = {
  url: red_icon,
  size: new google.maps.Size(15, 20),
  scaledSize: new google.maps.Size(15, 20),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(7.5,20),
  labelOrigin: new google.maps.Point(20,17)
}

const highlightedListingIconSettings = {
  url: red_icon,
  size: new google.maps.Size(30, 40),
  scaledSize: new google.maps.Size(30, 40),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(15,40),
  labelOrigin: new google.maps.Point(20,17),
}

const iconSettings = {
  url: blue_icon,
  color: 'blue',
  size: new google.maps.Size(20, 30),
  scaledSize: new google.maps.Size(20, 30),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(12,25),
  labelOrigin: new google.maps.Point(20,17)
}

const labelStyle = {
  background: 'white',
  lineHeight: '17px',
  fontWeight: 'bold',
  opacity: 0.9,
  color: "black",
  // zIndex: '3',
  fontSize: "16px",
  pointerEvents: "none",
  textAlign: "center",
  // width: "50px",
}
const amenityLabelStyle = {
  background: 'white',
  lineHeight: '17px',
  opacity: 0.9,
  color: "black",
  // zIndex: '1',
  fontSize: "12px",
  pointerEvents: "none",
  textAlign: "center",
  // width: "auto",
}
const singleLabelStyle = {
  background: 'white',
  lineHeight: '17px',
  opacity: 0.9,
  color: "black",
  // zIndex: '5',
  fontSize: "14px",
  pointerEvents: "none",
  textAlign: "center",
  // width: "120px",
}

const listingLabelStyle = {
  background: 'white',
  lineHeight: '14px',
  fontWeight: 'bold',
  opacity: 0.9,
  color: "black",
  // zIndex: '2',
  fontSize: "10px",
  pointerEvents: "none",
  textAlign: "center",
  // width: "40px",
}
const redListingLabelStyle = {
  background: 'yellow',
  lineHeight: '17px',
  fontWeight: 'bold',
  opacity: 0.9,
  color: "black",
  zIndex: '999999999999',
  fontSize: "12px",
  pointerEvents: "none",
  textAlign: "center",
  // width: "80px",
}

class ExperimentMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shownExperiment: null,
      amenities: [],
    }
  }
  showExperimentSalePage = (shownExperiment) => {
    this.props.setExperimentItemId(shownExperiment.id)
    this.setState({shownExperiment: shownExperiment.id })
  }

  hideExperimentSalePage = () => {
    this.setState({shownExperiment: null })
  }

  componentDidMount() {
    this.setAmenities(this.props.amenityTrips, this.props.amenityRoutes)
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  }
  componentDidUpdate(prevProps) {
     if (this.props.amenityTrips !== prevProps.amenityTrips || this.props.amenityRoutes !== prevProps.amenityRoutes) {
       this.setAmenities(this.props.amenityTrips, this.props.amenityRoutes)
     }

   }

  setAmenities =  memoize((amenityTrips, amenityRoutes) => {
    const filteredAmenityTrips = amenityTrips.filter(item => item.is_activated === true || item.round_trips_per_week > 0 )
    const amenities = this.props.amenities.filter(item => filteredAmenityTrips.find(a => a.amenity_id === item.id))

    this.setState({ amenities })
  })

  getShortReadableAddress = (place) => {
    if (place.street) {
      return `${place.street} ${place.house}`
    } else {
      return place.full_address && place.full_address.split(' ').slice(0,2).map(word => word.substring(0,8).replace(/,\s*$/, "")).join(' ')
    }
  }

  render() {
    const { placeSearches, suggestedExperiments, places, listings, experimentItemIdOnScroll, zoom, isSingleMarker, amenityLocations } = this.props
    const experimentSearch = this.props.experimentSearches[0]
    const { amenities } = this.state

    const dynamicZoom = experimentItemIdOnScroll ? 14 : zoom
    // const centeredPlace = experimentItemIdOnScroll ? places.find(place => place.id === suggestedExperiments.find(e => e.id === experimentItemIdOnScroll).place_id) : null
    const centeredExperiment = experimentItemIdOnScroll && suggestedExperiments ? suggestedExperiments.find(e => e.id === experimentItemIdOnScroll) : null
    const centeredPlaceId = experimentItemIdOnScroll && centeredExperiment ? centeredExperiment.place_id : null
    const centeredPlace = centeredPlaceId && experimentItemIdOnScroll ? places.find(place => place.id === centeredPlaceId) : null
    const dynamicLat = centeredPlace ? parseFloat(centeredPlace.latitude) : parseFloat(experimentSearch.map_latitude)
    const dynamicLng = centeredPlace ? parseFloat(centeredPlace.longitude) : parseFloat(experimentSearch.map_longitude)

    const markersWithLabels = placeSearches.map((placeSearch) => {
      const place = places.find(p => p.id === placeSearch.place_id)
      // const doubledPlace = place ? places.find(item => (item.longitude === place.longitude && item.latitude === place.latitude)) : null

      if ( place ) {
        const { latitude, longitude } = place

        return  <MarkerWithLabel
                  key={`place_search_${placeSearch.id}`}
                  position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                  labelAnchor={new google.maps.Point(30, 0)}
                  icon={iconSettings}
                  labelStyle={labelStyle}
                  labelInBackground={true}
                >
                  <div style={{zIndex: '2'}}> {this.getShortReadableAddress(place)}</div>
                </MarkerWithLabel>
      }
      return null
    })

    const amenityMarkers = amenities.map((amenity) => {
      if ( amenity && isSingleMarker ) {
        return amenityLocations.filter(item => parseInt(item.amenity_id, 10) === amenity.id).map(amenityLocation => {
          const amenityLocationName = amenityLocation.name
          const amenityLocationPlace = this.props.places.find(item => parseInt(item.id, 10) === amenityLocation.place_id)
          const { latitude, longitude } = amenityLocationPlace

          return <MarkerWithLabel
                  key={amenityLocation.id}
                  position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                  labelAnchor={new google.maps.Point(30, 0)}
                  icon={iconSettings}
                  labelStyle={amenityLabelStyle}
                  labelInBackground={true}
                >
                  <div style={{zIndex: '0'}}>{amenity.name_uk === 'Школа' ? 'Школа №' + amenityLocationName.substring(amenityLocationName.indexOf('№')).match(/\d+/) : amenityLocationName}</div>
                  </MarkerWithLabel>
        })
      }
      return null
    })
    const suggestedExperimentsPlaces = suggestedExperiments.map(item => {return places.find(place => item.place_id === place.id)}).filter(item =>item)

    const markersResults = suggestedExperiments
      .map((suggestedExperiment, i) => {
        const place = places.find(place => place.id === suggestedExperiment.place_id)
        const listing = listings.find(listing => listing.id === suggestedExperiment.listing_id)
        if (!place) {
          return <div key={i}/>
        }
        const value = suggestedExperiment.calculated_value
        const isValuePositive = value > 0
        // const isValueGreat = value > 80

        // magic numbers from https://livegpstracks.com/default.php?ch=converter#cnv2  50.502294,30.497209  50.502294,30.497350 ==> 10 m its only for Kiev latitude

        const doubledPlaces = suggestedExperimentsPlaces.filter(item => item.id !== place.id).filter(item => (
          Math.abs(parseFloat(item.longitude) - parseFloat(place.longitude)) < 0.000141 &&
          Math.abs(parseFloat(item.latitude) - parseFloat(place.latitude)) < 0.000088
        ))

        const doubledPlace = doubledPlaces.length > 0 ? doubledPlaces[0] : {}
        const doubledExperiment = doubledPlace.id ? suggestedExperiments.find(item => item.place_id === doubledPlace.id) : {}
        const doubledExperimentValue = doubledExperiment ? 100 - Math.round((doubledExperiment.total_seconds_per_year - suggestedExperiments.find(e => e.rank === 1).total_seconds_per_year)/3600) : null
        const isDoubledExperimentValuePositive = value > 0
        // const isDoubledExperimentValueGreat = value > 80
        const isShowDoubledLabel = Object.keys(doubledPlace).length > 0

        return listing && <MarkerWithLabel
          key={suggestedExperiment ? suggestedExperiment.id : i}
          position={{ lat: parseFloat(place.latitude), lng: parseFloat(place.longitude) }}
          labelAnchor={((experimentItemIdOnScroll === suggestedExperiment.id) || (this.state.shownExperiment === suggestedExperiment.id)) ? new google.maps.Point(50, 0) : new google.maps.Point(10, 0)}
          icon={suggestedExperiment.id === experimentItemIdOnScroll ? highlightedListingIconSettings : listingIconSettings}
          labelStyle={suggestedExperiment.id === experimentItemIdOnScroll ? redListingLabelStyle : listingLabelStyle}
          labelInBackground={suggestedExperiment.id !== experimentItemIdOnScroll}
          onClick={() => this.showExperimentSalePage(suggestedExperiment) }
        >
          <div style={(suggestedExperiment.id === experimentItemIdOnScroll) || (this.state.shownExperiment === suggestedExperiment.id) ? {zIndex: '7'} : {zIndex: '4'}}>

              {(!!isShowDoubledLabel && suggestedExperiment.id !== experimentItemIdOnScroll) && <div></div> }

              {!isShowDoubledLabel &&
                <div style={{color: isValuePositive ? 'black' : 'grey', zIndex: ((experimentItemIdOnScroll === suggestedExperiment.id) || (this.state.shownExperiment === suggestedExperiment.id)) ? 200 : 9}}>
                  {`№ ${suggestedExperiment.rank}`}
                  {/* {`№ ${suggestedExperiment.rank}`} {isValueGreat ? <img src={likeFilled} height={10} width={10} alt=''/> : <span/>} */}
                  {/* { ((experimentItemIdOnScroll === suggestedExperiment.id) || (this.state.shownExperiment === suggestedExperiment.id)) &&
                    <div className='map-label'>
                      {listing.total_rooms}{'-комн.'}{' '}{Math.round(listing.square_meters)}{'м²'}{' '}{Math.round(listing.price_per_month/100)/10}{'к '}{listing.currency_code}
                    </div>} */}
                </div>}
              

              {(!!isShowDoubledLabel && suggestedExperiment.id === experimentItemIdOnScroll) &&
                <div>
                  <div style={isValuePositive ? {color: 'black'} : {color: 'grey'}}>
                    {`№ ${suggestedExperiment.rank}`}
                    {/* {`№ ${suggestedExperiment.rank}`} {isValueGreat ? <img src={likeFilled} height={10} width={10} alt=''/> : <span/>} */}
                    { ((experimentItemIdOnScroll === suggestedExperiment.id) || (this.state.shownExperiment === suggestedExperiment.id)) &&
                      <div className='map-label'>
                        Оценка <span className='wayleap-logo'>Wayleap</span><span style={{color: value > 0 ? 'green' : 'red'}}> {' '}{Math.round(value)}</span>
                      </div>
                    }
                    <div style={isDoubledExperimentValuePositive ? {color: 'black'} : {color: 'grey'}}>
                      {`№ ${doubledExperiment.rank}`}
                      {/* {`№ ${doubledExperiment.rank}`} {isDoubledExperimentValueGreat ? <img src={likeFilled} height={10} width={10} alt=''/> : <span/>} */}
                      <div className='map-label'>
                        Оценка <span className='wayleap-logo'>Wayleap</span> <span style={{color: value > 0 ? 'green' : 'red'}}> {' '}{Math.round(doubledExperimentValue)}</span>
                      </div>
                    </div>
                  </div>

                </div>
              }
          </div>

        </MarkerWithLabel>
        }
      )
      const singleMarker = [this.props.placeSingle].map((item, key) => {
        if ( item ) {
          const { latitude, longitude } = item

          return  <MarkerWithLabel
                    key={key}
                    position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                    labelAnchor={new google.maps.Point(30, 0)}
                    icon={highlightedListingIconSettings}
                    labelStyle={singleLabelStyle}
                    labelInBackground={false}
                  >
                    <div style={{zIndex: 7}}>{item.street ? `${item.street} ${item.house}` : (item.street_address || item.full_address || '')
}</div>
                  </MarkerWithLabel>
        }
        return null
      })

    return  <MapWithAMarker
              zoom={dynamicZoom || parseInt(experimentSearch.map_zoom, 10)}
              mapTypeId={this.props.mapTypeId || "roadmap"}
              lat={dynamicLat}
              lng={dynamicLng}
              markersResults={markersResults}
              markersWithLabels={markersWithLabels}
              singleMarker={singleMarker}
              isSingleMarker={isSingleMarker || centeredPlaceId}
              amenityMarkers={amenityMarkers}
              googleMapURL={config.googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className='mapBlock' />}
              mapElement={<div style={{ height: `100%` }} />}
              onClick={() => this.hideExperimentSalePage()}
            />
  }
}

const MapWithAMarker = withGoogleMap(props =>
  <GoogleMap
    zoom={props.zoom}
    mapTypeId={props.mapTypeId || "roadmap"}
    center={{ lat: props.lat, lng: props.lng}}
    onClick={props.onClick}
  >
    {props.isSingleMarker && props.amenityMarkers}
    {props.markersResults}
    {props.markersWithLabels}
    {props.singleMarker}
  </GoogleMap>
)

const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
  suggestedExperiments: state.suggestedExperiments,
  placeSearches: state.placeSearches,
  places: state.places,
  listings: state.listings,
  amenityTrips: state.amenityTrips,
  amenityRoutes: state.amenityRoutes,
  amenityLocations: state.amenityLocations,
  amenities: state.amenities,

})

export default connect(mapStateToProps)(ExperimentMap)
