import React, {Component} from 'react'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
// import { I18n } from "react-redux-i18n"

export default function ExpansionPanelHOC(InputComponent, title) {
  const styles = theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: '17px',
      fontWeight: '400'
    },
    MuiExpansionPanelDetails: {
      display: 'block'

    }
  })
  // const titles = {
  //   'Ближайшая инфраструктура': I18n.t('experimentItem.nearbyAmenities')
  // }
  class Panel extends Component {
    render() {
      const { classes, expanded } = this.props
      // add thanslations to this HOC component: title ==> newTitle
      // const newTitle = titles[title] ? titles[title] : title

      return (
        <ExpansionPanel expanded={expanded} className={classes.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{title}{/*Compare With Next Best Alternative*/}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.MuiExpansionPanelDetails}>
            <InputComponent {...this.props}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    }
  }
  return withStyles(styles)(Panel)
}
