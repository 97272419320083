import React, {Component} from 'react'
import Button from '../../components/Button'
import { Translate } from 'react-redux-i18n'
import DeleteIcon from '@material-ui/icons/Delete'

export default class ExcludedExperiment extends Component {

  removeFromExcludedArrayInExperimentSearch = () => {
    const arr = this.props.experimentSearch.excluded_listing_ids
    const { experiment, setExperimentSearch, updateExperimentSearch, experimentSearch }= this.props
    const newArr = arr.filter(item => item !== `${experiment.listing_id}`)
    const newExperimentSearch = {...experimentSearch, excluded_listing_ids: newArr}
    setExperimentSearch(newExperimentSearch)
    updateExperimentSearch(newExperimentSearch)
  }

  render() {
    return(
      <div className='middle-block_row-wrap_list middle-block_row-wrap' style={{padding: 10}} id={this.props.dataId}>
        <Translate value='experimentItem.itemWasDeleted'/>
          <div style={{paddingTop: 10}}>
            <Button
              className='item-button'
              onClickFunction={()=> this.removeFromExcludedArrayInExperimentSearch()}
              icon={<DeleteIcon fontSize="small"/>}
              label={<Translate value='experimentItem.recover'/>}>
            </Button>
          </div>
      </div>
    )
  }
}
