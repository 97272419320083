import React, { Component } from 'react'
import { updateAmenityTrip } from '../../actions/amenityTrips'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { Translate } from 'react-redux-i18n'
import Select from '../../components/Select'

const round_trips_options = [0,1,2,3,4,5,6,7]

class AmenityTrip extends Component {
  prepareForUpdate = (value, type) => {
    const { index } = this.props
    const amenityTrip = cloneDeep(this.props.amenityTrip)

    switch(type) {
      case 'members_per_trip':
        amenityTrip.members_per_trip = parseInt(value, 10)
        break
      case 'round_trips_per_week':
        amenityTrip.round_trips_per_week = parseInt(value, 10)
        amenityTrip.round_trips_per_year = parseInt(value, 10) * 52
        break
      case 'round_trips_per_year':
        amenityTrip.round_trips_per_year = parseInt(value, 10)
        amenityTrip.round_trips_per_week = Math.round(parseInt(value, 10) / 52, 10)
        break
      default:
        amenityTrip[type] = parseInt(value, 10)
    }

    this.props.updateAmenityTrip(amenityTrip, index)
  }

  showRoundTripsOptions = () => {
    const { amenityTrip, amenities } = this.props
    // const round_trips_per_week = parseInt(amenityTrip.round_trips_per_week, 10)
    const round_trips_per_week = Math.round(parseInt(amenityTrip.round_trips_per_year, 10)/52.0)

    return (
<div>     <div style={{}}>   {amenities.find(amenity => amenity.id === amenityTrip.amenity_id).name} </div>
<div className='pagination-row'>

        {
          round_trips_options.map(option =>
            <div
              className={`${round_trips_per_week === option ? 'active ' : ''}pagination-row__item`}
              style={{}}
              onClick={() => this.prepareForUpdate(option, 'round_trips_per_week')}
              key={option}
            >
              { option }
            </div>
          )
        }
        {/* <a
          className='pagination-row__item'
          key={'roundTripsPerWeekOption'}
        >
        </a> */}
        {/* <Translate value='exactPlaceSearch.roundTripsPerWeek' /> */}
      </div></div>
    )
  }

  showFieldForRoundTripsPerYear = () => {
    const { amenityTrip } = this.props
    const round_trips_per_year = parseInt(amenityTrip.round_trips_per_year, 10)

   return (<div className='price-row-second'>
                <span className='price-text'>
                 или в год: 
                </span>
                <Select
                 onChange = {(e) => {
                   this.prepareForUpdate(e, 'round_trips_per_year')}}
                 value={round_trips_per_year || ''}
                 label={round_trips_per_year ? `${round_trips_per_year}` : ''}
                 key={'round_trips_per_year'}
                 values={[
                  {value: 0, label:'0'},
                  {value: 1, label:'1'},
                  {value: 2, label:'2'},
                  {value: 6, label:'6'},
                  {value: 12, label:'12'},
                  {value: 26, label:'26'},
                  {value: 52, label:'52'},
                  {value: 104, label:'104'},
                  {value: 156, label:'156'},
                  {value: 208, label:'208'},
                  {value: 260, label:'260'},
                  {value: 312, label:'312'},
                  {value: 364, label:'364'}
                  ]}/>
               </div>)
  }

  showMembersPerTripOptions = () => {
    const { members_per_trip } = this.props.amenityTrip
    const [experimentSearch] = this.props.experimentSearches
    const maxNumberOfPeoplePerTrip = experimentSearch.number_of_people_per_household || 5
    const membersPerTripOptions = [...Array(maxNumberOfPeoplePerTrip).keys()]
    const isMultipleOptions = membersPerTripOptions.length > 1 && (experimentSearch.is_household_with_children_under_school_age || experimentSearch.is_household_with_children_in_school || experimentSearch.is_household_a_couple || experimentSearch.is_household_a_group)

    return (isMultipleOptions ? <div className='members-per-trip_row pagination-row'>
        {
          [...Array(maxNumberOfPeoplePerTrip).keys()].map(option => {
            const membersPerTripOption = option + 1
            return <div
              className={`${members_per_trip === membersPerTripOption || experimentSearch.number_of_people_per_household === 1 ? 'active ' : ''}pagination-row__item`}
              onClick={() => this.prepareForUpdate(membersPerTripOption, 'members_per_trip')}
              key={membersPerTripOption}
            >
              { membersPerTripOption }
            </div>
          })
        }
        {/*<a
          className='pagination-row__item'
          key={'membersPerTripOption'}
        >
        </a>*/}
        <Translate value='exactPlaceSearch.membersPerTrip' />
      </div> : <span/>
    )
  }

  render() {
    return (
      <div className="exact-place-search trip-member-block">
        { this.props.isExtendedContextAndPreferences && this.showRoundTripsOptions() }
        {/* { this.props.isExtendedContextAndPreferences && this.showFieldForRoundTripsPerYear() } */}
        { this.props.isExtendedContextAndPreferences && this.showMembersPerTripOptions() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  amenities: state.amenities,
  experimentSearches: state.experimentSearches,
})

export default connect(mapStateToProps, { updateAmenityTrip })(AmenityTrip)
