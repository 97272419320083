import React, {Component} from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import SettingsIcon from '@material-ui/icons/Settings'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'

import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class FineTunePreferences extends Component {
  render() {

  return <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
    <div className='call-buttons' style={{fontSize: '14px'}}>
      <div className='execution-stats'>
        <div>
          {/*<Translate value='experimentList.onlyWorthwhile' />*/}
          Для улучшения качества результатов используйте кнопки <span className=''>
            <span className='item-button_second'>
              <Button style={{padding: "0px 0px", minHeight: "0px"}} variant="text" color="primary" className='item-button_second'>
                <DeleteIcon />
              </Button>
            </span>
            <span className='item-button_second'>
              <Button style={{padding: "0px 0px", minHeight: "0px"}} variant="text" color="primary" className='item-button_second'>
                <FavoriteBorderIcon />
              </Button>
            </span>
          </span>, попробуйте точную настройку предпочтений либо получите помощь непредвзятого специалиста.
        </div>
      </div>
    </div>

    <div className='call-buttons'>
      <div className=''>
        <Button
          variant="text" color="default" className=''
        >
          <SettingsIcon /> Настройка >
        </Button>
      </div>
      <div className=''>
        <Button
          variant="text" color="primary" className=''>
          <PhoneInTalkIcon /> Позвонить >
        </Button>
      </div>
    </div>
  </div>
  }
}
const mapStateToProps = state => ({})
export default howerObserver(togleShowAllMap(withRouter(connect(mapStateToProps, {})(FineTunePreferences))))
