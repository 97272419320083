import React, {Component} from 'react'

export default class ProgressBar extends Component {
    state = {
        secondsToFinish: this.props.experimentSearch.execution_time/1000 || 35,
        secondsCounter: 0
     }
 
    componentDidMount() {
        this.countUp()
        setTimeout(this.setState({secondsCounter: this.state.secondsCounter + 0.1}), 100)
    }
 
    countUp = () => {
        this.setState((prevState) => { return{secondsCounter: prevState.secondsCounter + 0.1 }})
    }

    componentDidUpdate() {
        if(this.state.secondsToFinish > this.state.secondsCounter){
            setTimeout(this.countUp, 100)
        }
    }

    render() {
        const { secondsCounter } = this.state
        const wholeSeconds = Math.floor(secondsCounter)

        // здесь просто должен быть bragging list / список преимуществ
        // либо rasparwennye chunk'y iz experiment.preferences

        

        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        

        const array = [
            'анализ предпочтений',
            'анализ цен',
            'анализ интерьера',
            'наличие бытовой техники',
            'качество бытовой техники',
            'наличие мебели',
            'качество мебели',
            'наличие сантехники',
            'качество сантехники',
            'анализ комфорта',
            'свет',
            'температура',
            'тишина',
            'просторность',
            'чистота',
            'крепкоустойчивость',
            'анализ местоположения',
            'близость к метро',
            'близость к супермаркету',
            'близость к крупному тц',
            'близость к зелени',
            'близость к парковке',
            'выбор оптимального вида транспорта',
            'престижность',
            'безопасность',
            'условия для работы',
            'условия для фитнеса',
            'условия для питания',
            'условия для стирки',
            'условия для хранения вещей',
            'условия для развлечений',
            'условия для отдыха',
            'условия для семьи',
            'условия для приема гостей',
            'проверка собственников и агентов',
            'проверка актуальности',
            'проверка точности данных',
            'подведение итога',
        ]
        
        const processDict = {}
        array.map((value, index) => processDict[index.toString()] = value)

        // {
        //     '0': '',
        //     '1': 'a',
        //     '2': '',
        //     '3': '',
        //     '4': '',
        //     '5': '',
        //     '6': 'b',
        //     '7': '',
        //     '8': '',
        //     '9': '',
        //     '10': '',
        //     '11': '',
        //     '12': 'c',
        //     '13': '',
        //     '14': '',
        //     '15': '',
        //     '16': '',
        //     '17': '',
        //     '18': '',
        //     '19': '',
        //     '20': '',
        //     '21': '',
        //     '22': '',
        //     '23': '',
        //     '24': '',
        //     '25': '',
        //     '26': '',
        //     '27': '',
        //     '28': '',
        //     '29': '',
        //     '30': '',
        //     '31': '',
        //     '32': '',
        //     '33': '',
        //     '34': '',
        //     '35': '',
        // }

        return(<span style={{'width': '100%'}}>
            <div>{processDict[wholeSeconds.toString()] ? processDict[wholeSeconds.toString()] : 'подготовка результатов'}</div>
            <progress value={this.state.secondsCounter} max={this.state.secondsToFinish}></progress>
        </span>
    )}
}
