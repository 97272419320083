import {
  FETCH_EXPERIMENT
} from '../../actions/experimentSearches'

const initialState = [{}]

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXPERIMENT:
      return (action.payload)
    default:
      return state
  }
}
