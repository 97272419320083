import React, { PureComponent } from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import Select from '../../components/Select'
import * as actions from '../../actions/experimentSearches'
import { bedroomsMin, bedroomsMax, squareMetersMin, squareMetersMax } from '../../fixtures'
import config from '../../config'
import DialogSelect from '../../components/DialogSelect'

class MinAndMaxBedroomsAndMetersSelect extends PureComponent {
  constructor(props) {
      super(props)
      const category_from_url = window.location.pathname.substring(1)
      const category_bias = `For ${category_from_url[0].charAt(0).toUpperCase() + category_from_url.slice(1)}`
  
      this.state = {
       category_bias: category_bias,
       bedrooms_min: this.props.experimentSearch.bedrooms_min || '',
       bedrooms_max: this.props.experimentSearch.bedrooms_max || '',
       square_meters_min: this.props.experimentSearch.square_meters_min || '',
       square_meters_max: this.props.experimentSearch.square_meters_max || '',
       year_built_min: this.props.experimentSearch.year_built_min || '',
       year_built_max: this.props.experimentSearch.year_built_max || '',
       floor_min: this.props.experimentSearch.floor_min || '',
       floor_max: this.props.experimentSearch.floor_max || '',
       floors_min: this.props.experimentSearch.floors_min || '',
       floors_max: this.props.experimentSearch.floors_max || '',
       kitchen_area_square_meters_min: this.props.experimentSearch.kitchen_area_square_meters_min || '',
       kitchen_area_square_meters_max: this.props.experimentSearch.kitchen_area_square_meters_max || '',
      }
  }

  componentDidUpdate(prevProps) {
    if (this.props.experimentSearch !== prevProps.experimentSearch) {
      const { bedrooms_min, bedrooms_max, square_meters_min, square_meters_max } = this.props.experimentSearch
      this.setState({ bedrooms_min, bedrooms_max, square_meters_min, square_meters_max })
    }
  }

  getTitle = (min, max) => {
    if (max && min) {
      return `комнат ${min}-${max}`
    } else if (max) {
      return `комнат до ${max}`
    } else if (min) {
      return `комнат ${min}+`
    } else {
      return 'комнат'
    }
  }

  render(){
    const [ experimentSearch ] = this.props.experimentSearches
    const { setExperimentSearch, isExtendedContextAndPreferences } = this.props
    const { category_bias, bedrooms_max, bedrooms_min } = this.state
    const title = this.getTitle(bedrooms_min, bedrooms_max)
    
    return (
      <DialogSelect title={title}>

      <div className='infrastructure-info pagination-row'>

        <div className='price-select'>
          <div className='price-row'>
            <span className='price-text'>
              {I18n.t('experimentSearch.rooms')} {isExtendedContextAndPreferences && `oт`}
            </span>
            <Select
              onChange = {(e) => {
                this.setState({bedrooms_min: e})
                setExperimentSearch({
                  ...experimentSearch,
                  category_bias,
                  bedrooms_min: e })}}
              value={this.state.bedrooms_min || ''}
              label={this.state.bedrooms_min ? `${this.state.bedrooms_min} +` : ''}
              key={'bedrooms_min'}
              values={bedroomsMin}/>
           </div>
           {isExtendedContextAndPreferences && <div className='price-row-second'>
             <span className='price-text'>
              до
             </span>
             <Select
              onChange = {(e) => {
                this.setState({bedrooms_max: e})
                setExperimentSearch({
                  ...experimentSearch,
                  category_bias,
                  bedrooms_max: e  })}}
              value={this.state.bedrooms_max || ''}
              label={this.state.bedrooms_max ? `${this.state.bedrooms_max}` : ''}
              key={'bedrooms_max'}
              values={bedroomsMax}/>
            </div>}
          </div>

          {true && <div className='price-select'>
            <div className='price-row-second'>
              <span className='price-text'>
                {I18n.t('experimentSearch.sqwMet')} {isExtendedContextAndPreferences && `oт`}
              </span>
              <Select
                onChange = {(e) => {
                  this.setState({square_meters_min: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    square_meters_min: e })}}
                value={this.state.square_meters_min || ''}
                label={this.state.square_meters_min ? `${this.state.square_meters_min} +` : ''}
                key={'square_meters_min'}
                values={squareMetersMin}/>
            </div>
            {isExtendedContextAndPreferences && <div className='price-row-second'>
              <span className='price-text'>
                до            
              </span>
              <Select
                onChange = {(e) => {
                this.setState({square_meters_max: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    square_meters_max: e })}}
                value={this.state.square_meters_max || ''}
                label={this.state.square_meters_max ? `${this.state.square_meters_max}` : ''}
                key={'square_meters_max'}
                values={squareMetersMax}/>
            </div>}
          </div>}



          {true && <div className='price-select'>
            <div className='price-row-second'>
              <span className='price-text'>
                год постройки {isExtendedContextAndPreferences && `oт`}
              </span>
              <Select
                onChange = {(e) => {
                  this.setState({year_built_min: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    year_built_min: e })}}
                value={this.state.year_built_min || ''}
                label={this.state.year_built_min ? `${this.state.year_built_min} +` : ''}
                key={'year_built_min'}
                // values={squareMetersMin}
                />
            </div>
            {isExtendedContextAndPreferences && <div className='price-row-second'>
              <span className='price-text'>
                до            
              </span>
              <Select
                onChange = {(e) => {
                this.setState({year_built_max: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    year_built_max: e })}}
                value={this.state.year_built_max || ''}
                label={this.state.year_built_max ? `${this.state.year_built_max}` : ''}
                key={'year_built_max'}
                // values={squareMetersMax}
                />
            </div>}
          </div>}



          {true && <div className='price-select'>
            <div className='price-row-second'>
              <span className='price-text'>
                этаж {isExtendedContextAndPreferences && `oт`}
              </span>
              <Select
                onChange = {(e) => {
                  this.setState({floor_min: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    floor_min: e })}}
                value={this.state.floor_min || ''}
                label={this.state.floor_min ? `${this.state.floor_min} +` : ''}
                key={'floor_min'}
                // values={squareMetersMin}
                />
            </div>
            {isExtendedContextAndPreferences && <div className='price-row-second'>
              <span className='price-text'>
                до            
              </span>
              <Select
                onChange = {(e) => {
                this.setState({floor_max: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    floor_max: e })}}
                value={this.state.floor_max || ''}
                label={this.state.floor_max ? `${this.state.floor_max}` : ''}
                key={'floor_max'}
                // values={squareMetersMax}
                />
            </div>}
          </div>}




          {true && <div className='price-select'>
            <div className='price-row-second'>
              <span className='price-text'>
                этажность {isExtendedContextAndPreferences && `oт`}
              </span>
              <Select
                onChange = {(e) => {
                  this.setState({floors_min: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    floors_min: e })}}
                value={this.state.floors_min || ''}
                label={this.state.floors_min ? `${this.state.floors_min} +` : ''}
                key={'floors_min'}
                // values={squareMetersMin}
                />
            </div>
            {isExtendedContextAndPreferences && <div className='price-row-second'>
              <span className='price-text'>
                до            
              </span>
              <Select
                onChange = {(e) => {
                this.setState({floors_max: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    floors_max: e })}}
                value={this.state.floors_max || ''}
                label={this.state.floors_max ? `${this.state.floors_max}` : ''}
                key={'floors_max'}
                // values={squareMetersMax}
                />
            </div>}
          </div>}




          {true && <div className='price-select'>
            <div className='price-row-second'>
              <span className='price-text'>
                кухня м² {isExtendedContextAndPreferences && `oт`}
              </span>
              <Select
                onChange = {(e) => {
                  this.setState({kitchen_area_square_meters_min: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    kitchen_area_square_meters_min: e })}}
                value={this.state.kitchen_area_square_meters_min || ''}
                label={this.state.kitchen_area_square_meters_min ? `${this.state.kitchen_area_square_meters_min} +` : ''}
                key={'kitchen_area_square_meters_min'}
                // values={squareMetersMin}
                />
            </div>
            {isExtendedContextAndPreferences && <div className='price-row-second'>
              <span className='price-text'>
                до            
              </span>
              <Select
                onChange = {(e) => {
                this.setState({kitchen_area_square_meters_max: e})
                  setExperimentSearch({
                    ...experimentSearch,
                    kitchen_area_square_meters_max: e })}}
                value={this.state.kitchen_area_square_meters_max || ''}
                label={this.state.kitchen_area_square_meters_max ? `${this.state.kitchen_area_square_meters_max}` : ''}
                key={'kitchen_area_square_meters_max'}
                // values={squareMetersMax}
                />
            </div>}
          </div>}





        </div>
        </DialogSelect>
    )
  }
}
const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
})
export default connect(mapStateToProps, { ...actions })(MinAndMaxBedroomsAndMetersSelect)
