import { SET_LOADING } from '../../actions/experimentSearches'

const initialState = true

export default (state = initialState, action) => {
  if (action.type === SET_LOADING) {
    return action.payload
  } else {
    return state
  }
}
