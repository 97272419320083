import React, {Component} from 'react'
import mRed from '../../assets/metro_red.svg'
import mGreen from '../../assets/metro_green.svg'
import mBlue from '../../assets/metro_blue.svg'

const redMetroStations = ['Академгородок','Житомирская','Святошин','Нивки','Берестейская','Шулявская','Политехнический институт','Вокзальная',
  'Университет','Театральная','Крещатик','Арсенальная','Днепр','Гидропарк','Левобережная','Дарница','Черниговская','Лесная', 'Політехнічний інститут ', 'Арсенальна', 'Шулявська',
  'Вокзальна','Дніпро','Шулявська','Дарниця','Чернігівська','Святошин','Лісова','Хрещатик','Університет','Академмістечко', 'Політехнічний інститут', 'Житомирська']
const greenMetroStations = ['Синеозёрная','Виноградарь','Мостицкая','Сырец','Дорогожичи','Герцена','Лукьяновская','Львовская брама','Золотые ворота',
  'Дворец спорта','Кловская','Печерская','Дружбы народов','Выдубичи','Теличка','Южный мост','Славутич', 'Осокорки','Позняки','Харьковская',
  'Вырлица','Бориспольская','Красный хутор', 'Золоті ворота','Кловська','Золоті ворота','Видубичі','Осокорки', 'Харківська',
  'Лук\'янівська', 'Лук’янівська','Дорогожичі','Сирець','Бориспільська','Червоний хутір', 'Дружби народів', 'Палац Спорту']
const blueMetroStations = ['Героев Днепра','Минская','Оболонь','Почайна','Тараса Шевченко','Контрактовая площадь ', 'Почтовая площадь',
  'Майдан Незалежности','Площадь Льва Толстого','Олимпийская','Дворец «Украина»', 'Дворец Украина','Лыбедская','Деміївська','Голосеевская','Васільковская', 'Васильковская',
  'Выставочный центр','Ипподром','Теремки','Одесская', 'Контрактова площа', 'Либідська','Майдан Незалежності','Оболонь','Олімпійська',
  'Героїв Дніпра','Либідська','Васильківська','Виставковий центр','Іподром','Теремки', 'Тараса Шевченка', 'Мінська']

export default class SubwayLineIcon extends Component {
    renderMetroIcon = (metroStation) => {
        if (redMetroStations.includes(metroStation)) {
          return <img src={mRed} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
        }
        if (greenMetroStations.includes(metroStation)) {
          return <img src={mGreen} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
        }
        if (blueMetroStations.includes(metroStation)) {
          return <img src={mBlue} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
        }
      }

      render() {
      return <span>{this.renderMetroIcon(this.props.name)}</span>;
    }
  }
