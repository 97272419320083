/* global google */
import React, {Component} from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import searchIcon from '../../assets/searchIcon.svg'
import { I18n } from 'react-redux-i18n'

export default class ShowPlacesAutocomplete extends Component {
  render() {
    const {value, handleSelect, prepareForUpdate, experimentSearch } = this.props

    return (
      <PlacesAutocomplete
        value={value}
        onChange={address => prepareForUpdate(address, 'address')}
        onSelect={handleSelect}
        autocomplete="new-password"
        highlightFirstSuggestion={true}
        searchOptions={{
          location: new google.maps.LatLng(experimentSearch.weber_point_latitude,
            experimentSearch.weber_point_longitude),
          sessionToken: new google.maps.places.AutocompleteSessionToken(),
          radius: 50000,
          // types: ['address'], 07.12.2020 MODIFIED
          language: 'ru',
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className={`${this.props.isModal ? 'modal' : ''}addressInput_block`}>
            <div className='pagination-row' >
              <input {...getInputProps({
                placeholder: I18n.t('exactPlaceSearch.address'),
                className: `${this.props.isModal ? 'modal' : ''}input exact-place-search-input adressInput`,
              })}
              />
              <img src={searchIcon} alt='search-icon' className='searchIcon' />
            </div>
            <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                const style = suggestion.active ? {
                  backgroundColor: '#eeeeee',
                  cursor: 'pointer', fontSize: '19px',
                  zIndex: 3, position: 'relative'
                } : {
                  backgroundColor: '#ffffff',
                  cursor: 'pointer', fontSize: '19px',
                  zIndex: 3, position: 'relative'
                }
                return (
                  <div {...getSuggestionItemProps(suggestion, { className, style })}>
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
           </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}
