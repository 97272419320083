import React, {Component} from 'react'
import config from '../../config'

export default class StatsBreakdownItem extends Component {

//   ehours = (name) => {
//     const { experiment } = this.props
//     return Math.round(experiment[name] / 3600)
//   }

//   echours = (xname) => {
//     const { experiment } = this.props
//     return Math.round(experiment.costs.data.find(({name}) => name === xname).costs_in_seconds_per_year / 3600)
//   }

//   ecvalue = (xname) => {
//     const { experiment } = this.props
//     return Math.round(experiment.costs.data.find(({name}) => name === xname).costs_in_seconds_per_year)
//   }

//   hours = (number) => {
//     return Math.round(number / 3600)
//   }
//   value = (number) => {
//     return Math.round(number)
//   }

  format = (n) => {
    return (n > 0) ? "+" + n : n
  }

  getSuperlativeWord = (stds) => {
    if (stds > 0) {
        return 'top x' + stds
    }
    if (stds < 0) {
        return 'shit x' + stds
    }
    return 0
  }

  stdCount = (diff, std) => {
    if (diff/std > 0) {
        return Math.floor(diff/std)
    }
    if (diff/std < 0) {
        return Math.ceil(diff/std)
    }
    return 0
  }

  render() {
    const { title, current, all, chosen, multiplier, std, spread, top, shit, showAdvantage, showDisadvantage, stats_debug} = this.props
    const times = multiplier || 1
    const format = this.format
    const getSuperlativeWord = this.getSuperlativeWord
    const stdCount = this.stdCount(times * (chosen - current), std)

    return(
        <span>
            {stats_debug && <span className={stats_debug ? "stats-breakdown-item" : ''}><span>
                {title}
                <br/>{current} (<span style={{color: times*current < times*chosen ? 'green' : 'red'}}>{format(times*chosen - times*current)}</span>)
                <br/>all {all}
                <br/>chosen {chosen}
                <br/>{std ? `std ${std}` : ''}
                <br/>{spread ? `spread ${spread}` : ''}
                <br/>(<span style={{color: times*chosen < times*all ? 'green' : 'red'}}>{format(times*all - times*chosen)}</span>)
                <br/>
            </span></span>}

            {showAdvantage && !stats_debug && (stdCount > 0 ) && <li className="present-amenity">
                <i className="fa fa-check-circle"></i>&nbsp;{Math.abs(stdCount) > 1 ? (Math.abs(stdCount) > 2 ? 'супер ' : `очень `) : ''}{top}
            </li>}

            {showDisadvantage && !stats_debug && (stdCount < 0 ) && <li className="absent-amenity" >
                <i className="fa fa-check-circle"></i>&nbsp;{Math.abs(stdCount) > 1 ? (Math.abs(stdCount) > 2 ? 'супер ' : `очень `) : ''}{shit}
            </li>}
        </span>
    )
  }
}
