import React, {Component} from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import ExactPlaceSearch from '../../containers/ExactPlaceSearch'
import config from '../../config'
import DialogSelect from '../../components/DialogSelect'

class ShowExactPlaceSearch extends Component {
  showOptionsForExtraPlaceSearches = (experimentSearch, placeSearchesPresent=false, createPlaceSearch) =>
    <div>
      <div className="pagination-row">добавить еще места</div>
      <span className="pagination-row">
        {[  I18n.t('exactPlaceSearch.work'),
            experimentSearch.is_household_with_student ? I18n.t('exactPlaceSearch.uni') : false,
            experimentSearch.is_household_with_children_under_school_age ? I18n.t('exactPlaceSearch.kindergarten') : false,
            experimentSearch.is_household_with_children_in_school ? I18n.t('exactPlaceSearch.school') : false,
            I18n.t('exactPlaceSearch.family'),
            I18n.t('exactPlaceSearch.friends'),
            I18n.t('exactPlaceSearch.other')
          ].filter(Boolean).map((option, i) =>
            <div
              className='pagination-row__item'
              onClick={() => createPlaceSearch({name: option, experiment_search_id: experimentSearch.id, is_activated: true})}
              key={i}
            >
              { option }
            </div>
          )
        }
      </span>
    </div>

getTitle = (places) => {
  
  if (places) {
    return 'возле ' + places.map(place => this.getShortReadableAddress(place)).join(', ')
  } else {
    return 'место'
  }
}

getShortReadableAddress = (place) => {
  if (place.street) {
    return `${place.street} ${place.house}`
  } else {
    return place.full_address && place.full_address.split(' ').slice(0,2).map(word => word.substring(0,8).replace(/,\s*$/, "")).join(' ')
  }
}

render() {
    const {experimentSearch, createPlaceSearch, isExtendedContextAndPreferences} = this.props
    const places = this.props.placeSearches.filter(ps => ps.is_activated).filter(ps => ps.round_trips_per_year > 0)
      .sort((a, b) => b.round_trips_per_year - a.round_trips_per_year)
      .map((placeSearch) => this.props.places.find((place) => place.id === placeSearch.place_id))

    const title = this.getTitle(places)

    // console.log(title)
    return experimentSearch.id ? <DialogSelect title={title}><div className='where-to-go-info'>
    <div className='pagination-row'>
      <div>возле каких мест</div>
      </div>
      {/* <Translate value='experimentSearch.exactPlaceSearchesExplanation' /> */}
      {/* .filter(ps => ps.is_activated)   07.12.2020 MODIFIED */}
      {config.IS_ONLINE && this.props.placeSearches.sort((a, b) => a.id - b.id).map((placeSearch, i) =>
         <ExactPlaceSearch
          key={i}
          index={i}
          placeSearch={placeSearch}
          experimentSearch={experimentSearch}
          // placeSearches={this.props.placeSearches}
          // createPlaceSearch={createPlaceSearch}
          isExtendedContextAndPreferences={isExtendedContextAndPreferences}
        />
      )}
      <div>
        {this.showOptionsForExtraPlaceSearches(experimentSearch, this.props.placeSearches.filter(ps => ps.is_activated).length > 0, createPlaceSearch) }
      </div>
    </div></DialogSelect> : <div></div>
  }
}
const mapStateToProps = state => ({
  places: state.places,
  placeSearches: state.placeSearches,
})

export default connect(mapStateToProps)(ShowExactPlaceSearch)
