import axios from 'axios'
import _ from 'lodash'
import config from '../config'

export const UPDATE_PLACE_SEARCH = 'UPDATE_PLACE_SEARCH'
export const UPDATE_PLACE_SEARCH_FIELDS = 'UPDATE_PLACE_SEARCH_FIELDS'
export const CREATE_PLACE_SEARCH = 'CREATE_PLACE_SEARCH'
export const ADD_PLACES = 'ADD_PLACES'
const HOST = config.HOST
const DEBOUNCE_MILLISECONDS = config.DEBOUNCE_MILLISECONDS

const updateBackend = (newPlaceSearch, dispatch) => {
  axios.put(`${HOST}api/place_searches/${newPlaceSearch.id}/`, newPlaceSearch)
  .then(response => {
    const [ placeSearch ] = response.data.data
    dispatch({
      type: UPDATE_PLACE_SEARCH_FIELDS,
      placeSearch: placeSearch,
      fields: {
        results_count: placeSearch.results_count,
        place: placeSearch.place,
        place_id: placeSearch.place_id,
        id: placeSearch.id
      },
    })
  })}

export const createPlaceSearch = (newPlaceSearch) => (dispatch) => {
  newPlaceSearch.round_trips_per_week = 5
  newPlaceSearch.members_per_trip = 1

  axios.post(`${HOST}api/place_searches/`, newPlaceSearch)
  .then(response => {
    dispatch({
      type: CREATE_PLACE_SEARCH,
      payload: response.data.data,
    })
  })
}

const debouncedUpdate = _.debounce((updatedPlaceSearch, dispatch) =>
  updateBackend(updatedPlaceSearch, dispatch), DEBOUNCE_MILLISECONDS)

export const updatePlaceSearch = (updatedPlaceSearch) => (dispatch) => {
  debouncedUpdate(updatedPlaceSearch, dispatch)
  dispatch({
    type: UPDATE_PLACE_SEARCH,
    placeSearch: updatedPlaceSearch,
  })
}

export const addPlaces = (places) => (dispatch) => {
  dispatch({
    type: ADD_PLACES,
    payload: places,
  })
}
