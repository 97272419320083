import React from 'react'
import PropTypes from 'prop-types';
import MDSpinner from "react-md-spinner";

Loader.propTypes = {
    isSimple: PropTypes.bool,
    isBlock: PropTypes.bool,
    isSmall: PropTypes.bool,
};

export default function Loader({isBlock = false, isSimple = false, isSmall = false}) {
    const color = 'rgb(66, 165, 245)';
    return isSimple ? (
        <MDSpinner size={40} singleColor={color} />
    ) : (
        <div>
            <MDSpinner size={isSmall ? 40 : 50} singleColor={color} />
        </div>
    );
}
