import React, {Component} from 'react'
// import { Translate } from 'react-redux-i18n'
// import Button from '@material-ui/core/Button'
// import Clear from 'material-ui/svg-icons/content/clear';
import {withRouter} from 'react-router-dom'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class SignIn extends Component {
  render() {
    // const {experimentSearch} = this.props
    return (
      <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
      <img src="https://dl4.joxi.net/drive/2018/12/19/0016/0103/1077351/51/b3bf66271e.jpg" height={'150px'} alt=''/>
{/*        <div>
        </div>
        <div className='call-buttons'>
          <div className=''>
            <Button style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="default" className=''>
            <Clear />
            </Button>
          </div>
          <div className=''>
            <Button
              onClick={() => this.props.history.push(`/renter-profile?c=${experimentSearch.id}`)}
              style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="primary" className=''>
              Create Renter Profile&nbsp;>
            </Button>
          </div>
        </div>*/}
      </div>
    )
  }
}

export default howerObserver(togleShowAllMap(withRouter(SignIn)))
