import React, {Component} from 'react'
import AmenityTrip from '../../containers/AmenityTrip'
import { Translate } from 'react-redux-i18n'
import DialogSelect from '../../components/DialogSelect'

export default class AmenityTrips extends Component {
  shouldWeGuessWhatIsBest = () => {
    const { experimentSearch, setExperimentSearch } = this.props
    const option1 = 'выбрать инфраструктуру(например метро)'
    const option2 = 'выбрать лучшую инфраструктуру на наше усмотрение'

    return <div className="pagination-row">
        { experimentSearch.what_amenities_best_it_to_be_guessed === false ? <button
          className={`${experimentSearch.what_amenities_best_it_to_be_guessed !== false ? 'active' : ''} pagination-row__item`}
          onClick={() => setExperimentSearch({
            ...experimentSearch,
            what_amenities_best_it_to_be_guessed: !experimentSearch.what_amenities_best_it_to_be_guessed,
          })}
        >
          {option2}
        </button> : null}
        <div
          className={`${experimentSearch.what_amenities_best_it_to_be_guessed === false ? 'active' : ''} pagination-row__item`}
          onClick={() => setExperimentSearch({
              ...experimentSearch,
              what_amenities_best_it_to_be_guessed: !experimentSearch.what_amenities_best_it_to_be_guessed,
            })}
        >
          {option1}
        </div>
    </div>
  }

  render() {
    const { updateAmenityTrip, amenityTrips, amenities, isExtendedContextAndPreferences, experimentSearch } = this.props
    const title = 'метро, школы, магазины'

    return( experimentSearch.id ? <DialogSelect title={title}>
       <div className='infrastructure-info'>
        {/* <div className='sub-title'>
          <h3><Translate value='experimentSearch.nearby' /></h3>
        </div> */}
        { Object.keys(amenities).length >= Object.keys(amenityTrips).length &&
          <div className='chosing-infrastructure_block'>
            {/* <div className='pagination-row'>
              {
                amenityTrips.map(amenityTrip => {
                  const amenity = amenities.find(amenity => amenity.id === amenityTrip.amenity_id)
                  return(
                    <div
                      className={`${amenityTrip.is_activated ? 'active ' : ''} pagination-row__item`}
                      // onClick={() => updateAmenityTrip({...amenityTrip, is_activated: !amenityTrip.is_activated, round_trips_per_week: null})}
                      key={amenity.id}
                    >
                      { amenity.name }
                    </div>
                  )}
                )
              }
            </div> */}
            посещений в неделю
            {isExtendedContextAndPreferences && amenityTrips.filter(amenityTrip => amenityTrip.is_activated).sort((a, b) => b.round_trips_per_year - a.round_trips_per_year).map((amenityTrip, index) =>
              <AmenityTrip
                key={amenityTrip.id}
                index={index}
                amenityTrip={amenityTrip}
                isExtendedContextAndPreferences={isExtendedContextAndPreferences}
              />
              )
            }
        </div>
      }
      {/* config.SHOW_NOT_IMPLEMENTED_FEATURES && this.shouldWeGuessWhatIsBest() */}
    </div></DialogSelect> : <div></div>
    )
  }
}



// import React, {Component} from 'react'
// import AmenityTrip from '../../containers/AmenityTrip'
// import { Translate } from 'react-redux-i18n'

// export default class AmenityTrips extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       subGroups: []
//     }
//   }
//   shouldWeGuessWhatIsBest = () => {
//     const { experimentSearch, setExperimentSearch } = this.props
//     const option1 = 'выбрать инфраструктуру(например метро)'
//     const option2 = 'выбрать лучшую инфраструктуру на наше усмотрение'

//     return <div className="pagination-row">
//         { experimentSearch.what_amenities_best_it_to_be_guessed === false ? <button
//           className={`${experimentSearch.what_amenities_best_it_to_be_guessed !== false ? 'active' : ''} pagination-row__item`}
//           onClick={() => setExperimentSearch({
//             ...experimentSearch,
//             what_amenities_best_it_to_be_guessed: !experimentSearch.what_amenities_best_it_to_be_guessed,
//           })}
//         >
//           {option2}
//         </button> : null}
//         <div
//           className={`${experimentSearch.what_amenities_best_it_to_be_guessed === false ? 'active' : ''} pagination-row__item`}
//           onClick={() => setExperimentSearch({
//               ...experimentSearch,
//               what_amenities_best_it_to_be_guessed: !experimentSearch.what_amenities_best_it_to_be_guessed,
//             })}
//         >
//           {option1}
//         </div>
//     </div>
//   }
//   formSubGroups () {
//     const { amenities, amenityTrips } = this.props
//     if (amenityTrips.length > 0 && amenities.length > 0) {
//       const actAmenities = amenityTrips.filter(amenityTrip => amenityTrip.is_activated).map(amenityTrip => amenities.find(amenity => amenity.id === amenityTrip.amenity_id))
//       const subGroupsNames = Array.from(amenities.reduce((acc,item) => {
//         return acc.add(item.subgroup_ru)
//       }, new Set()))
//       const actGroup = subGroupsNames.filter(subGroup => amenities.filter(item => item.subgroup_ru === subGroup).length === 1)
//       this.setState({ subGroups: subGroupsNames.map(item => {return {name: item, is_activated: actAmenities.some(amenity => item === amenity.subgroup_ru) || actGroup.includes(item)}})})
//     }
//   }

//   componentDidMount() {
//     this.formSubGroups()
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps.amenities !== this.props.amenities || prevProps.amenityTrips !== this.props.amenityTrips) {
//       this.formSubGroups()
//     }
//   }

//   render() {
//     const { updateAmenityTrip, amenityTrips, amenities, isExtendedContextAndPreferences } = this.props
//     const { subGroups } = this.state

//     return(
//        <div className='infrastructure-info'>
//         <div className='sub-title'>
//           <h3><Translate value='experimentSearch.nearby' /></h3>
//         </div>
//         { Object.keys(amenities).length > 0 &&
//           <div className='chosing-infrastructure_block'>
//             <div className='pagination-row'>
//             {/*add condition*/}
//               {subGroups.map(subgroup => {
//                 if (!subgroup.is_activated) { return(
//                   <div
//                     className='pagination-row__item'
//                     style={{backgroundColor: '#ebebf5'}}
//                     onClick={() => {
//                       const index = subGroups.findIndex(item => item === subgroup)
//                       this.setState({subGroups: [
//                         ...subGroups.slice(0, index),
//                         {is_activated: true, name: subgroup.name},
//                         ...subGroups.slice(index+1)]})
//                     }}
//                     key={subgroup.name}
//                   >
//                     { subgroup.name }
//                   </div>)
//                 } else { return(
//                   amenityTrips.filter(amenityTrip =>  amenities.find(amenity => amenity.id === amenityTrip.amenity_id).subgroup_ru === subgroup.name).map(amenityTrip => {
//                     const amenity = amenities.find(amenity => amenity.id === amenityTrip.amenity_id)
//                     return(
//                       <div
//                         className={`${amenityTrip.is_activated ? 'active ' : ''} pagination-row__item`}
//                         onClick={() => updateAmenityTrip({...amenityTrip, is_activated: !amenityTrip.is_activated, round_trips_per_week: null})}
//                         key={amenity.id}
//                       >
//                         { amenity.name }
//                       </div>
//                     )}))
//                 }
//               })}
//             </div>

//             {isExtendedContextAndPreferences && amenityTrips.filter(amenityTrip => amenityTrip.is_activated).map((amenityTrip, index) =>
//               <AmenityTrip
//                 key={amenityTrip.id}
//                 index={index}
//                 amenityTrip={amenityTrip}
//                 isExtendedContextAndPreferences={isExtendedContextAndPreferences}
//               />
//               )
//             }
//         </div>
//       }
//       {/* config.SHOW_NOT_IMPLEMENTED_FEATURES && this.shouldWeGuessWhatIsBest() */}
//     </div>
//     )
//   }
// }
