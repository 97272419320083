import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import queryString from 'query-string'

import Button from '@material-ui/core/Button'
// import Slider from "@material-ui/core/Slider"
import InputAutocomplete from '../../components/InputAutocomplete'
import DisabledInputAutocomplete from '../../components/DisabledInputAutocomplete'
// import SettingsIcon from '@material-ui/icons/Settings'
import ExperimentList from '../../containers/ExperimentList'
import ExperimentMap from '../../containers/ExperimentMap'
import CallProfessional from '../../components/CallProfessional'
import ExtendedContextAndPreferences from '../ExtendedContextAndPreferences'
import Email from '../../components/Email'
import ProgressBar from '../../components/ProgressBar'

import MinAndMaxPriceSelect from '../../containers/MinAndMaxPriceSelect'
import MinAndMaxBedroomsAndMetersSelect from '../../containers/MinAndMaxBedroomsAndMetersSelect'

import ShowExactPlaceSearch from '../../components/ShowExactPlaceSearch'
import AmenityTrips from '../../components/AmenityTrips'

// import ExtendedContextAndPreferences from '../../containers/ExtendedContextAndPreferences'
import UnableToContactServer from '../../components/UnableToContactServer'


import * as experimentSearchesActions from '../../actions/experimentSearches'
import * as placeSearchesActions from '../../actions/placeSearches'

// import logo from  '../../assets/logoo.png'
import config from '../../config'
import '../../App.css'

class MainPage extends Component {
  constructor(props) {
    super(props)

    const category_from_url = window.location.pathname.substring(1)
    const category_bias = `For ${category_from_url[0].charAt(0).toUpperCase() + category_from_url.slice(1)}`

    this.state = {
      width: window.innerWidth, height: window.innerHeight,
      showLeft: true,
      showRight: window.innerWidth > 1200,
      showSwitchButton: !(window.innerWidth > 1200),
      isPriceLowerThanMinValue: false,
      experimentItemId: null,
      experimentItemIdOnScroll: null,
      isExperimentListOnHover: false,
      isAgent: config.AGENT || window.location.hostname === "agent.imbadom.com" || window.location.hostname === "agent.wayleap.com",
      isLoaded: false,
      isLoading: false,
      category_bias,
      secondsCounter: 0,
      secondsToFinish: 0,
      display_extended_context_and_preferences: false,
      display_preferences_extension: ((window.location.hostname === "demo.imbadom.com") || (window.location.hostname === "demo.wayleap.com") || (window.location.hostname === "localhost")),
  }

  this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.toggleShowRight = this.toggleShowRight.bind(this)
    this.setIsPriceLowerThanMinValue = this.setIsPriceLowerThanMinValue.bind(this)
    this.setExperimentItemId = this.setExperimentItemId.bind(this)
    this.setExperimentItemIdOnScroll = this.setExperimentItemIdOnScroll.bind(this)
    this.toggle_display_extended_context_and_preferences = this.toggle_display_extended_context_and_preferences.bind(this)
    this.showAllMap = this.showAllMap.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    const contextId = parseInt(queryString.parse(this.props.location.search).c, 10)
    if (contextId) {
      this.setState({isLoading: true})
      if (!this.props.experimentSearch) {
        this.props.createExperimentSearch()
      }
    }
    this.setState({isLoaded: true}) //06.12.2020 MODIFIED
    this.setState({isLoading: false})

    this.props.setExperimentSearch({currency_bias: config.DEFAULT_CURRENCY, preferences: [
      { 'title': 'однокомнатная' },
      { 'title': 'до 15к' },
      { 'title': 'поближе к метро' },
      { 'title': 'поближе к центру города' },
      { 'title': 'поближе к Сильпо или Novus' },], ...this.props.experimentSearch })
  }

  doSearch = (experimentSearch, config) => {
      this.props.setLoading(true)
      this.setState({isLoading: true})
      if (!experimentSearch.id) {
        this.props.createExperimentSearch({...experimentSearch, category_bias: this.state.category_bias})
          .then(() => {
            this.props.setLoading(false)
            this.setState({isLoading: false})
          } 
            )
      } else {
        this.props.performExperimentSearch({...experimentSearch, category_bias: this.state.category_bias})
          .then(() => {
            this.props.setLoading(false)
            this.setState({isLoading: false})
          })
      }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  toggleShowRight() {
    this.setState({
      showRight: true,
      showLeft: window.innerWidth > 1200,
    })
  }

  toggleShowRightAndLeft() {
    this.setState(this.state.showLeft ?
      {showLeft: false, showRight: true}
      : {showLeft: true, showRight: false})
  }

  toggle_display_extended_context_and_preferences = () => {
    this.setState({display_extended_context_and_preferences: !this.state.display_extended_context_and_preferences}, () => {
      console.log('display_extended_context_and_preferences set state to: ', !this.state.display_extended_context_and_preferences)
    })
  }

  setIsPriceLowerThanMinValue(value){
    this.setState({isPriceLowerThanMinValue: value})
  }

  setExperimentItemId(id){
    this.setState({experimentItemId: id})
    sessionStorage.setItem('id', `${id}`)
    if (!(this.state.showRight && this.state.showLeft)) this.toggleShowRightAndLeft()
  }
  setExperimentItemIdOnScroll(id, value){
    if (id && value) {
      this.setState({experimentItemIdOnScroll: id})
    }
  }
  showAllMap(value){
    if (value) {
      this.setState({experimentItemIdOnScroll: ''})
    }
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      showLeft: this.state.showLeft,
      showRight: !this.state.showLeft || (this.state.showLeft && window.innerWidth > 1200),
      showSwitchButton: !(this.state.showLeft) || !(window.innerWidth > 1200),
    })
  }

  render() {
    const { isAgent } = this.state
    const [ experimentSearch ] = this.props.experimentSearches || [{}]
    const isSupportedCity = experimentSearch ? config.ARRAY_OF_SUPPORTED_CITIES.includes(experimentSearch.city_bias) : false
    
    // if (!experimentSearch) {
    //   return <div></div>
    // }

    return (
      <div>

      <div className="main-desk">
        {this.state.showSwitchButton && false ?
          <button
            type="maximize"
            value="maximize"
            className='show-top-bar_button'
            onClick={() => this.setState(this.state.showLeft ?
              {showLeft: false, showRight: true}
              : {showLeft: true, showRight: false})
            }
          >
           {this.state.showLeft ? 'Показать карту' : '< Назад'}
          </button>
           : ''
        }

        {this.state.showLeft &&
          <div className='left-block'>
            <nav className="nav-bar">
              <div className='logo'>
                {/*<img src={logo} width={'20px'} height={'20px'} alt=""/>*/}
                <a href={`/`} rel="noreferrer" className='' style={{textDecoration: 'none', color: 'black'}}><div className="title"><span className='wayleap-logo'>Wayleap Киев</span></div></a>
              </div>
              {/*<div
                style={{position: 'absolute', right: '0.5rem', top: '0.5rem', cursor: 'pointer'}}
              >
                <SettingsIcon />
              </div>*/}
             </nav>

             {config.SHOW_FORMS && <MinAndMaxPriceSelect
              experimentSearch={experimentSearch}
              setExperimentSearch={this.props.setExperimentSearch}/>}

            {config.SHOW_FORMS && <MinAndMaxBedroomsAndMetersSelect
              experimentSearch={experimentSearch}
              setExperimentSearch={this.props.setExperimentSearch}
              isExtendedContextAndPreferences={true}/>}

              {config.SHOW_FORMS && <ShowExactPlaceSearch
                experimentSearch={experimentSearch}
                createPlaceSearch={this.props.createPlaceSearch}
                isExtendedContextAndPreferences={true}
              />}

            <InputAutocomplete
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
            />



            {/* <AmenityTrips
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
                updateAmenityTrip={this.props.updateAmenityTrip}
                amenityTrips={this.props.amenityTrips}
                amenities={this.props.amenities}
                isExtendedContextAndPreferences={true}
              /> */}

{/* <form>
      <Slider marks />
    </form> */}



<div className='middle-block-search'>

<div className='loadingBlock'>
  {this.state.isLoading || (window.location.search.c && !experimentSearch.id) ?
  <div>
    <div className="loading-window">
    <div className="car">
        <div className="strike"></div>
        <div className="strike strike2"></div>
        <div className="strike strike3"></div>
        <div className="strike strike4"></div>
        <div className="strike strike5"></div>
        <div className="car-detail spoiler"></div>
        <div className="car-detail back"></div>
        <div className="car-detail center"></div>
        <div className="car-detail center1"></div>
        <div className="car-detail front"></div>
        <div className="car-detail wheel"></div>
        <div className="car-detail wheel wheel2"></div>
    </div>

    <div className="text" style={{width: '100%'}}>
        <span>Loading</span><span className = "dots">...</span>
        <div><ProgressBar experimentSearch={experimentSearch}/></div>
    </div>
</div>
</div>
// <div>
    //   <div className='lds-spinner'>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </div>
    // </div>
    :
              <div className='search-button_block'>
                <div className='call-buttons'>
                {this.props.suggestedExperiments.length > 0 && <Email
                  style={{display: 'inline-block'}}
                  user={this.props.user}
                  experimentSearch={experimentSearch}
                  setExperimentSearch={this.props.setExperimentSearch}
                  showAllMap={this.showAllMap}/>}

                  <Button
                    variant='contained'
                    color='primary'
                    className=''
                    type='search'
                    value='Search'
                    style={{marginLeft: '20px'}}
                    onClick={() => {
                      this.doSearch(experimentSearch, config)}
                    }
                    >
                    {/* <SearchIcon /> */}
                    <Translate value='experimentSearch.search'/>
                  </Button>
                  {this.state.display_preferences_extension && <span onClick={() => {this.toggle_display_extended_context_and_preferences()} } style={{textDecoration: 'underline', cursor: 'pointer', color: 'gray'}}>
        (?)
      </span> }
                </div>
              </div>}

</div>
</div>






               {false && !isAgent && <CallProfessional showAllMap={this.showAllMap}/>}

                {this.state.display_extended_context_and_preferences && (!experimentSearch.is_context_frozen) && <ExtendedContextAndPreferences
                 setIsPriceLowerThanMinValue={this.setIsPriceLowerThanMinValue}
                 showAllMap={this.showAllMap}/>}

               {experimentSearch.id && <ExperimentList
                  setIsPriceLowerThanMinValue={this.setIsPriceLowerThanMinValue}
                  toggleShowRight={this.toggleShowRight}
                  isShowRight={this.state.showRight}
                  isPriceLowerThanMinValue={this.state.isPriceLowerThanMinValue}
                  experimentItemIdToScroll={this.state.experimentItemId}
                  setExperimentItemIdOnScroll={this.setExperimentItemIdOnScroll}
                  showAllMap={this.showAllMap}/>}


              {(!experimentSearch.id || config.ALWAYS_DISPLAY_WHAT_OTHERS_WANT) && <DisabledInputAutocomplete/>}

           </div>
        }

        {config.IS_ONLINE && this.state.showRight &&
          <div className="right-block">
            {!(this.state.showRight && this.state.showLeft) && <nav className="nav-bar" style={{backgroundColor: 'lightgray', height: '4.5rem'}}>
              <div className='logo'>
                {/*<img src={logo}  alt=""/>*/}
                <div className="title"><span className='wayleap-logo'>Wayleap</span></div>
              </div>

              <Button
                    variant='contained'
                    color='primary'
                    className='show-top-bar_button'
                    type='search'
                    value='Search'
                    onClick={() => {
                      this.toggleShowRightAndLeft();
                      this.setExperimentItemId(this.state.experimentItemIdOnScroll)
                    }}
                    >
                  Назад
              </Button>

            </nav>}
            <ExperimentMap
              setExperimentItemId={this.setExperimentItemId}
              experimentItemIdOnScroll={this.state.experimentItemIdOnScroll}
              places={this.props.places}
            />
          </div>
        }

        {experimentSearch.id && !isSupportedCity &&
          <div className='unsupported-city'>
            <div className='unsupported-city_block'>
              {experimentSearch.city_bias &&
                <p>
                  <Translate value='unsupportedLocation.unfortunately' />
                  <Translate value={`cities.${experimentSearch.city_bias}`} />
                  <Translate value='unsupportedLocation.isNotSupportedAtTheMoment' />
                </p>
              }
              <p>
                <Translate value='unsupportedLocation.selectAnotherCityIfYouWantToRentOrBuyProperty' />
              </p>
            </div>
            <div className="btn-group">
              {config.ARRAY_OF_SUPPORTED_CITIES.map((supportedCity, i) =>
                <button key={i}
                  type="submit"
                  onClick={this.props.updateExperimentSearch({...experimentSearch, city_bias: supportedCity })}
                >
                  <Translate value={`cities.${supportedCity}`} />
                </button>
              )}
            </div>
          </div>
        }
        {false && !experimentSearch.id &&
          <div className="loading-box">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <UnableToContactServer experimentSearch={experimentSearch} wait={5000}/>
        </div>}

        {false && !this.state.isLoaded &&
          <div className="loading-box">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <UnableToContactServer experimentSearch={experimentSearch} wait={5000}/>
          </div>
        }

          </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  suggestedExperiments: state.suggestedExperiments,
  experimentSearches: state.experimentSearches,
  state: state,
  places: state.places,
  amenityTrips: state.amenityTrips,
  amenities: state.amenities,
  isLoading: state.isLoading,
})

export default connect(mapStateToProps, { ...experimentSearchesActions, ...placeSearchesActions })(MainPage)
