import React, {Component} from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import config from '../../config'
import CurrencySwitcher from '../../components/CurrencySwitcher'
import * as actions from '../../actions/experimentSearches'
import { connect } from 'react-redux'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

class Priorities extends Component {
  constructor(props) {
      super(props)
      this.state = {
        showHowThisIsUsed: false,
        showWhatThisMeans: false
      }
    this.setEarningsPerHourInFuture = this.setEarningsPerHourInFuture.bind(this)
  }

  toggleShowHowThisIsUsed() {
    this.setState({
      showHowThisIsUsed: !this.state.showHowThisIsUsed
    })
  }

  toggleShowWhatThisMeans() {
    this.setState({
      showWhatThisMeans: !this.state.showWhatThisMeans
    })
  }

  askWhatAreAvailableSavings = (experimentSearch, setExperimentSearch) => {
    return(
      <div>{ experimentSearch.listing_type_bias === 'For Sale' && experimentSearch.person_knows_hour_worth && <div>
        <div className='pagination-row'>доступные сбережения(рекомендовано, не обязательно{/* для учета оптимальных методов финансирования*/})</div>

        <div className='price-row'>
          <div className=''>
            <input
              type="number"
              value={experimentSearch.savings || ''}
              onChange={e => {
                setExperimentSearch({
                ...experimentSearch,
                savings: parseInt(e.target.value, 10)
              })}}
              className='input'
            />

            <span className='amenity-row'>
              { config.SHOW_CONSEQUENT_CURRENCY_SELECTS &&
                config.CURRENCIES.map(option =>
                  <div
                    className={`${(experimentSearch.currency_bias || config.DEFAULT_CURRENCY) === option ? 'active ' : ''}pagination-row__item`}
                    onClick={() => setExperimentSearch({
                        ...experimentSearch,
                        currency_bias: option
                      })}
                    key={option}
                  >
                    <Translate value={`experimentSearch.in ${option}`} />
                  </div>
                )
              }
              { !config.SHOW_CONSEQUENT_CURRENCY_SELECTS && <div
                className='active pagination-row__item'
              >
                <Translate value={`experimentSearch.in ${experimentSearch.currency_bias}`} />
              </div> }
            </span>
          </div>
        </div>
      </div>}
    </div>
    )
  }
  seeWhatThisMeans = () => {
    const {showWhatThisMeans} = this.state

    return(<div className='pagination-row'>
      <div onClick={() => this.toggleShowWhatThisMeans() }>
        (?) {I18n.t('experimentSearch.seeWhatThisMeans')}
      </div>

      {showWhatThisMeans && <div className='pagination-row'>
        <div>{I18n.t('experimentSearch.AlternaticeCosts')}</div>
        <div>{I18n.t('experimentSearch.Roughly')}</div>
      </div>}
    </div>)
  }
  seeHowThisIsUsed = () => {
    const {showHowThisIsUsed} = this.state

    return(<div>
      <div onClick={() => this.toggleShowHowThisIsUsed()} className='pagination-row'>
        (?) {I18n.t('experimentSearch.seeHowThisIsUsed')}
      </div>

      {showHowThisIsUsed && <div className='pagination-row'>
        <div>{I18n.t('experimentSearch.toCalculateOptimalTradeOffs')}</div>
        {/*<div>1) выбор оптимальных методов передвижения</div>
        <div>2) сравнение разной по цене недвижимости</div>
        <div>3) выбор оптимальных методов финансирования</div>*/}
      </div>}
    </div>)
  }
  setEarningsPerHourInFuture = (value) => {
    const {experimentSearch, setExperimentSearch } = this.props

    setExperimentSearch({
      ...experimentSearch,
      earnings_per_hour_in_future: parseInt(value, 10),
      person_knows_hour_worth: parseInt(value, 10) > 0
    })
  }
  setEarningsPerHourCurrent = (value) => {
    const {experimentSearch, setExperimentSearch } = this.props

    setExperimentSearch({
      ...experimentSearch,
      earnings_per_hour_current: parseInt(value, 10)
    })
  }


  render() {
    const { setExperimentSearch } = this.props
    const [ experimentSearch ] = this.props.experimentSearches

    return (
      <div>
        <div className='pagination-row'>
          <h3 className={`${experimentSearch.person_knows_hour_worth ? 'active ' : ''}something`} style={{display: 'flex', textAlign: 'center'}}>
            {/*I18n.t('experimentSearch.costOfOneHourInTheFuture')*/}
            {I18n.t('experimentSearch.opportunityCostOfOneHour')}
          </h3>
        </div>

        <div className='pagination-row'>
          <BootstrapInput
            autoFocus={true}
            value={experimentSearch.earnings_per_hour_in_future || ''}
            onChange={event => {
              setExperimentSearch({
                ...experimentSearch,
                earnings_per_hour_in_future: parseInt(event.target.value, 10) || null,
                person_knows_hour_worth: parseInt(event.target.value, 10) > 0
              })}}
           />
            <CurrencySwitcher
              experimentSearch={experimentSearch}
              setExperimentSearch={setExperimentSearch}
            />
          </div>

          { this.seeWhatThisMeans() }
          { this.seeHowThisIsUsed() }

        <div className='pagination-row'>
          <h3 className={`${experimentSearch.person_knows_hour_worth ? 'active ' : ''}something`} style={{display: 'flex', textAlign: 'center'}}>
            {/*I18n.t('experimentSearch.costOfOneHourInTheFuture')*/}
            Коэффицент полезности дополнительного пространства, используйте значения от 0.2 до 5.
          </h3>
        </div>

        <div className='pagination-row'>
          <BootstrapInput
            autoFocus={true}
            value={experimentSearch.additional_space_utility_coef || ''}
            onChange={event => {
              setExperimentSearch({
                ...experimentSearch,
                additional_space_utility_coef: parseFloat(event.target.value, 10) || 0.999,
              })}}
           />
        </div>

        <div className='pagination-row'>
          <h3 className={`${experimentSearch.person_knows_hour_worth ? 'active ' : ''}something`} style={{display: 'flex', textAlign: 'center'}}>
            {/*I18n.t('experimentSearch.costOfOneHourInTheFuture')*/}
            Коэффициент важности ЗНО школы, используйте значения от 0.2 до 5.
          </h3>
        </div>

        <div className='pagination-row'>
          <BootstrapInput
            autoFocus={true}
            value={experimentSearch.school_quality_coef || ''}
            onChange={event => {
              setExperimentSearch({
                ...experimentSearch,
                school_quality_coef: parseFloat(event.target.value, 10) || 0.999,
              })}}
           />
        </div>

          { config.ASK_WHAT_ARE_AVAILABLE_SAVINGS && this.askWhatAreAvailableSavings(experimentSearch, setExperimentSearch) }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  experimentSearches: state.experimentSearches,
})

export default connect(mapStateToProps, { ...actions })(Priorities)
