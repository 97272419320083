import React, {Component} from 'react'
import { I18n } from 'react-redux-i18n'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
const values=[
  'Голосеевский',
  'Святошинский',
  'Соломенский',
  'Оболонский',
  'Подольский',
  'Печерский',
  'Шевченковский',
  'Дарницкий',
  'Днепровский',
  'Деснянский'
]
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectMenu: {
    minWidth: 170,
    maxWidth: 170
  }
})


class DistrictsSelect extends Component {
  constructor(props) {
      super(props)
      this.state = {
       selected: this.props.experimentSearch.preferred_districts || [],
       open: false
      }
  }

  handleClose = () => {
    const { selected } = this.state
    const { experimentSearch, setExperimentSearch } = this.props
// updateExperimentSearch
    this.setState({open: false})
    const newExperimentSearch = {
      ...experimentSearch,
      preferred_districts: selected
    }
    setExperimentSearch(newExperimentSearch)
    // updateExperimentSearch(newExperimentSearch)
  }

  handleOpen = () => {
    this.setState({open: true})
  }

  render(){
    const { classes } = this.props
    // const { selected = [] } = this.props.experimentSearch.preferred_districts
    const { open, selected } = this.state

    return (
    <div className='infrastructure-info pagination-row'>
      <span className='price-select'>
        {I18n.t('experimentSearch.districts')}
        <Select
          multiple
          autoWidth={true}
          value={selected.length > 0 ? selected : ['Все районы']}
          classes={classes}
          onChange={(e) => {
            const newSelected = e.target.value.filter(item => item !== 'Все районы')
            this.setState({selected: newSelected})
          }}
          open={open}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(', ')}
        >
        {values.map(item => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={selected.indexOf(item) > -1} style ={{color: "grey"}}/>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
        </Select>
      </span>
    </div>
  )
 }
}
export default withStyles(styles)(DistrictsSelect)
