import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import NavigationIcon from '@material-ui/icons/Navigation'
import config from '../../config'

export default class AgentLinks extends Component {
  render() {
    const { listing } = this.props
    return(
      <div className=''>
          {/**<a href={listing.source_url.split("redirect-realty").join("a")} rel="noopener noreferrer" target="_blank">**/}
       {false && config.SHOW_SHIT && <div className='description_row-item'>
         {listing.source_url.startsWith("https://flatfy.lun.ua") &&
          <a href={listing.source_url.split("?")[0].split("redirect-realty").join("a").replace('www.lun.', 'flatfy.lun.')} rel="noopener noreferrer" target="_blank">
            <Button size="small">
              <NavigationIcon fontSize="small" />
              {listing.source}
            </Button>
          </a>
         }

       </div>}
       {<div className=''>
         {false && config.SHOW_SHIT && <span className='separator'>•</span>}
          {false && config.SHOW_SHIT && <a href={listing.source_url.startsWith("https://flatfy.lun.ua") ? listing.source_url.split("?")[0] : listing.source_url} rel="noopener noreferrer" target="_blank" style={{textDecoration: 'none', marginBottom: '15px'}}>
           <Button size="small" variant="contained" color="primary">
             {/* <NavigationIcon fontSize="small" /> */}
             {listing.source_url.startsWith("https://flatfy.lun.ua") ? 'перейти' : listing.source}
           </Button>
         </a>}
       </div>}
     </div>
    )
  }
}
