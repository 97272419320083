import React, { PureComponent } from 'react'
import { Translate } from 'react-redux-i18n'
import { I18n } from 'react-redux-i18n'

export default class LifestyleOptions extends PureComponent {
  state = {
    experimentSearch: this.props.experimentSearch || {},
    placeSearches:[]
  }
  getOptionName = (option) => {
    switch(option) {
      case 'is_household_with_children_under_school_age':
        return I18n.t('exactPlaceSearch.kindergarten')
      case 'is_household_with_children_in_school':
        return I18n.t('exactPlaceSearch.school')
      case 'is_household_with_student':
        return I18n.t('exactPlaceSearch.uni')
      default:
        return null
    }
  }

  render() {
    const {setExperimentSearch, placeSearches} = this.props
    const {experimentSearch} = this.state
// updateExperimentSearch

    const lifestyleOptions = [
          'is_household_a_couple',
          'is_household_with_children_under_school_age',
          'is_household_with_children_in_school',
          'is_household_pet_owner',
          'is_household_a_group',
          'is_household_with_student',
        ].concat(experimentSearch.category_bias === 'room' ? ['is_household_a_male', 'is_household_a_female'] : ['is_household_a_male', 'is_household_a_female'])
         .concat([ 'is_household_with_car' ])

    return(
      <div className='pagination-row'>
        <h3><Translate value='experimentSearch.whoWillHoldHouse'/></h3>
        <div className='lifestyle-options_block'>
          {lifestyleOptions.map(option =>
            <div
              className={`${experimentSearch[option] ? 'active ' : ''} pagination-row__item`}
              onClick={() => {
                const newExperimentSearch = {...experimentSearch, [option]: !experimentSearch[option]}
                const newExperimentSearchWithNumberOfPeople = {
                  ...newExperimentSearch,
                  number_of_people_per_household: (!!newExperimentSearch.is_household_a_couple && !newExperimentSearch.is_household_a_group && !newExperimentSearch.is_household_with_children_under_school_age && !newExperimentSearch.is_household_with_children_in_school) ? 2 :
                  ((newExperimentSearch.is_household_a_group || newExperimentSearch.is_household_with_children_under_school_age || newExperimentSearch.is_household_with_children_in_school) ? null :
                    ((newExperimentSearch.is_household_a_male || newExperimentSearch.is_household_a_female) ? 1 : null))
                }
                this.setState({experimentSearch: newExperimentSearchWithNumberOfPeople})
                setExperimentSearch(newExperimentSearchWithNumberOfPeople)
                // updateExperimentSearch(newExperimentSearchWithNumberOfPeople, false)

                const optionName = this.getOptionName(option)
                if (optionName) {
                  if (experimentSearch[option]) {
                    placeSearches.filter(item => item.name === optionName).forEach(item => {
                      item['is_activated'] = false
                      item['round_trips_per_week'] = null
                      this.props.updatePlaceSearch(item)
                    })
                   } else {
                    this.props.createPlaceSearch({name: optionName, experiment_search_id: this.props.experimentSearch.id, is_activated: true})
                  }
                }
              }}
              key={option}
            >
              <Translate value={`experimentSearch.${option}`} />
            </div>
          )}
        </div>
      </div>
    )
  }
}
