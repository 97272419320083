import React, {Component} from 'react'
import Button from '../../components/Button'
import { Translate } from 'react-redux-i18n'

import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import EditIcon from '@material-ui/icons/Edit'

import RequestTourModal from '../../containers/RequestTourModal'
import closeGreyIcon from '../../assets/closeGrey.svg'
import queryString from 'query-string'
import {withRouter} from 'react-router-dom'
import config from '../../config';

class Buttons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showRequestTourModal: false
    }
  }

  setAndUpdateExperimentSearch = (action, option) => {
    const { experiment, setExperimentSearch, updateExperimentSearch, experimentSearch } = this.props
    let newExperimentSearch

    if (action === 'addToFavorites') {
      const array = this.props.experimentSearch[option]
      if (!array.includes(`${experiment.listing_id}`)) {
        const newArray = [...array, experiment.listing_id ]
        newExperimentSearch = {...experimentSearch, [option]: newArray}
      }
    }

    if (action === 'removeFromFavorites') {
      const newArray = experimentSearch.favorited_listing_ids.filter(item => item !== `${experiment.listing_id}`)
      newExperimentSearch = {...experimentSearch, favorited_listing_ids: newArray}
    }

    setExperimentSearch(newExperimentSearch)
    updateExperimentSearch(newExperimentSearch)
  }

  totallyRemove = (option) => {
    const { setListing, listing, setInactive } = this.props
    const { isSinglePage } = this.props

    if (option === 'is_inactive') {
      setListing(listing.id, {is_active: false})
      !isSinglePage && setInactive('isActive', false)
    }
    if (option === 'is_scam') {
      setListing(listing.id, {is_scam: true})
      !isSinglePage && setInactive('isScam', true)
    }
  }

  toggleShowModal() {
    this.setState({
      showRequestTourModal: !this.state.showRequestTourModal
    })
  }

  render() {
    const { experiment, experimentSearch, isAgent, isSinglePage } = this.props
    const isInFavourites = experimentSearch.favorited_listing_ids && experimentSearch.favorited_listing_ids.includes(`${experiment.listing_id}`)
    const isInExcluded = experimentSearch.excluded_listing_ids && experimentSearch.excluded_listing_ids.includes(`${experiment.listing_id}`)

    return(
      <div>
        {!isSinglePage && isAgent && <div className='description_row_buttons_agent'>
          <Button
            className='item-button'
            onClickFunction={()=> this.totallyRemove('is_inactive')}
            icon={<DeleteForeverIcon fontSize="small"/>}
            label={<Translate value='experimentItem.isInactive'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> this.totallyRemove('is_scam')}
            icon={<DeleteOutlineIcon fontSize="small"/>}
            label={<Translate value='experimentItem.isScam'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> this.setAndUpdateExperimentSearch('addToFavorites','excluded_listing_ids')}
            icon={<DeleteIcon fontSize="small"/>}
            label={<Translate value='experimentItem.showLess'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> isInFavourites ? this.setAndUpdateExperimentSearch('removeFromFavorites') : this.setAndUpdateExperimentSearch('addToFavorites', 'favorited_listing_ids')}
            icon={<FavoriteBorderIcon fontSize="small"/>}
            label={isInFavourites ? <Translate value='experimentItem.removeFromFavorites'/> : <Translate value='experimentItem.addToFavorites'/>}>
          </Button>
          {config.SHOW_SHIT && <Button
            className='item-button'
            onClickFunction={()=> this.toggleShowModal()}
            icon={<AddShoppingCartIcon fontSize="small"/>}
            label={<Translate value='experimentItem.book'/>}>
          </Button>}
          {config.SHOW_SHIT && <Button
            className='item-button'
            icon={<PhoneInTalkIcon fontSize="small"/>}
            label={<Translate value='experimentItem.call'/>}
            href="tel:+380668514359">
          </Button>}
        </div>}
        {!isSinglePage && !isAgent && <div className='description_row_buttons'>
          <Button
            className='item-button'
            onClickFunction={()=> this.setAndUpdateExperimentSearch('addToFavorites','excluded_listing_ids')}
            icon={<DeleteIcon fontSize="small"/>}
            label={<Translate value='experimentItem.showLess'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> isInFavourites ? this.setAndUpdateExperimentSearch('removeFromFavorites') : this.setAndUpdateExperimentSearch('addToFavorites', 'favorited_listing_ids')}
            icon={<FavoriteBorderIcon fontSize="small"/>}
            label={isInFavourites ? <Translate value='experimentItem.removeFromFavorites'/> : <Translate value='experimentItem.addToFavorites'/>}>
          </Button>
          {config.SHOW_SHIT && <Button
            className='item-button'
            onClickFunction={()=> this.toggleShowModal()}
            icon={<AddShoppingCartIcon fontSize="small"/>}
            label={<Translate value='experimentItem.book'/>}>
          </Button>}
          {config.SHOW_SHIT && <Button
            className='item-button'
            icon={<PhoneInTalkIcon fontSize="small"/>}
            label={<Translate value='experimentItem.call'/>}
            href="tel:+380668514359">
          </Button>}
        </div>}

          {this.state.showRequestTourModal &&
            <div className='request-modal'>
              <div className='request-tour request-tour-mobile'>
                <img src={closeGreyIcon} alt="" className='modal-close-btn' onClick={()=>this.toggleShowModal()} />
                <div>
                  <RequestTourModal
                    experiment={experiment}
                  />
                </div>
              </div>
            </div>}

        {isSinglePage && isAgent && <div className='description_row_buttons_agent'>
          <Button
            className='item-button'
            onClickFunction={()=> {
              this.totallyRemove('is_inactive')
              this.props.history.push(`/search?c=${queryString.parse(this.props.location.search).c}`)}}
            icon={<DeleteForeverIcon fontSize="small"/>}
            label={<Translate value='experimentItem.isInactive'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> {
              this.totallyRemove('is_scam')
              this.props.history.push(`/search?c=${queryString.parse(this.props.location.search).c}`)}}
            icon={<DeleteOutlineIcon fontSize="small"/>}
            label={<Translate value='experimentItem.isScam'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> {
              this.setAndUpdateExperimentSearch('addToFavorites', 'excluded_listing_ids')
              this.props.history.push(`/search?c=${queryString.parse(this.props.location.search).c}`)}
            }
            icon={<DeleteIcon fontSize="small"/>}
            label={<Translate value='experimentItem.showLess'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> isInFavourites ? this.setAndUpdateExperimentSearch('removeFromFavorites') : this.setAndUpdateExperimentSearch('addToFavorites', 'favorited_listing_ids')}
            icon={<FavoriteBorderIcon fontSize="small"/>}
            label={isInFavourites ? <Translate value='experimentItem.removeFromFavorites'/> : <Translate value='experimentItem.addToFavorites'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> this.toggleShowModal()}
            icon={<AddShoppingCartIcon fontSize="small"/>}
            label={<div></div>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> this.props.toggleShowEditListingModal()}
            icon={<EditIcon fontSize="small"/>}
            label={<div></div>}>
          </Button>
        </div>}

        {!isInExcluded && isSinglePage && !isAgent && <div className='description_row_buttons'>
          <Button
            className='item-button'
            onClickFunction={()=> this.setAndUpdateExperimentSearch('addToFavorites', 'excluded_listing_ids')}
            icon={<DeleteIcon fontSize="small"/>}
            label={<Translate value='experimentItem.showLess'/>}>
          </Button>
          <Button
            className='item-button'
            onClickFunction={()=> isInFavourites ? this.setAndUpdateExperimentSearch('removeFromFavorites') : this.setAndUpdateExperimentSearch('addToFavorites', 'favorited_listing_ids')}
            icon={<FavoriteBorderIcon fontSize="small"/>}
            label={isInFavourites ? <Translate value='experimentItem.removeFromFavorites'/> : <Translate value='experimentItem.addToFavorites'/>}>
          </Button>
          {config.SHOW_SHIT &&<Button
            className='item-button'
            onClickFunction={()=> this.toggleShowModal()}
            icon={<AddShoppingCartIcon fontSize="small"/>}
            label={<div></div>}>
          </Button>}
          {config.SHOW_SHIT && <Button
            className='item-button'
            icon={<PhoneInTalkIcon fontSize="small"/>}
            label={<Translate value='experimentItem.call'/>}
            href="tel:[+380668514359]">
          </Button>}
        </div>}

      </div>
    )
  }
}
export default withRouter(Buttons)
