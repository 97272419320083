import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Translate } from 'react-redux-i18n'

import * as actions from '../../actions/experimentSearches'
import config from '../../config'
// import NearbyAmenities from '../../components/NearbyAmenities'
// import NearbyMetroStationOrStreetName from '../../components/NearbyMetroStationOrStreetName'
// import Highlights from '../../components/Highlights'
// import Buttons from '../../components/Buttons'
// import ImagesGallery from '../../components/ImagesGallery'
// import RecommendationRankAndAddress from '../../components/RecommendationRankAndAddress'
// import AgentApartmentsInfo from '../../components/AgentApartmentsInfo'
// import AgentLinks from '../../components/AgentLinks'
// import ApartmentsInfo from '../../components/ApartmentsInfo'
import howerObserver from '../../components/HowerObserver'
import likeFilled from '../../assets/heartFilled.svg'

class OneLineExperiment extends PureComponent {
  constructor(props) {
      super(props)
      this.state = {
        isShowDetailsExpanded: false,
        isNoLongerAvailable: false,
        isShowInactive: false,
        agentPhoneNumberCopied: false,
        isAgent: config.AGENT || window.location.hostname === "agent.imbadom.com" || window.location.hostname === "agent.wayleap.com",
        showRequestModal: false,
        experiment: {},
        place: {},
        listing: {},
        amenityLocations: [],
        experimentSearch: {},
        amenityRoutes: {},
        listingPhotos: [],
        listingEntity: {},
        isSet: false,
        isActive: true,
        isScam: false
      }
    this.setInactive = this.setInactive.bind(this)
  }
  componentDidMount() {
    const {dataId, scrollToElement, lastId } = this.props
    if (dataId === lastId) {
      scrollToElement()
    }
  }

  showOnMap = (place) => {
    const [ experimentSearch ] = this.props.experimentSearches
    this.props.setExperimentSearch({
      ...experimentSearch,
      map_latitude: place.latitude,
      map_longitude: place.longitude,
      map_zoom: 14
    })
    if (!this.props.experimentSingle) {
      this.props.toggleShowRight()
    }
  }

  toggleShowModal() {
    this.setState({
      showRequestTourModal: !this.state.showRequestTourModal
    })
  }
  setInactive(name, value) {
    this.setState({
      [name]: value
    })
  }

  setExperiment = (experimentListItem, places, listings, amenityRoutes, listingEntities, experimentSearches, suggestedExperiments, amenityLocations) => {
    const place = places.find(place => place.id === experimentListItem.place_id)
    const listing = listings.find(listing => listing.id === experimentListItem.listing_id)
    const [ experimentSearch ] = experimentSearches
    this.setState({
      place,
      amenityRoutes: amenityRoutes.filter(amenityRoute => amenityRoute.place_id === place.id),
      experiment: experimentListItem,
      listing,
      listingPhotos: listing.photo_urls,
      listingEntity: listingEntities.find(entity => entity.id === experimentListItem.listing_entity_id),
      experimentSearch,
      isSet: true
    })
  }

  componentDidUpdate(prevProps) {
     if (this.props.isHovering !== prevProps.isHovering && this.props.isHovering) {
       this.props.setExperimentItemIdOnScroll(this.props.experimentListItem.id, true)
     }
     if (this.props.isHovering !== prevProps.isHovering && !this.props.isHovering) {
       this.props.setExperimentItemIdOnScroll(this.props.experimentListItem.id, false)
     }
   }

  render() {
    const { experimentListItem, listings, places, isNew } = this.props //listingEntities, amenityRoutes, amenities, 
    const [ experimentSearch ] = this.props.experimentSearches
    const experiment = experimentListItem
    // const { isScam, isActive, isAgent } = this.state

    const listing =  listings.find(listing => listing.id === experiment.listing_id)
    const place = places.find(place => place.id === experiment.place_id)

                const price_in_usd = Object.keys(listing).length >  0 ? (listing.price_per_month ? listing.price_per_month : listing.price) / ((experimentSearch || {}).exchange_rates || {})[listing.currency_code] : 0
            const price_in_target_currency = price_in_usd * ((experimentSearch || {}).exchange_rates || {})[experimentSearch.currency_bias]
            const subwayStationName = experiment.highlights && experiment.highlights.data.find(({name}) => name === 'nearest subway station').value


    if (!listing || !place) {
      return (<span/>)
    }
    if (Object.keys(experimentSearch).length <= 0 ) {
      return (<span/>)
    }

    // const amenityRoutes = amenityRoutes.filter(amenityRoute => amenityRoute.place_id === place.id)
    // const listingEntity = listingEntities.find(entity => entity.id === experiment.listing_entity_id)
    const isInFavourites = experimentSearch.favorited_listing_ids && experimentSearch.favorited_listing_ids.includes(`${experiment.listing_id}`)

    return (
      <div>
                  <a
            key={experiment.listing_id}
            onClick={()=> sessionStorage.setItem('id', `${experiment.id}`)}
            href={config.SHOW_INDIVIDUAL_LISTING ? `listing/?c=${experiment.experiment_search_id}&i=${experiment.listing_id}` : null}
            style={{textDecoration: 'none', color: 'inherit'}}
          >
          <div
            className='middle-block_row-wrap_list middle-block_row-wrap'
            key={experiment.id}
            style={{cursor: 'pointer'}}
          >
            <span style={{width: '25px'}}>№&nbsp;{experiment.rank}</span>
            <span className='miniseparator'>•</span><span style={{width: '160px'}}>оценка&nbsp;Wayleap&nbsp;{Math.round(experiment.calculated_value)}</span>
            <span className='miniseparator'>•</span><span style={{width: '110px'}}>{`${Math.round(price_in_target_currency).toLocaleString()}`}&nbsp;<Translate value={`listing.${experimentSearch.currency_bias}`}/>{listing.price_per_month && config.SHOW_SHIT && <span>/ <Translate value='listing.month'/></span>}</span>
            <span className='miniseparator'>•</span><span style={{width: '70px'}}><Translate count={listing.bedrooms} value='listing.bedrooms' /></span>
            <span className='miniseparator'>•</span><span style={{width: '50px'}}><Translate value='listing.squareMetersShort' count={Math.round(listing.square_meters)} /></span>
            <span className='miniseparator'>•</span><span style={{width: '270px'}}>ст.м. {subwayStationName}</span>
            {isNew && <span className='new-ad-highlight' style={{color: 'orange', marginLeft: 10}}><sup>new</sup></span>}
            {isInFavourites && <img src={likeFilled} height={15} width={23} alt='' style={{marginLeft: 'auto'}}/>}
            {!listing.is_active && <span><span className='miniseparator'>•</span><span style={{width: '100px', color: 'red'}}>Не актуально</span></span>}
            {listing.is_scam && <span><span className='miniseparator'>•</span><span style={{width: '100px', color: 'orange'}}>Fake</span></span>}
          </div></a>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  state: state,
  experimentSearches: state.experimentSearches,
  photos: state.photos,
  listings: state.listings,
  listingEntities: state.listingEntities,
  places: state.places,
  placeSearches: state.placeSearches,
  amenityRoutes: state.amenityRoutes,
  amenities: state.amenities,
  suggestedExperiments: state.suggestedExperiments,
  amenityLocations: state.amenityLocations,
})

export default connect(mapStateToProps, { ...actions })(withRouter(howerObserver(OneLineExperiment)))
