import React, {Component} from 'react'
// import { I18n } from "react-redux-i18n"
import mRed from '../../assets/mRed.jpeg'
import mGreen from '../../assets/mGreen.jpeg'
import mBlue from '../../assets/mBlue.jpeg'
// import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'

const redMetroStations = ['Академгородок','Житомирская','Святошин','Нивки','Берестейская','Шулявская','Политехнический институт','Вокзальная',
  'Университет','Театральная','Крещатик','Арсенальная','Днепр','Гидропарк','Левобережная','Дарница','Черниговская','Лесная', 'Політехнічний інститут ', 'Арсенальна', 'Шулявська',
  'Вокзальна','Дніпро','Шулявська','Дарниця','Чернігівська','Святошин','Лісова','Хрещатик','Університет','Академмістечко', 'Політехнічний інститут', 'Житомирська']
const greenMetroStations = ['Синеозёрная','Виноградарь','Мостицкая','Сырец','Дорогожичи','Герцена','Лукьяновская','Львовская брама','Золотые ворота',
  'Дворец спорта','Кловская','Печерская','Дружбы народов','Выдубичи','Теличка','Южный мост','Славутич', 'Осокорки','Позняки','Харьковская',
  'Вырлица','Бориспольская','Красный хутор', 'Золоті ворота','Кловська','Золоті ворота','Видубичі','Осокорки', 'Харківська',
  'Лук\'янівська', 'Лук’янівська','Дорогожичі','Сирець','Бориспільська','Червоний хутір', 'Дружби народів', 'Палац Спорту']
const blueMetroStations = ['Героев Днепра','Минская','Оболонь','Почайна','Тараса Шевченко','Контрактовая площадь ', 'Почтовая площадь',
  'Майдан Незалежности','Площадь Льва Толстого','Олимпийская','Дворец «Украина»', 'Дворец Украина','Лыбедская','Деміївська','Голосеевская','Васільковская',
  'Выставочный центр','Ипподром','Теремки','Одесская', 'Контрактова площа', 'Либідська','Майдан Незалежності','Оболонь','Олімпійська',
  'Героїв Дніпра','Либідська','Васильківська','Виставковий центр','Іподром','Теремки', 'Тараса Шевченка', 'Мінська']

export default class NearbyMetroStationOrStreetName extends Component {
  renderMetroIcon = (metroStation) => {
    if (redMetroStations.includes(metroStation)) {
      return <img src={mRed} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
    }
    if (greenMetroStations.includes(metroStation)) {
      return <img src={mGreen} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
    }
    if (blueMetroStations.includes(metroStation)) {
      return <img src={mBlue} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
    }
  }

  getColor = (metroStation) => {
    if (redMetroStations.includes(metroStation)) {
      return 'red'
    }
    if (greenMetroStations.includes(metroStation)) {
      return 'green'
    }
    if (blueMetroStations.includes(metroStation)) {
      return '#0066FF'
    }
  }

  render() {
    const {place, amenityRoutes, amenities, amenityLocations} = this.props
    const nearbyMetroStation = amenities ? amenityRoutes.find(amenityRoute => amenityRoute.amenity_id === amenities.find(amenity => amenity.name === 'Метро').id && amenityRoute.place_id === place.id) : {}
    const amenityLocation = nearbyMetroStation ? amenityLocations.find(amenityLocation => amenityLocation.amenity_id === nearbyMetroStation.amenity_id && amenityLocation.place_id === nearbyMetroStation.amenity_location_place_id) : null
    const name = nearbyMetroStation && amenityLocation ? amenityLocation.name : ''
    const time = nearbyMetroStation && amenityLocation ? Math.round(nearbyMetroStation.round_trip_foot_time_distance/2/60) : -1
    const color = nearbyMetroStation && amenityLocation ? this.getColor(name) : 'purple'

    return (
        <span className='recommendation ranking' style={{margin: 'auto', backgroundColor: 'orange'}}>
          <span style={{marginLeft: 0}}>{this.renderMetroIcon(name)}</span>&nbsp;
          <span style={{marginRight: '6px', marginLeft: 0, color: color}}>{ name } </span>
          <span style={{color: 'gray'}}>&nbsp;{time}&nbsp;мин</span>
        </span>
    )
    }
  }
// {<div className='description_row-item break-text'>  place.full_address}{place.city ? `, ${place.city}` : '
