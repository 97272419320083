import React from 'react'
import { Translate } from 'react-redux-i18n'

export default function PeoplePerHouseholdOptions({experimentSearch, setExperimentSearch}) {
    const variation_in_number_of_people = (experimentSearch.is_household_with_children_under_school_age || experimentSearch.is_household_with_children_in_school || experimentSearch.is_household_a_group ? 8 : 0)
    const people_per_household_options = [...Array(variation_in_number_of_people).keys()].map(i => i + 1 + (experimentSearch.is_household_a_couple || experimentSearch.is_household_a_group ? 1 : 0) + (experimentSearch.is_household_with_children_under_school_age ? 1 : 0) + (experimentSearch.is_household_with_children_in_school ? 1 : 0))
    return (
      <div className="pagination-row">
      {people_per_household_options.length > 0 ? <span><Translate value='experimentSearch.number_of_people_per_household' />:&nbsp;{experimentSearch.number_of_people_per_household}</span> : <span></span>}
        { people_per_household_options.map(option =>
            <div
              className={`${parseInt(experimentSearch.number_of_people_per_household, 10) === option ? 'active ' : ''}pagination-row__item`}
              onClick={() => setExperimentSearch({
                  ...experimentSearch,
                  number_of_people_per_household: experimentSearch.number_of_people_per_household === option ? null : option
                })}
              key={option}
            >
              { option }
            </div>
          )
        }
      </div>
    )
  }
