import React, {Component} from 'react'
import { Translate } from 'react-redux-i18n'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'
import config from '../../config'

class ExecutionStats extends Component {
  render() {
  const {results_count, execution_time, relevant_suggestions_count} = this.props

  return(
    <div className='execution-stats'>
      <div style={{fontSize: '11px'}}>
        <Translate
          value='experimentList.resultsCount'
          experiment_count={results_count}
          total_count={relevant_suggestions_count}
        />
        {config.SHOW_SHIT && 'по потенциалу социального роста для вас и вашей семьи'}
        {config.SHOW_SHIT && <span
          onClick={() => { window.location='/about'+ window.location.search }}
          style={{marginLeft: '7px', marginRight: '7px', color: 'blue', cursor: 'pointer'}}
        >
          узнать больше
        </span>}
        <Translate
          value='experimentList.executionTime'
          execution_time={Math.round(execution_time/10)/100}
        />
        {/*<Translate
          value='experimentList.relevantSuggestions'
          number_of_suggestions={relevant_suggestions_count}
        />*/}
      </div>
    </div>
  )}
}
export default howerObserver(togleShowAllMap(ExecutionStats))
