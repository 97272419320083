import React, { PureComponent } from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import InputLabel from '@material-ui/core/InputLabel';
// import Input from '@material-ui/core/Input';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

const classes = ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
})

class DialogSelect extends PureComponent {
  constructor(props) {
      super(props)
      this.state = {
       age: '',
       open: false,
      }
  }

  setAge(age) {
    this.setState({
      age: age
    })
  }

  setOpen(open) {
    this.setState({
      open: open
    })
  }


  handleChange = (event) => {
    this.setAge(Number(event.target.value) || '');
  }

  handleClickOpen = () => {
    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };
  render() {
  return (
    <div style={{display: 'inline-block'}}>
      <Button           style={{padding: "5px", minHeight: "40px", margin: "5px"}}
          variant="outlined"
          color="primary"
  onClick={this.handleClickOpen}>{this.props.title}</Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.open} onClose={this.handleClose}>
        {this.props.children}
        <DialogTitle>{this.props.call_to_action}</DialogTitle>
        <DialogContent>
          <form className={classes.container}>

            {/* <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Age</InputLabel>
              <Select
                native
                value={this.state.age}
                onChange={this.handleChange}
                input={<Input id="demo-dialog-native" />}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">Age</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={this.state.age}
                onChange={this.handleChange}
                input={<Input />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl> */}
          </form>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={this.handleClose} color="primary">
            отмена
          </Button> */}
          <Button onClick={this.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
}
export default DialogSelect