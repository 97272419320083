import React, {Component} from 'react'

import mRed from '../../assets/metro_red.svg'
import mGreen from '../../assets/metro_green.svg'
import mBlue from '../../assets/metro_blue.svg'
import config from '../../config'
import SubwayLineIcon from '../../components/SubwayLineIcon'

const redMetroStations = ['Академгородок','Житомирская','Святошин','Нивки','Берестейская','Шулявская','Политехнический институт','Вокзальная',
  'Университет','Театральная','Крещатик','Арсенальная','Днепр','Гидропарк','Левобережная','Дарница','Черниговская','Лесная', 'Політехнічний інститут ', 'Арсенальна', 'Шулявська',
  'Вокзальна','Дніпро','Шулявська','Дарниця','Чернігівська','Святошин','Лісова','Хрещатик','Університет','Академмістечко', 'Політехнічний інститут', 'Житомирська']
const greenMetroStations = ['Синеозёрная','Виноградарь','Мостицкая','Сырец','Дорогожичи','Герцена','Лукьяновская','Львовская брама','Золотые ворота',
  'Дворец спорта','Кловская','Печерская','Дружбы народов','Выдубичи','Теличка','Южный мост','Славутич', 'Осокорки','Позняки','Харьковская',
  'Вырлица','Бориспольская','Красный хутор', 'Золоті ворота','Кловська','Золоті ворота','Видубичі','Осокорки', 'Харківська',
  'Лук\'янівська', 'Лук’янівська','Дорогожичі','Сирець','Бориспільська','Червоний хутір', 'Дружби народів', 'Палац Спорту']
const blueMetroStations = ['Героев Днепра','Минская','Оболонь','Почайна','Тараса Шевченко','Контрактовая площадь ', 'Почтовая площадь',
  'Майдан Незалежности','Площадь Льва Толстого','Олимпийская','Дворец «Украина»', 'Дворец Украина','Лыбедская','Деміївська','Голосеевская','Васільковская',
  'Выставочный центр','Ипподром','Теремки','Одесская', 'Контрактова площа', 'Либідська','Майдан Незалежності','Оболонь','Олімпійська',
  'Героїв Дніпра','Либідська','Васильківська','Виставковий центр','Іподром','Теремки', 'Тараса Шевченка', 'Мінська']

export default class ApartmentItemHighlights extends Component {

  renderMetroIcon = (metroStation) => {
    if (redMetroStations.includes(metroStation)) {
      return <img src={mRed} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
    }
    if (greenMetroStations.includes(metroStation)) {
      return <img src={mGreen} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
    }
    if (blueMetroStations.includes(metroStation)) {
      return <img src={mBlue} alt="" width={15} height={15} style={{verticalAlign: 'middle'}} />
    }
  }

  getAmenityNameAndTime = (amenityType) => {
        const {  place, amenityRoutes, amenities, amenityLocations, experiment } = this.props

        const amenityRoute = amenityRoutes.find(amenityRoute => amenityRoute.amenity_id === amenities.find(amenity => amenity.name === amenityType).id && amenityRoute.place_id === place.id)
        const amenityLocation = amenityRoute ? amenityLocations.find(amenityLocation => amenityLocation.amenity_id === amenityRoute.amenity_id && amenityLocation.place_id === amenityRoute.amenity_location_place_id) : null
        const name = amenityRoute && amenityLocation ? amenityLocation.name : ''
        const footTime = amenityRoute && amenityLocation ? Math.round(amenityRoute.round_trip_foot_time_distance/2/60) : -1
        const carTime = amenityRoute && amenityLocation ? Math.round(amenityRoute.round_trip_car_time_distance/2/60) : -1
        const count = amenityRoute && amenityLocation ? amenityRoute.nearest_amenity_locations_euclidean_distances.filter(x => x < 1000).length : -1
        const costs = amenityRoute && experiment.costs['amenities'].find(computedAmenities => computedAmenities.amenity_route_id === amenityRoute.id)

        const mode = amenityRoute && costs['best_transportation_mode_forward_if_driving_is_an_option']
        const totalCostsOneWay = amenityRoute && costs['best_transportation_mode_forward_time_costs_if_driving_is_an_option']
        const useUber = amenityRoute && experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car').value === 'to sell'

        const time = mode === 'driving' ? carTime : footTime
        return {name,
                totalCostsOneWay: totalCostsOneWay,
                time: time,
                timeLabel: `${time} мин${mode === 'driving' ? `(${useUber ? 'транспорт' : 'машина'})` : ''}`,
                count}
    }

    getPlaceSearchNameTimeAndMode = (placeSearch) => {
      const { experiment, places } = this.props
      const placeTarget = places.find(place => placeSearch.place_id === place.id)
      const costs = placeTarget && experiment.costs['exact_places'].find(computedPlaceSearch => computedPlaceSearch.place_search_id === placeSearch.id)
      const mode = placeTarget && costs && costs['best_transportation_mode_forward_if_driving_is_an_option']
      const footTime = placeTarget && costs && Math.round(costs['forward_foot_time_distance']/60)
      const carTime = placeTarget && costs && Math.round(costs['forward_car_time_distance']/60)
      const useUber = placeTarget && experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car').value === 'to sell'

      return {name: placeTarget && placeTarget.full_address.substring(0,25),
              timeLabel: mode === 'driving' ? `${carTime} (${useUber ? 'транспорт' : 'машина'})` : footTime,
              mode}
    }

    render() {
    const { placeSearches, experiment } = this.props
    const novusOneWayCosts = this.getAmenityNameAndTime('Novus').totalCostsOneWay
    const silpoOneWayCosts = this.getAmenityNameAndTime('Сiльпо').totalCostsOneWay
    const shouldShowNovus = novusOneWayCosts <= silpoOneWayCosts
    const shouldShowSilpo = silpoOneWayCosts <= novusOneWayCosts
// ============================ FROM HERE
    // const shouldShowGrocery = this.getAmenityNameAndTime('Супермаркет').totalCostsOneWay < Math.min(novusOneWayCosts, silpoOneWayCosts)

    // const restaurantOneWayCosts = this.getAmenityNameAndTime('Ресторан').totalCostsOneWay
    // const cafeOneWayCosts = this.getAmenityNameAndTime('Кафе').totalCostsOneWay
    
    // const restaurantCafeTimeLabel = restaurantOneWayCosts < cafeOneWayCosts ? this.getAmenityNameAndTime('Ресторан').timeLabel : this.getAmenityNameAndTime('Кафе').timeLabel
    // const shouldShowShoppingCenter = this.getAmenityNameAndTime('Популярный торговый центр').totalCostsOneWay < this.getAmenityNameAndTime('Один из самых популярных торговых центров').totalCostsOneWay 
    // const shouldShowPark = this.getAmenityNameAndTime('Парк').totalCostsOneWay < this.getAmenityNameAndTime('Популярное место для прогулок').totalCostsOneWay
// ============================ TO HERE

    return(
          <span>

          {placeSearches.filter(ps => ps.is_activated).filter(ps => ps.round_trips_per_year > 0).map(placeSearch => {
            return <span key={`${placeSearch.id}-${experiment.id}`} className={`hiuhihuihihuiuhihiu_css_class ${this.getPlaceSearchNameTimeAndMode(placeSearch).mode === 'driving' ? 'driving' : 'pedestrian'}`}>
              <span className='miniseparator'>•</span><span>{this.getPlaceSearchNameTimeAndMode(placeSearch).name}: {this.getPlaceSearchNameTimeAndMode(placeSearch).timeLabel} мин</span>
            </span>
          })}

          <span className='miniseparator'>•</span><SubwayLineIcon name={this.getAmenityNameAndTime('Метро').name}></SubwayLineIcon>{this.getAmenityNameAndTime('Метро').name.split(' ').map(word => word.substring(0, 12)).join(' ')}: {this.getAmenityNameAndTime('Метро').timeLabel}

          {shouldShowSilpo && <span>
            <span className='miniseparator'>•</span>{this.getAmenityNameAndTime('Сiльпо').name}: {this.getAmenityNameAndTime('Сiльпо').timeLabel}
          </span>}

          {shouldShowNovus && <span>
            <span className='miniseparator'>•</span>{this.getAmenityNameAndTime('Novus').name}: {this.getAmenityNameAndTime('Novus').timeLabel}
          </span>}

      </span>
    )
  }
}




// {/* <div className="apartment-item-highlights">
// <h4>Рекомендовано</h4>

// {placeSearches.filter(ps => ps.is_activated).filter(ps => ps.round_trips_per_year > 0).map(placeSearch => {
//   return <div key={`${placeSearch.id}-${experiment.id}`} className={`apartment-item-badge ${this.getPlaceSearchNameTimeAndMode(placeSearch).mode === 'driving' ? 'driving' : 'pedestrian'}`}>
//     <div>{this.getPlaceSearchNameTimeAndMode(placeSearch).name}<br/>{this.getPlaceSearchNameTimeAndMode(placeSearch).timeLabel} мин<br/></div>
//   </div>
// })}

// <div className="apartment-item-badge subway">
// {/* style={{whiteSpace: 'nowrap', overflow: 'scroll'}} */}
// <div><SubwayLineIcon name={this.getAmenityNameAndTime('Метро').name}></SubwayLineIcon>{this.getAmenityNameAndTime('Метро').name.split(' ').map(word => word.substring(0, 12)).join(' ')}<br/>{this.getAmenityNameAndTime('Метро').timeLabel}<br/></div>
// </div>


// {/* <div className="apartment-item-badge bus">
//   <div>{this.getAmenityNameAndTime('Автобусная остановка').name}<br/>{this.getAmenityNameAndTime('Автобусная остановка').timeLabel}<br/></div>
// </div> */}

// {shouldShowSilpo && <div className="apartment-item-badge grocery">
//   <div>{this.getAmenityNameAndTime('Сiльпо').name}<br/>{this.getAmenityNameAndTime('Сiльпо').timeLabel}<br/></div>
// </div>}

// {shouldShowNovus && <div className="apartment-item-badge grocery">
//   <div>{this.getAmenityNameAndTime('Novus').name}<br/>{this.getAmenityNameAndTime('Novus').timeLabel}<br/></div>
// </div>}


//  */}


















// ============================== FROM HERE

// {config.SHOW_STATS_DEBUG && shouldShowGrocery && <div className="apartment-item-badge grocery">
// <div>{this.getAmenityNameAndTime('Супермаркет').name}<br/>{this.getAmenityNameAndTime('Супермаркет').timeLabel}<br/></div>
// </div>}

// <div className="apartment-item-badge grocery">
// <div>{this.getAmenityNameAndTime('Один из самых популярных торговых центров').name}<br/>{this.getAmenityNameAndTime('Один из самых популярных торговых центров').timeLabel}<br/></div>
// </div>

// {config.SHOW_STATS_DEBUG && shouldShowShoppingCenter && <div className="apartment-item-badge grocery">
// <div>{this.getAmenityNameAndTime('Популярный торговый центр').name}<br/>{this.getAmenityNameAndTime('Популярный торговый центр').timeLabel}<br/></div>
// </div>}

// {/* <div className="apartment-item-badge restaurant">
// <div>ресторанов<br/>вокруг ({this.getAmenityNameAndTime('Ресторан').count})<br/>от {this.getAmenityNameAndTime('Ресторан').timeLabel}<br/></div>
// </div>

// <div className="apartment-item-badge restaurant">
// <div>кафе<br/>вокруг ({this.getAmenityNameAndTime('Кафе').count})<br/>от {this.getAmenityNameAndTime('Кафе').timeLabel}<br/></div>
// </div> */}

// {config.SHOW_STATS_DEBUG && <div className="apartment-item-badge restaurant">
// <div>
//     ресторанов({this.getAmenityNameAndTime('Ресторан').count})<br/>
//     кафе({this.getAmenityNameAndTime('Кафе').count})<br/>
//     от {restaurantCafeTimeLabel}<br/></div>
// </div>}

// {config.SHOW_STATS_DEBUG && shouldShowPark && <div className="apartment-item-badge park">
// <div>{this.getAmenityNameAndTime('Парк').name.substring(0,24)}<br/>{this.getAmenityNameAndTime('Парк').timeLabel}<br/></div>
// </div>}

// {config.SHOW_STATS_DEBUG && <div className="apartment-item-badge pedestrian">
// <div>{this.getAmenityNameAndTime('Популярное место для прогулок').name.substring(0,24)}<br/>{this.getAmenityNameAndTime('Популярное место для прогулок').timeLabel}<br/></div>
// </div>}

// {/* ({this.getAmenityNameAndTime('Тренажерный зал').count}) */}
// {config.SHOW_STATS_DEBUG && <div className="apartment-item-badge pedestrian">
// <div>фитнес от {this.getAmenityNameAndTime('Тренажерный зал').timeLabel}<br/>{this.getAmenityNameAndTime('Бассейн').name.substring(0,14)}<br/>{this.getAmenityNameAndTime('Бассейн').timeLabel}<br/></div>
// </div>}

// ============================== TO HERE










        //   {/* <div className="apartment-item-badge subway">
        //     <div>A/E (metro) - 8 min<br/>161/164/195 (bus) - 7 min</div>
        //   </div> */}
        //           {/* <div className="apartment-item-badge bus">
        //     <div>1/20/7 (bus) - 1 min<br/>10/110/5 (bus) - 2 min</div>
        //   </div> */}
        
        //   {/* <div className="apartment-item-badge bus neg">
        //     <div>Limited nearby<br/>public transit</div>
        //   </div> */}
        //   {/* <div className="apartment-item-badge noise">
        //     <div>In a quiet<br/>neighborhood</div>
        //   </div> */}