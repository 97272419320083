import axios from 'axios'
import config from '../../config'
import { SET_LISTING }  from '../../actions/experimentSearches'

const HOST = config.HOST

export const FETCH_CREATE_REQUEST_START = 'editListingModal/FETCH_CREATE_REQUEST_START'
export const FETCH_CREATE_REQUEST_SUCCESS = 'editListingModal/FETCH_CREATE_REQUEST_SUCCESS'
export const FETCH_CREATE_REQUEST_ERROR = 'editListingModal/FETCH_CREATE_REQUEST_ERROR'

const initialState = {
    isFetch: false,
    isFetchRequest: false,
    error: null,
    isRequestSuccesful: false,
    user: null
  }

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CREATE_REQUEST_START:
            return {...state, isFetchRequest: true, error: null, isRequestSuccesful: false}
        case FETCH_CREATE_REQUEST_SUCCESS:
            return {...state, isFetchRequest: false, isRequestSuccesful: true}
        case FETCH_CREATE_REQUEST_ERROR:
            return {...state, isFetchRequest: false, error: action.payload, isRequestSuccesful: false}
        default: return state
    }
}

export let cancel = null;

export const fetchEditListing = (listing_id, request) => (dispatch, getState) => {
  dispatch({type: FETCH_CREATE_REQUEST_START})
  axios.put(`${HOST}api/listings/${listing_id}/`, request).then((response) => {
    dispatch({ type: SET_LISTING, payload: response.data })
    dispatch({type: FETCH_CREATE_REQUEST_SUCCESS})
  }
  )
    .catch(error => dispatch({type: FETCH_CREATE_REQUEST_ERROR, payload: error}))
}
