import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'

import * as actions from '../../actions/experimentSearches'

import ExperimentItem from '../ExperimentItem'
import OneLineExperiment from '../OneLineExperiment'

import FineTunePreferences from '../../components/FineTunePreferences'
import SignIn from '../../components/SignIn'
import Email from '../../components/Email'
import FreezeContext from '../../components/FreezeContext'
import SharePage from '../../components/SharePage'
import ExecutionStats from '../../components/ExecutionStats'
import ExcludedExperiment from '../../components/ExcludedExperiment'
import CheckoutRentGuide from '../../components/CheckoutRentGuide'
import CreateRenterProfile from '../../components/CreateRenterProfile'
import AllExperimentsStatistics from '../../components/AllExperimentsStatistics'
import SuggestedExperimentsStatistics from '../../components/SuggestedExperimentsStatistics'

import CompareWithNextBestAlternativeList from '../../containers/CompareWithNextBestAlternativeList'
import AddApartmentsModal from '../AddApartmentsModal'
import config from '../../config'

import mapIcon from '../../assets/map.png'
import closeGreyIcon from '../../assets/closeGrey.svg'

const numberOfInitialExpandedExperiments = 60
const expandExperimentsPerStep = 60

class ExperimentList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showAddApartmentsModal: false,
      showOneLiners: false,
      showStatistics: false,
      showTools: false,
      isAgent: config.AGENT || window.location.hostname === "agent.imbadom.com" || window.location.hostname === "agent.wayleap.com",
      numberOfExpandedExperiments: numberOfInitialExpandedExperiments
    }
    this.scrollToElement = this.scrollToElement.bind(this);
  }

  showMoreExperiments() {
    this.setState({
      numberOfExpandedExperiments: this.state.numberOfExpandedExperiments + expandExperimentsPerStep
    })
  }

  toggleShowModal(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  scrollToElement(idItem){
    const id = idItem ? idItem : parseInt(sessionStorage.getItem('id'))
    const element = document.getElementById(id)

    if (element) {
      element.scrollIntoView({block: 'start'})
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.experimentItemIdToScroll !==  prevProps.experimentItemIdToScroll) {
      this.scrollToElement(this.props.experimentItemIdToScroll)
    }
  }

  render() {
    const experimentSearch = this.props.experimentSearches[0]
    const { showAddApartmentsModal, isAgent } = this.state
    const { toggleShowRight, isShowRight, setExperimentItemIdOnScroll, listings, suggestedExperiments } = this.props
    const isRental = experimentSearch.category_bias === 'For Rent'
    const DISPLAY_VALUE_BREAKDOWN = config.DISPLAY_VALUE_BREAKDOWN && ((window.location.hostname === "demo.imbadom.com") || (window.location.hostname === "demo.wayleap.com") || (window.location.hostname === "localhost") )

    return (
      <div className="experiment-list">
        {isAgent && config.SHOW_SHIT && <FreezeContext
          user={this.props.user}
          experimentSearch={experimentSearch}
          updateExperimentSearch={this.props.updateExperimentSearch}
          setExperimentSearch={this.props.setExperimentSearch}
          showAllMap={this.props.showAllMap}/>}

        {config.SHOW_SHIT && suggestedExperiments.length > 0 &&
          <SharePage showAllMap={this.props.showAllMap}/>}

        {suggestedExperiments.length > 0 && config.SHOW_SHIT && <CompareWithNextBestAlternativeList
          experimentSearch={experimentSearch}
          suggestedExperiments={suggestedExperiments}
          listings={listings}
          benchmarkListingId={experimentSearch ? experimentSearch.benchmark_listing_id : null}
          toggleShowModal={()=>this.toggleShowModal('showAddApartmentsModal')}
          showAllMap={this.props.showAllMap}
          />}

        {suggestedExperiments.length > 0 &&
          <ExecutionStats
            results_count={suggestedExperiments.length}
            execution_time={experimentSearch.execution_time}
            relevant_suggestions_count={experimentSearch.results_count}
            showAllMap={this.props.showAllMap}/>
        }

        {suggestedExperiments.length > 0 && config.SHOW_SHIT && experimentSearch && experimentSearch.new_listing_ids && experimentSearch.new_listing_ids.length > 0 && <div className='middle-block_row-wrap'>
          Новые рекомендации: {suggestedExperiments.filter(({listing_id}) => experimentSearch.new_listing_ids.map(Number).includes(Number(listings.find(({id}) => id === listing_id).group_id))).map(a => a.rank).join(', ')} подсвечены желтым цветом.
        </div>}

        {suggestedExperiments.length > 0 && config.SHOW_SHIT && experimentSearch && experimentSearch.new_listing_ids && experimentSearch.new_listing_ids.length === 0 && <div className='middle-block_row-wrap'>
          Новых рекомендаций нет.
        </div>}

        <div className=''>

          {!this.props.isShowRight && config.SHOW_SHIT && suggestedExperiments.length > 0 &&
 <Button  

 style={{maxHeight: '20px'}}
 variant="contained"
 color="default"
 onClick={() => toggleShowRight()}
>
            <img width={25} height={25} src={mapIcon} alt=""/>
            <span className='shine-animation'>{/*I18n.t('experimentList.showOnMap')*/}Показать на карте</span>
          </Button>}

          {DISPLAY_VALUE_BREAKDOWN && suggestedExperiments.length > 0 && <span 
            // className='request-tour_send-btn'
            style={{padding: "5px 5px", maxHeight: "20px", cursor: 'pointer'}}
            variant="contained"
            color="default"
            onClick={() => {this.toggleShowModal('showTools')}}
            >
              {this.state.showTools ? 'скрыть инструменты' : 'инструменты'}
        </span>}

        {false && suggestedExperiments.length > 0 && <Email
          style={{display: 'inline-block'}}
          user={this.props.user}
          experimentSearch={experimentSearch}
          setExperimentSearch={this.props.setExperimentSearch}
          showAllMap={this.props.showAllMap}/>}

        </div>







        {this.state.showTools && <Button  
            className='request-tour_send-btn'
            style={{padding: "5px 5px"}}
            variant="contained"
            color="default"
            onClick={() => {this.toggleShowModal('showStatistics')}}
        >
          {this.state.showStatistics ? 'скрыть статистику' : 'статистика'}
        </Button>}

        {suggestedExperiments.length > 0 && this.state.showStatistics && experimentSearch.all_experiments_statistics && <div className='middle-block_row-wrap'>
          <AllExperimentsStatistics
            all_experiments_statistics={experimentSearch.all_experiments_statistics}
            category_bias={experimentSearch.category_bias}
            showAllMap={this.props.showAllMap}/>
        </div>}


        {suggestedExperiments.length > 0 && this.state.showStatistics && experimentSearch.all_experiments_statistics && <div className='middle-block_row-wrap'>
          <SuggestedExperimentsStatistics
            suggested_experiments_statistics={experimentSearch.suggested_experiments_statistics}
            all_experiments_statistics={experimentSearch.all_experiments_statistics}
            results_count={experimentSearch.results_count}
            category_bias={experimentSearch.category_bias}
            expanded={true}
            showAllMap={this.props.showAllMap}/>
        </div>}


        {this.state.showTools && <div><Button  
            className='request-tour_send-btn'
            style={{padding: "5px 5px"}}
            variant="contained"
            color="default"
            onClick={() => {this.toggleShowModal('showOneLiners')}}
            >

            {this.state.showOneLiners ? 'скрыть компактные результаты' : 'компактные результаты'}
            </Button>
        <div>

        {suggestedExperiments.length > 0 && this.state.showOneLiners &&
          suggestedExperiments.sort((a, b) => a.rank - b.rank).map((experiment, i) => {
            const listing =listings.find((listing) => listing.id === experiment.listing_id)
            const isNew = experimentSearch.new_listing_ids && experimentSearch.new_listing_ids.map(Number).includes(Number(listing.group_id))
            if (!listing){
              return <div key={experiment.id}></div>
            }


          return (<OneLineExperiment
                key={experiment.id}
                experimentId={experiment.id}
                dataId={experiment.id}
                lastId={suggestedExperiments[`${suggestedExperiments.length-1}`].id}
                scrollToElement={this.scrollToElement}
                experimentListItem={experiment}
                isNew={isNew}
                toggleShowRight={toggleShowRight}
                experiment={experiment}
                setExperimentItemIdOnScroll={setExperimentItemIdOnScroll}
                isShowRight={isShowRight}/>)
          })
        }
          </div>
        </div>}

        {suggestedExperiments.length > 0 &&
          suggestedExperiments.sort((a, b) => a.rank - b.rank).map((experiment, i) => {
            const listing =listings.find((listing) => listing.id === experiment.listing_id)
            const isNew = experimentSearch.new_listing_ids && listing && experimentSearch.new_listing_ids.map(Number).includes(Number(listing.group_id))
            if (!listing){
              return <div key={experiment.id}></div>
            }

            if (i >= this.state.numberOfExpandedExperiments) {
                        return <div key={i}></div>
            } else {

          if (experimentSearch.excluded_listing_ids && experimentSearch.excluded_listing_ids.map(Number).includes(Number(listing.group_id))) {
            return (
              <ExcludedExperiment
                key={experiment.id}
                experiment={experiment}
                experimentSearch={experimentSearch}
                setExperimentSearch={this.props.setExperimentSearch}
                updateExperimentSearch={this.props.updateExperimentSearch}
              />
            )
          } else {
            return (
              <ExperimentItem
                key={experiment.id}
                experimentId={experiment.id}
                dataId={experiment.id}
                lastId={suggestedExperiments[`${suggestedExperiments.length-1}`].id}
                scrollToElement={this.scrollToElement}
                experimentListItem={experiment}
                isNew={isNew}
                toggleShowRight={toggleShowRight}
                experiment={experiment}
                setExperimentItemIdOnScroll={setExperimentItemIdOnScroll}
                isShowRight={isShowRight}
                />
             )}

  }

          })
        }

{ (suggestedExperiments.length > this.state.numberOfExpandedExperiments) &&
  <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap' style={{cursor: 'pointer'}} onClick={() => this.showMoreExperiments()} >
    <span className='shine-animation' style={{fontSize: '36px'}}>Показать&nbsp;больше&nbsp;> </span>
  </div>}



        {false && <CheckoutRentGuide
          experimentSearch={experimentSearch}
          showAllMap={this.props.showAllMap}/>}


        {false && isRental && <CreateRenterProfile
          experimentSearch={experimentSearch}
          showAllMap={this.props.showAllMap}/>}

        {false && <SignIn
          experimentSearch={experimentSearch}
          showAllMap={this.props.showAllMap}/>}



        {showAddApartmentsModal &&
          <div className='request-modal'>
            <div className='request-tour request-tour-mobile'>
              <img src={closeGreyIcon} alt="" className='modal-close-btn' onClick={()=>this.toggleShowModal('showAddApartmentsModal')} />
              <div>
                <AddApartmentsModal
                  toggleShowModal={()=>this.toggleShowModal('showAddApartmentsModal')}
                  setExperimentSearch={this.props.setExperimentSearch}
                />
              </div>
            </div>
          </div>
         }

        {false && suggestedExperiments.length > 0 && !isAgent &&
          <FineTunePreferences
            experimentSearch={experimentSearch}
            showAllMap={this.props.showAllMap}/>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  state: state,
  listings: state.listings,
  experimentSearches: state.experimentSearches,
  suggestedExperiments: state.suggestedExperiments,
  i18n: state.i18n,
  user: state.user,
})

export default connect(mapStateToProps, { ...actions })(ExperimentList)
