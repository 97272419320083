import React from 'react'
import ReactHoverObserver from 'react-hover-observer'

export default function HowerObserver(InputComponent) {
  return class extends React.Component {
    render() {
      return(
        <ReactHoverObserver>
          {({ isHovering }) => (
            <InputComponent isHovering={isHovering} {...this.props}/>
          )}
        </ReactHoverObserver>
      )
    }
  }
}
