import React, {Component} from 'react'
import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'
import ShareIcon from '@material-ui/icons/Share'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'
import config from '../../config'

// const language = window.navigator.languages.some(item => ['ru', 'ua', 'ru-RU', 'ru-UA', 'uk-UA', 'be-BY' ].includes(item)) ? config.LANGUAGE.RU : config.LANGUAGE.ENG
const language = config.LANGUAGE.RU

class SharePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false,
    }
  }
  copyToClipboard = () => {
    var textField = document.createElement('textarea')
    textField.innerText = language === 'ru-RU' ? `Смотри! Вот мой поиск квартиры на Wayleap: ${window.location}` : `Look! It's my apartments search on Wayleap: ${window.location}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.setState({ isCopied: true})
  }

  render() {
    const { isCopied } = this.state

    return <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
      <div>
        <Translate value='experimentSearch.sharePage' />
      </div>

      <div className='call-buttons'>
        <div className=''>

          <Button onClick={this.copyToClipboard} style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="primary" className=''>
            <ShareIcon />
            <Translate value='experimentItem.share'/>&nbsp;>
          </Button>

          {isCopied && <div style={{minHeight: '30px'}}>cсылка скопирована</div>}

        </div>
    </div>
  </div>
  }
}
export default howerObserver(togleShowAllMap(SharePage))
