import React, {Component} from 'react'
import { I18n } from 'react-redux-i18n'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
const values = [
  "Куреневка", "Чёрная гора", "Новое строение",
  "Шулявка", "Виноградарь", "Чоколовка", "Троещина", "Харьковский", "Воскресенка", "Лукьяновка", "Оболонь", "Минский", "КПИ",
  "Голосеево", "Нижний Печерск", "Татарка", "Сырец", "Подол", "Позняки", "Лесной", "Исторический центр", "Осокорки", "Солдатская слободка",
  "Беличи", "Соломенка", "Русановка", "Нивки", "Борщаговка", "Отрадный", "Липки", "Теремки-2", "Святошино", "Зверинец", "Старая Дарница",
  "Демеевка", "Академгородок", "Новобеличи", "Никольская Слободка", "Черепанова гора", "Саперная Слободка", "Ветряные Горы", "Березняки",
  "Печерск", "Александровская Слободка", "Северо-Броварской (Комсомольский)", "Жуляны", "Радужный", "Соцгород", "Никольская Борщаговка",
  "Теремки-1", "Кудрявец", "Первомайский массив", "Новая Дарница", "Караваевы дачи", "Корчеватое", "Левобережный массив", "Кадетский Гай",
  "Галаганы", "Феофания", "Теремки", "ДВРЗ", "Бортничи", "Вышгородский массив "
]

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectMenu: {
    minWidth: 170,
    maxWidth: 170
  }
})


class MicrodistrictsSelect extends Component {
  constructor(props) {
      super(props)
      this.state = {
       selected: this.props.experimentSearch.preferred_microdistricts || [],
       open: false
      }
  }

  handleClose = () => {
    const { selected } = this.state
    const { experimentSearch, setExperimentSearch } = this.props
    // updateExperimentSearch

    this.setState({open: false})
    const newExperimentSearch = {
      ...experimentSearch,
      preferred_microdistricts: selected
    }
    setExperimentSearch(newExperimentSearch)
    // updateExperimentSearch(newExperimentSearch)
  }

  handleOpen = () => {
    this.setState({open: true})
  }

  render(){
    const { classes } = this.props
    // const { selected = [] } = this.props.experimentSearch.preferred_districts
    const { open, selected } = this.state

    return (
    <div className='infrastructure-info pagination-row'>
      <span className='price-select'>
        {I18n.t('experimentSearch.microdistricts')}
        <Select
          multiple
          autoWidth={true}
          value={selected.length > 0 ? selected : ['Все микрорайоны']}
          classes={classes}
          onChange={(e) => {
            const newSelected = e.target.value.filter(item => item !== 'Все микрорайоны')
            this.setState({selected: newSelected})
          }}
          open={open}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(', ')}
        >
        {values.map(item => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={selected.indexOf(item) > -1} style ={{color: "grey"}}/>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
        </Select>
      </span>
    </div>
  )
 }
}
export default withStyles(styles)(MicrodistrictsSelect)
