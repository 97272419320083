const config = {
  HOST: 'https://backend.wayleap.com/',
  // googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCBY7MpGGnwjZo7ScKZMW3iXGFYeJzD6cs',
  googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB7UKCm13yxn48fwTWcA7eBKjcQTMyvMeY&language=ru',
  SHOW_FOR_WHOM_IT_MAY_BE_THE_BEST_OPTION: false,
  LOAD_EXPERIMENTS_ON_INITIAL_PAGE_LOAD: false,
  ALWAYS_SHOW_SUPPORT_PHONE_NUMBER: false,
  SHOW_CHANGE_LANGUAGE: false,
  SHOW_LIKE_BUTTON: false,
  IS_ONLINE: true,
  SHOW_CONSEQUENT_CURRENCY_SELECTS: false,
  ASK_FOR_STATUS_BRACKET_BIAS: false,
  SHOW_LINKS: true,
  SHOW_LONG_EXPLANATIONS: false,
  LOAD_TEST_EXPERIMENT_SEARCH: false,
  TEST_EXPERIMENT_SEARCH_ID: 1,
  DEBOUNCE_MILLISECONDS: 250,
  ASK_WHAT_ARE_AVAILABLE_SAVINGS: false,
  SHOW_NEARBY_AMENITY_COUNT: false,
  SHOW_NOT_IMPLEMENTED_FEATURES: false,
  ARRAY_OF_SUPPORTED_CITIES: ['Moscow', 'St Petersburg', 'Kiev'],
  // ARRAY_OF_SUPPORTED_CITIES: ['Kiev'],
  // CURRENCIES: [ 'UAH', 'RUB', 'USD' ],
  CURRENCIES: [ 'UAH', 'USD' ],
  DEFAULT_CURRENCY: 'UAH',
  AGENT: false,
  SHOW_STATS_DEBUG: false,
  DISPLAY_STANDARD_DEVIATIONS: false,
  DISPLAY_PRIVATE_AMENITY_MATCHES: false,
  DISPLAY_VALUE_BREAKDOWN: true,
  SHOW_TRUNCATED_DESCRIPTIONS: true,
  ALWAYS_DISPLAY_WHAT_OTHERS_WANT: true,

  SHOW_FORMS: false,
  SHOW_DESCRIPTION: true,
  SHOW_SHIT: false,
  SHOW_INDIVIDUAL_LISTING: false, //17.20.2020 added
  SHOW_INFO: false,
  CURRENCY_SIGNS: {
    'UAH': 'грн',
    'USD': '$',
    'RUB': '₽'
  },
  MIN_SEARCH_PRICE: {
    UAH: 7000,
    USD: 250,
    RUB: 15000,
  },
  LINK_WEBSITES_LIST: 'https://docs.google.com/spreadsheets/d/1EC5uelc1ZgjaValPUcTAQLvQGoYBL6N4kuXaPomv4PI/',
  LANGUAGE: {
    // ENG: 'en-US',
    RU: 'ru-RU'
  },
  DEFAULT_FILTER_AMENITYS_NAMES: [
    'Popular place to walk',
    'Subway',
    'Supermarket',
  ]
}

export default config
