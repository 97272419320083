import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { I18n } from 'react-redux-i18n'

const data = [
  [
    'Красная линия',
    'Академгородок',
    'Житомирская',
    'Святошин',
    'Нивки',
    'Берестейская',
    'Шулявская',
    'Политехнический институт',
    'Вокзальная',
    'Университет',
    'Театральная',
    'Крещатик',
    'Арсенальная',
    'Днепр',
    'Гидропарк',
    'Левобережная',
    'Дарница',
    'Черниговская',
    'Лесная'
  ],
  [
    'Синяя линия',
    'Героев Днепра',
    'Минская',
    'Оболонь',
    'Почайна',
    'Тараса Шевченко',
    'Контрактовая площадь',
    'Почтовая площадь',
    'Майдан Независимости',
    'Площадь Льва Толстого',
    'Олимпийская',
    'Дворец Украина',
    'Лыбедская',
    'Демиевская',
    'Голосеевская',
    'Васильковская',
    'Выставочный центр',
    'Ипподром',
    'Теремки'
  ],
  [
    'Зеленая линия',
    'Сырец',
    'Дорогожичи',
    'Лукьяновская',
    'Золотые ворота',
    'Дворец спорта',
    'Кловская',
    'Печерская',
    'Дружбы народов',
    'Выдубичи',
    'Славутич',
    'Осокорки',
    'Позняки',
    'Харьковская',
    'Вырлица',
    'Бориспольская',
    'Красный хутор'
  ]
]

const lineName = [
  'Красная линия',
  'Синяя линия',
  'Зеленая линия'
]

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectMenu: {
    minWidth: 170,
    maxWidth: 170
  }
})

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 750,
      zIndex: 8,
      position: 'absolute',
      overflow: 'auto',
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: 2,
      backgroundColor: '#fff',
    },
  },
}


class MetroSelect extends Component {
  constructor(props) {
      super(props)
      this.state = {
       selected: [],
       selectedValues: [],
       open: false

      }
  }
  componentDidUpdate(prevProps) {
    if (this.props.experimentSearch !== prevProps.experimentSearch) {
      const { preferred_subway_stations = [] } = this.props.experimentSearch
      let newSelected
      let newSelectedValues
      let result = []
      if ( preferred_subway_stations && preferred_subway_stations[0] === 'Все станции' ) {
        newSelected = [...data.reduce((c, v) => c.concat(v), []), 'Все станции' ]
        newSelectedValues = preferred_subway_stations
      }
      if (preferred_subway_stations) {
        result = [0, 1, 2].reduce((acc, value) => {
          if ( data[value].filter(item => item !==lineName[value]).every(item => preferred_subway_stations.includes(item)) ) {
            acc = [...acc, lineName[value] ]
          }
          return acc
        }, preferred_subway_stations)
      }

      if (result.length > preferred_subway_stations.length) {
        newSelected = [...result ]
        newSelectedValues = preferred_subway_stations
      } else {
        newSelected = preferred_subway_stations
        newSelectedValues = preferred_subway_stations
      }
        this.setState({selected: newSelected ? newSelected : [], selectedValues: newSelectedValues ? newSelectedValues : [] })
    }
  }

  handleClose = () => {
   const { selected, selectedValues } = this.state
   const { experimentSearch, setExperimentSearch } = this.props
   // updateExperimentSearch

   this.setState({open: false})
   const newExperimentSearch = {
     ...experimentSearch,
     preferred_subway_stations: selectedValues.length > 0 ? selectedValues : selected
   }
   setExperimentSearch(newExperimentSearch)
   // updateExperimentSearch(newExperimentSearch)
  }

   handleOpen = () => {
     this.setState({open: true})
   }

  handleClick = (e) => {
    const { selected } = this.state
    const isInSelected = selected.indexOf(e) > -1
    let newSelected
    let newSelectedValues

    if (e === 'Все станции' && isInSelected) {
      newSelected = []
    } else if (e === 'Все станции' && !isInSelected) {
      newSelected = [ e, ...data.reduce((c, v) => c.concat(v), [])]
      newSelectedValues = [ e ]
    } else if (!isInSelected) {
      newSelected = [...selected, e]
    } else if (isInSelected) {
      let newSecondSelected
      [0, 1, 2].forEach(index => {
        if (data[index].includes(e)) {
        newSecondSelected = selected.filter(item => item !== lineName[index])
      }})
      if (selected.includes('Все станции')) {
        newSelected = newSecondSelected.filter(item => item !== 'Все станции' && item !== e)
      } else {
        newSelected = newSecondSelected.filter(item => item !== e)
      }
    }
    [0, 1, 2].reduce((acc,item) => {
      if (e === lineName[item] && isInSelected) {
        newSelected =  acc.filter(index => !(data[item].includes(index)) && index !=='Все станции')
        newSelectedValues = [ ...acc.filter(index => !data[item].includes(index) && !lineName.filter(index => index !== lineName[item]).concat('Все станции').includes(index))]
      } else if (e === lineName[item] && !isInSelected) {
        newSelected = [ ...acc.filter(index => !(data[item].includes(index))), ...data[item]]
        newSelectedValues = [ ...acc.filter(index => !data[item].includes(index) && !lineName.filter(index => index !== lineName[item]).includes(index)), ...data[item].filter(index => index !== lineName[item])]
      }
      return newSelected ? newSelected : acc
    }, selected )
    if ( newSelected && newSelected.length === 55) {
      newSelectedValues = ['Все станции']
      newSelected = [ ...newSelected, 'Все станции']
    }
    this.setState({selected: newSelected, selectedValues: newSelectedValues ? newSelectedValues : newSelected})
  }

  render() {
    const { classes } = this.props
    const { selected, selectedValues, open } = this.state
    const rowOrColumn = window.innerWidth > 750 ? 'flex' : ''

    return (
      <div className='infrastructure-info pagination-row'>
        <span className='price-select'>
        <div style ={{width: '140px'}}>{I18n.t('experimentSearch.metroStations')}</div>
          <Select
            multiple
            autoWidth={true}
            value={selectedValues.length > 0 ? selectedValues : ['Все станции метро']}
            classes={classes}
            onChange={this.handleChange}
            input={<Input id="select-multiple-checkbox" />}
            renderValue={selected => selected.join(', ')}
            MenuProps={MenuProps}
            open={open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
          >
          <span>
            <MenuItem key={'all'} value={'Все станции'} onClick={()=>{this.handleClick('Все станции')}} >
              <Checkbox checked={selected.indexOf('Все станции') > -1}  style ={{color: 'grey'}}/>
              <ListItemText primary={'Все станции'} />
            </MenuItem>

            <div style={{display:`${rowOrColumn}`, width: '800'}}>
              {data.map((station, i) =>{
                const checkBoxColor = i === 0 ? '#E33026' : i === 1 ? '#2685C6' : '#32B460'
                return <div style={{flexDirection:'row', width: '230px'}} key={station}>
                  {station.map(item => {
                    const renderedItem = (lineName.includes(item)) ? <Typography style={{fontSize: '18px' }}>{item}</Typography> : item
                    return <MenuItem key={item} value={item} onClick={()=>this.handleClick(item)} style ={{paddingTop: '5px', paddingBottom: '5px'}}>
                      <Checkbox checked={selected.indexOf(item) > -1} style ={{color: `${checkBoxColor}`}}/>
                      <ListItemText primary={renderedItem}/>
                    </MenuItem>
                  })}
                </div>})}
              </div>
            </span>
          </Select>
        </span>
      </div>
    )
  }
}
export default withStyles(styles)(MetroSelect)
