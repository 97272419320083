import React from 'react'
import config from '../../config'
import { I18n } from "react-redux-i18n"
import walkIcon from '../../assets/walkIcon.svg'
import carIcon from '../../assets/carIcon.svg'
import expansionPanel from '../../components/ExpansionPanel'


function NearbyAmenities({amenityRoutes=[], amenities, classes, amenityLocations, amenityTrips=[], routeStops=[], suggestedTransportationModes=[], suggestionOnWhetherToBuyOrSellCar='to sell'}) {
  let selectedAmenityTrips = []

  if ((amenityRoutes.length !== 0) && (amenityTrips.length !== 0) && (amenities.length >= amenityTrips.length)) {
    const sortedAmenityRoutesIds = amenityRoutes.filter(amenityRoute => amenities.map(am => am.id).includes(amenityRoute.amenity_id)).map(item => {
      const amenity = amenities.find(record => record.id === item.amenity_id)

      const amenityTrip = amenityTrips.find(amenityTrip => amenityTrip.amenity_id === amenity.id)

      return {amenityTripRoundTripsPerYear: amenityTrip ? amenityTrip.round_trips_per_year : null, amenityRouteId: item.id}
    }).sort((a,b) => b.amenityTripRoundTripsPerYear - a.amenityTripRoundTripsPerYear)
    // }).filter(item => parseInt(item.amenityTripRoundTripsPerYear, 10)).sort((a,b) => b.amenityTripRoundTripsPerYear - a.amenityTripRoundTripsPerYear)
    const sortedAmenityRoutes = sortedAmenityRoutesIds.map(item => {
      const amenityRoute = amenityRoutes.find(amenityRoute => item.amenityRouteId === amenityRoute.id)
      const suggestedTransportationMode = suggestedTransportationModes.find(suggestedTransportationMode => item.amenityRouteId === suggestedTransportationMode.amenity_route_id)
      const bestTransportationMode = suggestedTransportationMode ? (suggestionOnWhetherToBuyOrSellCar === 'to buy' && suggestedTransportationMode.best_transportation_mode_forward_if_driving_is_an_option === 'driving' ? 'driving' : suggestedTransportationMode.best_transportation_mode_forward) : ''
      return {...amenityRoute, bestTransportationMode: bestTransportationMode}
    })

    let filteredAmenityRoutes
    if (sortedAmenityRoutes.length === 0) {
      const amenityRoutesIds = amenityRoutes.map(item => {
      const amenity = amenities.find(amenity => amenity.id === item.amenity_id)

      return {amenity: amenity, id: item.id}
    })
      const filteredAmenityRoutesIds = config.DEFAULT_FILTER_AMENITYS_NAMES.reduce((acc, amenityName) => {
         const filtered = amenityRoutesIds.filter(item => item.amenity.name_en === amenityName)
         return acc.concat(filtered)
      }, [] )
      filteredAmenityRoutes = filteredAmenityRoutesIds.map(item => amenityRoutes.find(amenityRoute => item.id === amenityRoute.id))
    }
    selectedAmenityTrips = sortedAmenityRoutes.length === 0 ? filteredAmenityRoutes : sortedAmenityRoutes
  }

  return (
    <div>
      <table className="table table-header-rotated">
       <thead>
         <tr>
           <th />
           <th />
           {config.SHOW_NEARBY_AMENITY_COUNT && <th>
               <div>
                 <span>объектов рядом</span>
               </div>
             </th>}
         </tr>
       </thead>
       <tbody>{(selectedAmenityTrips).map(amenityRoute => {
         const { amenity_id, forward_car_time_distance, forward_foot_time_distance, amenity_location_id} = amenityRoute
        //  console.log('amenity_id, forward_car_time_distance, forward_foot_time_distance, amenity_location_id', amenity_id, forward_car_time_distance, forward_foot_time_distance, amenity_location_id)
         const amenity = amenities.find(item => parseInt(item.id, 10) === amenity_id)
         const amenityLocation = amenityLocations.find(item => parseInt(item.id, 10) === amenity_location_id)
        //  console.log('amenityLocation', amenityLocation)
        //  console.log('amenityLocations', amenityLocations)
         const amenityLocationName = amenityLocation ? amenityLocation.name : ''
        //  console.log('amenityLocationName', amenityLocationName)
         const bestTransportationMode = amenityRoute.bestTransportationMode ? amenityRoute.bestTransportationMode : ''

         return (<tr key={amenityRoute.id}>
           <th className="row-header">{amenity.name}<span>{amenityLocationName || ''}</span></th>
           <td>
             {((!bestTransportationMode) || bestTransportationMode === 'foot' || amenity.name_uk === 'Школа') && <div className='td-row'>
               <img alt='' src={walkIcon} />
               {forward_foot_time_distance < 100000 ? (forward_foot_time_distance > 3600 ? `${Math.round(forward_foot_time_distance/360)/10} ${I18n.t('amenity.hours')}` : `${Math.round(forward_foot_time_distance/60)}\u00a0${I18n.t('amenity.minutes')}`) : '-'}
             </div>}
             {((!bestTransportationMode) || bestTransportationMode !== 'foot') && (amenity.name_uk !== 'Школа') && <div className='td-row'>
               <img alt='' src={carIcon} />&nbsp;{bestTransportationMode === 'driving' ? 'машина' : 'такси'}&nbsp;
               {forward_car_time_distance < 100000 ? (forward_car_time_distance > 3600 ? `${Math.round(forward_car_time_distance/360)/10} ${I18n.t('amenity.hours')}` : `${Math.round(forward_car_time_distance/60)}\u00a0${I18n.t('amenity.minutes')}`) : '-'}
             </div>}
           </td>
         </tr>)
       })}</tbody>
      </table>
    </div>
  )
}
export default expansionPanel(NearbyAmenities, 'Ближайшая инфраструктура')
