
export const loginInputs = [
    {
        "id": "login-name",
        "field": "name",
        "text": "Login:"
    },
    {
        "id": "login-password",
        "field": "password",
        "text": "Password:"
    }
]

export const images = [
    {
      original: 'http://lorempixel.com/1000/600/nature/1/',
      thumbnail: 'http://lorempixel.com/250/150/nature/1/',
    },
    {
      original: 'http://lorempixel.com/1000/600/nature/2/',
      thumbnail: 'http://lorempixel.com/250/150/nature/2/'
    },
    {
      original: 'http://lorempixel.com/1000/600/nature/3/',
      thumbnail: 'http://lorempixel.com/250/150/nature/3/'
    }
  ]
export const titles = {
      title: 'Квартира',
      rank: 'Рейтинг',
      source_title: 'Адрес',
      price: 'Цена',
      total_rooms: 'Количество комнат',
      square_meters: 'Жилая площадь',
      mark: 'Оценка Wayleap',
      hoursPerMonthInCommute: 'Время в дороге, час/мес',
      minutesPerWorkdayInCommute: 'Время в дороге, мин/день',
}
export const compareRules = {
      title: '',
      rank: 'less',
      source_title: '',
      price: 'less',
      total_rooms: 'more',
      square_meters: 'more',
      mark: 'more',
      hoursPerMonthInCommute: 'less',
      minutesPerWorkdayInCommute: 'less',
}

export const workdaysPerMonth = 365.25 * 5 / 84

export const pricesForRentMin = {
  USD:[
    {value: 200, label:'200'},
    {value: 250, label:'250'},
    {value: 300, label:'300'},
    {value: 350, label:'350'},
    {value: 400, label:'400'},
    {value: 450, label:'450'},
    {value: 500, label:'500'},
    {value: 550, label:'550'},
    {value: 600, label:'600'},
    {value: 650, label:'650'},
    {value: 700, label:'700'},
    {value: 750, label:'750'},
    {value: 800, label:'800'},
    {value: 850, label:'850'},
    {value: 900, label:'900'},
    {value: 950, label:'950'},
    {value: 1000, label:'1 000'},
    {value: 1200, label:'1 200'},
    {value: 1300, label:'1 300'},
    {value: 1400, label:'1 400'},
    {value: 1500, label:'1 400'},
    {value: 1600, label:'1 400'},
    {value: 1700, label:'1 400'},

  ],
  UAH:[
    {value: 6000, label:'6 000'},
    {value: 7000, label:'7 000'},
    {value: 8000, label:'8 000'},
    {value: 9000, label:'9 000'},
    {value: 10000, label:'10 000'},
    {value: 12000, label:'12 000'},
    {value: 14000, label:'14 000'},
    {value: 16000, label:'16 000'},
    {value: 18000, label:'18 000'},
    {value: 20000, label:'20 000'},
    {value: 22000, label:'22 000'},
    {value: 25000, label:'25 000'},
    {value: 30000, label:'30 000'},
    {value: 40000, label:'40 000'},
    {value: 60000, label:'60 000'},
    {value: 70000, label:'70 000'},
    {value: 90000, label:'90 000'}
  ]
}
export const pricesForRentMax = {
  USD:[
    {value: 300, label:'300'},
    {value: 350, label:'350'},
    {value: 400, label:'400'},
    {value: 450, label:'450'},
    {value: 500, label:'500'},
    {value: 550, label:'550'},
    {value: 600, label:'600'},
    {value: 650, label:'650'},
    {value: 700, label:'700'},
    {value: 750, label:'750'},
    {value: 800, label:'800'},
    {value: 850, label:'850'},
    {value: 900, label:'900'},
    {value: 950, label:'950'},
    {value: 1000, label:'1 000'},
    {value: 1200, label:'1 200'},
    {value: 1300, label:'1 300'},
    {value: 1400, label:'1 400'}
  ],
  UAH:[
    {value: 7000, label:'7 000'},
    {value: 8000, label:'8 000'},
    {value: 9000, label:'9 000'},
    {value: 10000, label:'10 000'},
    {value: 11000, label:'11 000'},
    {value: 12000, label:'12 000'},
    {value: 13000, label:'13 000'},
    {value: 14000, label:'14 000'},
    {value: 15000, label:'15 000'},
    {value: 16000, label:'16 000'},
    {value: 18000, label:'18 000'},
    {value: 20000, label:'20 000'},
    {value: 22000, label:'22 000'},
    {value: 25000, label:'25 000'},
    {value: 30000, label:'30 000'},
    {value: 35000, label:'35 000'},
    {value: 40000, label:'40 000'},
    {value: 45000, label:'45 000'},
    {value: 50000, label:'50 000'},
    {value: 60000, label:'60 000'},
    {value: 70000, label:'70 000'},
    {value: 90000, label:'90 000'},
    {value: 120000, label:'120 000'},
    {value: 180000, label:'180 000'},
    {value: 250000, label:'250 000'},
    {value: 500000, label:'500 000'}
  ]
}
export const pricesForSaleMin = {
  USD:[
    {value: 10000, label:'10 000'},
    {value: 12000, label:'12 000'},
    {value: 14000, label:'14 000'},
    {value: 16000, label:'16 000'},
    {value: 18000, label:'18 000'},
    {value: 20000, label:'20 000'},
    {value: 24000, label:'24 000'},
    {value: 26000, label:'26 000'},
    {value: 30000, label:'30 000'},
    {value: 35000, label:'35 000'},
    {value: 38000, label:'38 000'},
    {value: 40000, label:'40 000'},
    {value: 45000, label:'45 000'},
    {value: 50000, label:'50 000'},
    {value: 55000, label:'55 000'},
    {value: 60000, label:'60 000'},
    {value: 65000, label:'65 000'},
    {value: 70000, label:'70 000'}
  ],
  UAH:[
    {value: 200000, label:'200 000'},
    {value: 300000, label:'300 000'},
    {value: 400000, label:'400 000'},
    {value: 500000, label:'500 000'},
    {value: 600000, label:'600 000'},
    {value: 700000, label:'700 000'},
    {value: 800000, label:'800 000'},
    {value: 900000, label:'900 000'},
    {value: 1000000, label:'1 000 000'},
    {value: 1200000, label:'1 200 000'},
    {value: 1400000, label:'1 400 000'},
    {value: 1600000, label:'1 600 000'},
    {value: 1800000, label:'1 800 000'},
    {value: 2000000, label:'2 000 000'},
    {value: 2200000, label:'2 200 000'},
    {value: 2500000, label:'2 500 000'},
    {value: 3000000, label:'3 000 000'},
    {value: 4000000, label:'4 000 000'},
    {value: 6000000, label:'6 000 000'},
    {value: 7000000, label:'7 000 000'},
    {value: 9000000, label:'9 000 000'}
  ]
}
export const pricesForSaleMax = {
  USD:[
    {value: 12000, label:'12 000'},
    {value: 14000, label:'14 000'},
    {value: 16000, label:'16 000'},
    {value: 18000, label:'18 000'},
    {value: 20000, label:'20 000'},
    {value: 24000, label:'24 000'},
    {value: 26000, label:'26 000'},
    {value: 30000, label:'30 000'},
    {value: 40000, label:'40 000'},
    {value: 50000, label:'50 000'},
    {value: 60000, label:'60 000'},
    {value: 70000, label:'70 000'},
    {value: 80000, label:'80 000'},
    {value: 90000, label:'90 000'},
    {value: 100000, label:'100 000'},
    {value: 110000, label:'110 000'},
    {value: 120000, label:'120 000'},
    {value: 130000, label:'130 000'},
    {value: 140000, label:'140 000'},
    {value: 145000, label:'145 000'},
    {value: 150000, label:'150 000'},
    {value: 155000, label:'155 000'},
    {value: 160000, label:'160 000'},
    {value: 165000, label:'165 000'},
    {value: 170000, label:'170 000'}
  ],
   UAH: [
    {value: 700000, label:'700 000'},
    {value: 800000, label:'800 000'},
    {value: 900000, label:'900 000'},
    {value: 1000000, label:'1 000 000'},
    {value: 1100000, label:'1 100 000'},
    {value: 1200000, label:'1 200 000'},
    {value: 1300000, label:'1 300 000'},
    {value: 1400000, label:'1 400 000'},
    {value: 1500000, label:'1 500 000'},
    {value: 1600000, label:'1 600 000'},
    {value: 1800000, label:'1 800 000'},
    {value: 2000000, label:'2 000 000'},
    {value: 2200000, label:'2 200 000'},
    {value: 2500000, label:'2 500 000'},
    {value: 3000000, label:'3 000 000'},
    {value: 3500000, label:'3 500 000'},
    {value: 4000000, label:'4 000 000'},
    {value: 4500000, label:'4 500 000'},
    {value: 5000000, label:'5 000 000'},
    {value: 6000000, label:'6 000 000'},
    {value: 7000000, label:'7 000 000'},
    {value: 9000000, label:'9 000 000'},
    {value: 12000000, label:'1 2000 000'},
    {value: 18000000, label:'1 8000 000'},
    {value: 25000000, label:'2 500 000'},
    {value: 50000000, label:'5 0000 000'}
  ]
}
export const bedroomsMin = [
  {value: 0, label:'0+'},
  {value: 1, label:'1+'},
  {value: 2, label:'2+'},
  {value: 3, label:'3+'},
  {value: 4, label:'4+'},
  {value: 5, label:'5+'}
]
export const bedroomsMax = [
  {value: 1, label:'1'},
  {value: 2, label:'2'},
  {value: 3, label:'3'},
  {value: 4, label:'4'},
  {value: 5, label:'5'}
]
export const squareMetersMin = [
  {value: 30, label:'30+'},
  {value: 40, label:'40+'},
  {value: 50, label:'50+'},
  {value: 60, label:'60+'},
  {value: 70, label:'70+'},
  {value: 80, label:'80+'},
  {value: 90, label:'90+'},
  {value: 100, label:'100+'},
  {value: 110, label:'110+'},
  {value: 120, label:'120+'},
  {value: 130, label:'130+'},
  {value: 140, label:'140+'},
  {value: 150, label:'150+'},
  {value: 200, label:'200+'},
  {value: 300, label:'300+'},
  {value: 500, label:'500+'}
]
export const squareMetersMax = [
    {value: 30, label:'30'},
    {value: 40, label:'40'},
    {value: 50, label:'50'},
    {value: 60, label:'60'},
    {value: 70, label:'70'},
    {value: 80, label:'80'},
    {value: 90, label:'90'},
    {value: 100, label:'100'},
    {value: 110, label:'110'},
    {value: 120, label:'120'},
    {value: 130, label:'130'},
    {value: 140, label:'140'},
    {value: 150, label:'150'},
    {value: 200, label:'200'},
    {value: 300, label:'300'},
    {value: 500, label:'500'}
]
