import { UPDATE_PLACE_SEARCH, UPDATE_PLACE_SEARCH_FIELDS, CREATE_PLACE_SEARCH } from '../../actions/placeSearches'
import { cloneDeep } from 'lodash'
import { deserialize } from '../utils/deserialize'
import { SET_PLACE_SEARCHES } from '../../actions/experimentSearches'

const initialState = []

export default (state = initialState, action) => {
  const placeSearches = cloneDeep(state)

  switch (action.type) {
    case SET_PLACE_SEARCHES:
      return deserialize(action.payload)
    case CREATE_PLACE_SEARCH:
      const newPlaceSearches = state.concat(deserialize(action.payload))
      
      return newPlaceSearches
    case UPDATE_PLACE_SEARCH:
      const index = placeSearches.findIndex(ps => ps.id === action.placeSearch.id)
      placeSearches[index] = action.placeSearch
      const newUpdatedPlaceSearches = placeSearches

      return newUpdatedPlaceSearches
    case UPDATE_PLACE_SEARCH_FIELDS:
      const objIndex = placeSearches.findIndex(ps => ps.id === action.placeSearch.id)
      placeSearches[objIndex] = {...placeSearches[objIndex], ...action.fields}
      return placeSearches
    default:
      return state
  }
}
