import { SET_USER } from '../../actions/experimentSearches'
import { deserialize } from '../utils/deserialize'

const initialState = []

export const UPDATE_USER = 'UPDATE_USER'

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return deserialize(action.payload)
    case UPDATE_USER:
      return deserialize(action.payload)
    default:
      return state
  }
}
