import React, {Component} from 'react'
import Button from '@material-ui/core/Button'

class ButtonComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    const { className, onClickFunction, icon, label, href } = this.props

    return(
      <div className={className}>
        <Button
          style={{padding: "0px 0px", minHeight: "0px"}}
          variant="outlined"
          color="primary"
          className={className}
          href={href ? href : null}
          onClick={onClickFunction}>
          {icon}&nbsp;
          {label}
        </Button>
      </div>
    )
  }
}
export default ButtonComponent
