import { UPDATE_USER } from './user'
import { SET_USER } from '../../actions/experimentSearches'

import { deserialize } from '../utils/deserialize'

import axios from 'axios'
import config from '../../config'

const HOST = config.HOST


export const FETCH_CREATE_USER_START = 'requestTourModal/FETCH_CREATE_USER_START'
export const FETCH_CREATE_USER_SUCCESS = 'requestTourModal/FETCH_CREATE_USER_SUCCESS'
export const FETCH_CREATE_USER_ERROR = 'requestTourModal/FETCH_CREATE_USER_ERROR'

export const FETCH_UPDATE_USER_START = 'requestTourModal/FETCH_UPDATE_USER_START'
export const FETCH_UPDATE_USER_SUCCESS = 'requestTourModal/FETCH_UPDATE_USER_SUCCESS'
export const FETCH_UPDATE_USER_ERROR = 'requestTourModal/FETCH_UPDATE_USER_ERROR'

export const FETCH_CREATE_REQUEST_START = 'requestTourModal/FETCH_CREATE_REQUEST_START'
export const FETCH_CREATE_REQUEST_SUCCESS = 'requestTourModal/FETCH_CREATE_REQUEST_SUCCESS'
export const FETCH_CREATE_REQUEST_ERROR = 'requestTourModal/FETCH_CREATE_REQUEST_ERROR'

const initialState = {
    isFetch: false,
    isFetchRequest: false,
    error: null,
    isRequestSuccesful: false,
    user: null
  }

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CREATE_USER_START:
            return {...state, isFetch: true, error: null}
        case FETCH_CREATE_USER_SUCCESS:
            return {...state, isFetch: false, error: null, user: deserialize(action.payload)}
        case FETCH_CREATE_USER_ERROR:
            return {...state, isFetch: false, error: action.payload}
        case FETCH_UPDATE_USER_START:
            return {...state, isFetch: true, error: null}
        case FETCH_UPDATE_USER_SUCCESS:
            return {...state, isFetch: false, error: null, user: deserialize(action.payload)}
        case FETCH_UPDATE_USER_ERROR:
            return {...state, isFetch: false, error: action.payload}
        case FETCH_CREATE_REQUEST_START:
            return {...state, isFetchRequest: true, error: null, isRequestSuccesful: false}
        case FETCH_CREATE_REQUEST_SUCCESS:
            return {...state, isFetchRequest: false, isRequestSuccesful: true}
        case FETCH_CREATE_REQUEST_ERROR:
            return {...state, isFetchRequest: false, error: action.payload, isRequestSuccesful: false}
        default: return state
    }
}

export let cancel = null;

export const fetchCreateRequest = (user, userId, shouldUserUpdate, request, experimentSearch, updateExperimentSearch ) => (dispatch, getState) => {
  if (userId) {
    if (shouldUserUpdate) {
    dispatch({type: FETCH_UPDATE_USER_START})
    axios.put(`${HOST}api/users/${parseInt(userId, 10)}`, user)
        .then((response) => {
          dispatch({type: UPDATE_USER, payload: response.data.data})
        })
        .catch(error => dispatch({type: FETCH_UPDATE_USER_ERROR, payload: error}))
    }
    dispatch({type: FETCH_CREATE_REQUEST_START})
    const newRequest = {
      ...request,
      user_id: userId
    }
    axios.post(`${HOST}api/showing_requests/`, newRequest)
        .then((response) => {
          dispatch({type: FETCH_CREATE_REQUEST_SUCCESS})
        })
        .catch(error => dispatch({type: FETCH_CREATE_REQUEST_ERROR, payload: error}))
  } else {
    dispatch({type: FETCH_CREATE_USER_START})
    axios.post(`${HOST}api/users/`, user)
        .then((response) => {
              // dispatch({type: FETCH_CREATE_USER_SUCCESS, payload: response.data.data})
              dispatch({type: SET_USER, payload: response.data.data})
              dispatch({type: FETCH_CREATE_REQUEST_START})
              const newRequest = {
                ...request,
                user_id: parseInt(response.data.data[0].id, 10)
              }
              const newExperimentSearch = {
                ...experimentSearch,
                user_id: parseInt(response.data.data[0].id, 10)
              }
              updateExperimentSearch(newExperimentSearch)

              axios.post(`${HOST}api/showing_requests/`, newRequest)
                  .then((response) => {
                    dispatch({type: FETCH_CREATE_REQUEST_SUCCESS})
                  })
                  .catch(error => dispatch({type: FETCH_CREATE_REQUEST_ERROR, payload: error}))
        })
        .catch(error => dispatch({type: FETCH_CREATE_USER_ERROR, payload: error}))
  }
}
