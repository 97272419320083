import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import togleShowAllMap from '../../components/TogleShowAllMap'
import howerObserver from '../../components/HowerObserver'
import ComparePageLink from '../../components/ComparePageLink'
import {titles} from '../../fixtures'
import {compareRules} from '../../fixtures'
import {workdaysPerMonth} from '../../fixtures'
import * as actions from '../../actions/experimentSearches'
import { connect } from 'react-redux'
import expansionPanel from '../../components/ExpansionPanel'

class CompareWithNextBestAlternativeList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experiments: [],
      bestOption: null,
      compareIndicators: [],
    }
  }

  componentDidMount() {
    this.formExperimentsAndListings()
  }
  componentDidUpdate(prevProps) {
    if (this.props.experimentSearches !== prevProps.experimentSearches || this.props.listings !== prevProps.listings) {
      //check which request is last and fix it
      this.formExperimentsAndListings()
    }
  }

  formSecondExperiment = (firstExperiment) => {
    const { suggestedExperiments } = this.props
    const benchmarkListingId = this.props.experimentSearches[0].benchmark_listing_id

    if (benchmarkListingId) {
      const secondExperiment = suggestedExperiments.find(item => item.listing_id === benchmarkListingId)
      return secondExperiment || {}
    }

    if (firstExperiment.rank === 1) {
      return suggestedExperiments.find(item => item.rank === 2)
    }
    if (suggestedExperiments.find(item => item.rank === 1)) {
      return suggestedExperiments.find(item => item.rank === 1)
    }
   return {}
  }

  formFirstExperiment = () => {
    const { suggestedExperiments, experimentOne } = this.props
    const benchmarkListingId = this.props.experimentSearches[0].benchmark_listing_id

    if (experimentOne) {
      return experimentOne
    }
    if ( benchmarkListingId && suggestedExperiments.find(item => item.listing_id === benchmarkListingId) && suggestedExperiments.find(item => item.listing_id === benchmarkListingId).rank === 1) {
      return suggestedExperiments.find(item => item.rank === 2)
    }
    if (suggestedExperiments.find(item => item.rank === 1)) {
      return suggestedExperiments.find(item => item.rank === 1)
    }
   return {}
  }

  formExperimentsAndListings = () => {
    const {listings } = this.props

    const firstExperiment = this.formFirstExperiment()
    const secondExperiment = this.formSecondExperiment(firstExperiment)

    const firstListing =  Object.keys(firstExperiment).length > 0 ? listings.find(item => firstExperiment.listing_id === item.id) : {}
    const secondListing = Object.keys(secondExperiment).length > 0 ? listings.find(item => secondExperiment.listing_id === item.id) : {}

    this.formExperimentsMap(firstListing, firstExperiment, secondListing, secondExperiment)
  }

  formExperimentsMap = (firstListing, firstExperiment, secondListing, secondExperiment) => {
    const benchmarkListingId = this.props.experimentSearches[0].benchmark_listing_id
    const { suggestedExperiments } = this.props

    const experiments =  [[firstListing, firstExperiment], [secondListing, secondExperiment ]].map((item, i) => {
      if (!item[0] || !item[1]) {
        return  {}
      }
      const place = this.props.places.length > 0 ? this.props.places.find(a => a.id === item[0].place_id) : {}

      return  {
        title: i === 0 ? 'первая по рейтингу' : 'с которой сравниваем',
        rank: benchmarkListingId && i === 1 && item[1].listing_id === benchmarkListingId ? 'добавленная вами квартира' : item[1].rank,
        source_title: place ? (place.street_address || place.full_address) : item[0].source_title,
        price: Math.round(item[0].price_per_month || item[0].price),
        total_rooms: item[0].total_rooms,
        square_meters: Math.round(item[0].square_meters),
        mark: 100 - Math.round((item[1].total_seconds_per_year - (suggestedExperiments.find(e => e.rank === 1) || {}).total_seconds_per_year)/3600),
        hoursPerMonthInCommute: Math.round(item[1].commute_seconds_per_year / 4320) / 10,
        minutesPerWorkdayInCommute: Math.round(item[1].commute_seconds_per_year / 43200 * 60/workdaysPerMonth),
      }})

    const bestOption = experiments[1].rank === 'добавленная вами квартира' ?  experiments[0].rank < secondExperiment.rank ? 1 : 2 : null
    const compareIndicators = [[firstListing, firstExperiment], [secondListing, secondExperiment ]].map((item, i) => {
      if (!item[0] || !item[1]) {
        return  {}
      }
      return  {
        sign: item[0].currency_code === 'USD' ? "$" : "₴",
        rentalCosts: item[1].rental_costs_seconds_per_year,
     }})
     this.setState({ experiments, bestOption, compareIndicators })
  }

  render() {
    const { toggleShowModal, experimentSearch } = this.props
    const { experiments, bestOption, compareIndicators } = this.state
    const benchmarkListingId = this.props.experimentSearches[0].benchmark_listing_id

    return (
      <div className="compare-page">
        {experiments.length > 0 && Object.keys(experiments[0]).map(field => {
          let compareCellFisrtClassName =  ((parseInt(experiments[0][field]) > parseInt(experiments[1][field]) && compareRules[field] === 'more') || (parseInt(experiments[0][field]) < parseInt(experiments[1][field]) && compareRules[field] === 'less') ) ? {backgroundColor:'#e6ffe6'} : {}
          let compareCellSecondClassName =  ((parseInt(experiments[1][field]) > parseInt(experiments[0][field]) && compareRules[field] === 'more')  || (parseInt(experiments[1][field]) < parseInt(experiments[0][field]) && compareRules[field] === 'less')) ? {backgroundColor:'#e6ffe6'}: {}

          if (benchmarkListingId && field === 'rank' && bestOption) {
            compareCellFisrtClassName = bestOption === 1 ? {backgroundColor:'#e6ffe6'} : {}
            compareCellSecondClassName =  bestOption === 1 ? {} : {backgroundColor:'#e6ffe6'}
          }
          if (benchmarkListingId && field === 'price' && bestOption) {
            compareCellFisrtClassName = compareIndicators[0].rentalCosts < compareIndicators[1].rentalCosts ? {backgroundColor:'#e6ffe6'} : {}
            compareCellSecondClassName =  compareIndicators[0].rentalCosts < compareIndicators[1].rentalCosts ? {} : {backgroundColor:'#e6ffe6'}
          }

          return <div key={field}>
            <div className='compare-bold-mini'>{titles[field]}</div>
            <hr style={{height:'1px', color:'#aaa'}}/>

            <div className='compare-row-mini'>
              <div className='compare-cell-mini' style={compareCellFisrtClassName}>
                <div>
                  <span>
                    {experiments[0][field]}
                    {field === 'price' && compareIndicators[0].sign}
                  </span>
                </div>
              </div>
              <div className='compare-cell-mini' style={compareCellSecondClassName}>
                {field === 'title' && <div style={{paddingTop: '5px'}}>
                  <span>
                    {experiments[1][field]}
                    <div>
                      <Button
                        onClick={(e)=> {toggleShowModal()}}
                        style={{minHeight: '8px'}} variant='text' color='primary' className=''>
                        Сравнить с другой квартирой
                      </Button>
                     </div>
                   </span>
                 </div>}
                 {field !== 'title' && <div>
                   <span>{experiments[1][field]}</span>
                   {field === 'price' && compareIndicators[1].sign}
                  </div>
                 }
              </div>
            </div>
          </div>
        })}
          <div className='compare-row' style={{textAlign: 'center', padding: '25px'}}>
            <ComparePageLink experimentSearch={experimentSearch} buttonWord={'сравнить другие варианты'}/>
          </div>
      </div>
    )
 }
}
const mapStateToProps = state => ({
  suggestedExperiments: state.suggestedExperiments,
  experimentSearches: state.experimentSearches,
  listings: state.listings,
  places: state.places,
})
export default connect(mapStateToProps, { ...actions })(howerObserver(togleShowAllMap(expansionPanel(CompareWithNextBestAlternativeList, 'Сравнить варианты между собой'))))
