import React, {Component} from 'react'
// import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'
// import AddAlertIcon from '@material-ui/icons/AddAlert'
// import UnsubscribeIcon from '@material-ui/icons/Unsubscribe'
import closeGreyIcon from '../../assets/closeGrey.svg'
import RequestEmailModal from '../../containers/RequestEmailModal'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class Email extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false

    }
    this.toggleShowModal = this.toggleShowModal.bind(this)
  }

  toggleShowModal() {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  render() {
    const {experimentSearch, setExperimentSearch } = this.props

    if (experimentSearch.is_subscribed) {
      return (
        <span style={{display: 'inline-block'}}>
          {/* <div> */}
            {/*<Translate value='experimentSearch.notMailMe' />*/}
            {/* отписаться от рассылки */}
          {/* </div> */}
          {/* <div className='call-buttons'> */}
            <Button
              style={{padding: "5px 5px"}}
              variant="contained"
              color="default"
              className=''
              onClick={()=>this.toggleShowModal()}
            >
              {/* <UnsubscribeIcon /> */}
              {/*<Translate value='experimentSearch.notSend'/>*/}отписаться
            </Button>
          {/* </div> */}
          {this.state.showModal &&
            <div className='request-modal'>
              <div className='request-tour request-tour-mobile'>
                <img src={closeGreyIcon} alt="" className='modal-close-btn' onClick={()=>this.toggleShowModal()} />
                <div>
                  <RequestEmailModal
                    toggleShowModal={this.toggleShowModal}
                    experimentSearch={experimentSearch}
                    setExperimentSearch={setExperimentSearch}
                  />
                </div>
              </div>
            </div>
          }
        </span> )
    } else {
      return (
        <span style={{display: 'inline-block'}}>
          {/* <div> */}
            {/*<Translate value='experimentSearch.mailMe' />*/}
            {/* Хотите ли вы получать такие предложения по email? */}
            {/* подписаться и получать квартиры на почту по этому поиску */}
          {/* </div> */}

          {/* <div className='call-buttons'> */}
            
            <Button
            style={{padding: "5px 5px"}}
            variant="contained"
            color="secondary"
            className=''
            onClick={()=>this.toggleShowModal()}
            >
              {/* <AddAlertIcon /> */}
                {/* <span className='shine-animation'>рассылка</span> */}
                <span className=''>рассылка</span>
              </Button>
          {/* </div> */}
          {this.state.showModal &&
            <div className='request-modal'>
              <div className='request-tour request-tour-mobile'>
                <img src={closeGreyIcon} alt="" className='modal-close-btn' onClick={()=>this.toggleShowModal()} />
                <div>
                  <RequestEmailModal
                    toggleShowModal={this.toggleShowModal}
                    experimentSearch={experimentSearch}
                    setExperimentSearch={setExperimentSearch}
                  />
                </div>
              </div>
            </div>
          }
        </span>
      )
    }
  }
}
export default howerObserver(togleShowAllMap(Email))
