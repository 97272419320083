import axios from 'axios'
import config from '../config'

const HOST = config.HOST

export const UPDATE_AMENITY_TRIP = 'UPDATE_AMENITY_TRIP'

export const updateAmenityTrip = (updatedAmenityTrip, index) => dispatch => {
  axios.put(`${HOST}api/amenity_trips/${updatedAmenityTrip.id}/`, updatedAmenityTrip)

  dispatch({
    type: UPDATE_AMENITY_TRIP,
    amenityTrip: updatedAmenityTrip,
    index
  })}
