import React, {Component} from 'react'
// import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'
import PhoneInTalk from '@material-ui/icons/PhoneInTalk';
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class CallProfessional extends Component {
  constructor(props) {
      super(props)
      this.state = {
        isExtended: false,
      }
  }

  toggleExtended = () =>  {
    this.setState({
      isExtended: !this.state.isExtended
    })
  }

  render() {
    const { isExtended } = this.state

    return <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
      <div>
        {/*<Translate value='experimentSearchProduct.callFreePrice' />*/}
        Цена - это то, что вы платите. Ценность - это то, что вы получаете.
      </div>
      {!isExtended && <div>
        {/*<Translate value='experimentSearchProduct.callFreeValueShort' />*/}
        Wayleap оценивает недвижимость по её способности помочь развиваться вам и вашей семье<span style={{color: '#3f51b5'}} onClick={this.toggleExtended}>...(узнать больше)</span>
      </div>}
      {isExtended && <div>
        {/*<Translate value='experimentSearchProduct.callFreeValue' />*/}
        Wayleap оценивает недвижимость по её способности помочь развиваться вам и вашей семье, чтобы предоставить вам максимум ценности за ту цену, что вы платите. Позвоните нам и мы свяжем вас с профессионалом, чтобы формализовать ваши желания, нужды и предпочтения.
      </div>}
      {isExtended && <div>
        {/*<Translate value='experimentSearchProduct.callFreeSearch' />*/}
        С учётом этого, мы сделаем, чтобы поиск недвижимости стал для вас понятным процессом, и вы чётко понимали за что платите c уверенностью, что вы нашли лучшую для себя квартиру.
      </div>}
      <div className='call-buttons'>
        <a className='' style={{textDecoration: 'none'}} href={'/about'+ window.location.search } >
          <Button style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="default" className=''>
            Узнать больше >
          </Button>
        </a>
        <div className=''>
          <Button style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="primary" className=''>
            <PhoneInTalk style={{color: "black"}}/>
            <a href="tel:+380668514359" className='' style={{color: 'black', textDecoration: 'none'}}><span >Позвонить ></span></a>
          </Button>
        </div>
      </div>
    </div>
  }
}
export default howerObserver(togleShowAllMap(CallProfessional))
