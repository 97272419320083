import React, {Component} from 'react'
import StatsBreakdownItem from '../../components/StatsBreakdownItem'
import config from '../../config'

export default class StatsBreakdown extends Component {

  ehours = (name) => {
    const { experiment } = this.props
    return Math.round(experiment[name] / 3600)
  }

  echours = (xname) => {
    const { experiment } = this.props
    return Math.round(experiment.costs.data.find(({name}) => name === xname).costs_in_seconds_per_year / 3600)
  }

  ecvalue = (xname) => {
    const { experiment } = this.props
    return Math.round(experiment.costs.data.find(({name}) => name === xname).costs_in_seconds_per_year)
  }

  hours = (number) => {
    return Math.round(number / 3600)
  }
  value = (number) => {
    return Math.round(number)
  }
  format = (n) => {
    return (n > 0) ? "+" + n : n
  }

  render() {
    const { experimentSearch, stats_debug } = this.props
    const ehours = this.ehours
    const echours = this.echours
    const ecvalue = this.ecvalue
    const hours = this.hours
    const value = this.value
    const format = this.format
    const suggested_experiments_statistics=experimentSearch.suggested_experiments_statistics
    const all_experiments_statistics=experimentSearch.all_experiments_statistics


    // const calculated_value_chosen = (suggested_experiments_statistics || {}).calculated_value || {}
    // const year_built_chosen = (suggested_experiments_statistics || {}).year_built || {}
    const year_built_aging_building_maintenance_seconds_per_year_chosen = (suggested_experiments_statistics || {}).year_built_aging_building_maintenance_seconds_per_year || {}
    const commute_hours_chosen = (suggested_experiments_statistics || {}).min_possible_total_commute_seconds_per_year || {}
    const rental_costs_chosen = (suggested_experiments_statistics || {}).rental_costs_seconds_per_year || {}



    // const calculated_value_all = ((all_experiments_statistics || {}).calculated_value || {})
    // const year_built_all = (all_experiments_statistics || {}).year_built || {}
    const year_built_aging_building_maintenance_seconds_per_year_all = (all_experiments_statistics || {}).year_built_aging_building_maintenance_seconds_per_year || {}
    const commute_hours_all = (all_experiments_statistics || {}).approximate_total_commute_seconds_per_year || {}
    const rental_costs_all = (all_experiments_statistics || {}).rental_costs_seconds_per_year || {}
  
    const avg_neighborhood_price_chosen = (suggested_experiments_statistics || {}).avg_neighborhood_price || {}
    const avg_neighborhood_price_all = (all_experiments_statistics || {}).avg_neighborhood_price || {}

    const avg_house_price_chosen = (suggested_experiments_statistics || {}).avg_house_price || {}
    const avg_house_price_all = (all_experiments_statistics || {}).avg_house_price || {}

    const avg_neighborhood_price_per_sqm_chosen = (suggested_experiments_statistics || {}).avg_neighborhood_price_per_sqm || {}
    const avg_neighborhood_price_per_sqm_all = (all_experiments_statistics || {}).avg_neighborhood_price_per_sqm || {}

    const avg_house_price_per_sqm_chosen = (suggested_experiments_statistics || {}).avg_house_price_per_sqm || {}
    const avg_house_price_per_sqm_all = (all_experiments_statistics || {}).avg_house_price_per_sqm || {}

    const approximate_costs_seconds_per_year_all = ((all_experiments_statistics || {}).approximate_costs_seconds_per_year || {})
    const total_seconds_per_year_chosen = (suggested_experiments_statistics || {}).total_seconds_per_year || {}
    const missing_space_utility_seconds_per_year_all = ((all_experiments_statistics || {}).missing_space_utility_seconds_per_year || {})
    const missing_space_utility_seconds_per_year_chosen = (suggested_experiments_statistics || {}).missing_space_utility_seconds_per_year || {}
    const secondary_effects_all = ((all_experiments_statistics || {}).secondary_effects || {})
    const secondary_effects_chosen = (suggested_experiments_statistics || {}).secondary_effects || {}
    const info_uncovered_utility_penalty_seconds_per_year_all = ((all_experiments_statistics || {}).info_uncovered_utility_penalty_seconds_per_year || {})
    const info_uncovered_utility_penalty_seconds_per_year_chosen = (suggested_experiments_statistics || {}).info_uncovered_utility_penalty_seconds_per_year || {}

    const neighborhood_benefits_seconds_per_year_chosen = (suggested_experiments_statistics || {}).neighborhood_benefits_seconds_per_year || {}
    const neighborhood_benefits_seconds_per_year_all = (all_experiments_statistics || {}).neighborhood_benefits_seconds_per_year || {}

    const house_spy_chosen = (suggested_experiments_statistics || {}).house_spy || {}
    const house_spy_all = (all_experiments_statistics || {}).house_spy || {}


    const view_spy_chosen = (suggested_experiments_statistics || {}).view_spy || {}
    const view_spy_all = (all_experiments_statistics || {}).view_spy || {}
 
    const style_spy_chosen = (suggested_experiments_statistics || {}).style_spy || {}
    const style_spy_all = (all_experiments_statistics || {}).style_spy || {}
 
    const meta_spy_chosen = (suggested_experiments_statistics || {}).meta_spy || {}
    const meta_spy_all = (all_experiments_statistics || {}).meta_spy || {}
 

    const kitchen_qlt_spy_chosen = (suggested_experiments_statistics || {}).kitchen_qlt_spy || {}
    const kitchen_qlt_spy_all = (all_experiments_statistics || {}).kitchen_qlt_spy || {}

    const kitchen_qty_spy_chosen = (suggested_experiments_statistics || {}).kitchen_qty_spy || {}
    const kitchen_qty_spy_all = (all_experiments_statistics || {}).kitchen_qty_spy || {}

    const furn_qlt_spy_chosen = (suggested_experiments_statistics || {}).furn_qlt_spy || {}
    const furn_qlt_spy_all = (all_experiments_statistics || {}).furn_qlt_spy || {}

    const furn_qty_spy_chosen = (suggested_experiments_statistics || {}).furn_qty_spy || {}
    const furn_qty_spy_all = (all_experiments_statistics || {}).furn_qty_spy || {}


    const interior_spy_chosen = (suggested_experiments_statistics || {}).interior_spy || {}
    const interior_spy_all = (all_experiments_statistics || {}).interior_spy || {}

    const exterior_spy_chosen = (suggested_experiments_statistics || {}).exterior_spy || {}
    const exterior_spy_all = (all_experiments_statistics || {}).exterior_spy || {}

    const finishes_qlt_spy_chosen = (suggested_experiments_statistics || {}).finishes_qlt_spy || {}
    const finishes_qlt_spy_all = (all_experiments_statistics || {}).finishes_qlt_spy || {}

    const bath_spy_chosen = (suggested_experiments_statistics || {}).bath_spy || {}
    const bath_spy_all = (all_experiments_statistics || {}).bath_spy || {}

    const entertain_spy_chosen = (suggested_experiments_statistics || {}).entertain_spy || {}
    const entertain_spy_all = (all_experiments_statistics || {}).entertain_spy || {}

    const comfort_spy_chosen = (suggested_experiments_statistics || {}).comfort_spy || {}
    const comfort_spy_all = (all_experiments_statistics || {}).comfort_spy || {}

    const work_spy_chosen = (suggested_experiments_statistics || {}).work_spy || {}
    const work_spy_all = (all_experiments_statistics || {}).work_spy || {}

    const fitness_spy_chosen = (suggested_experiments_statistics || {}).fitness_spy || {}
    const fitness_spy_all = (all_experiments_statistics || {}).fitness_spy || {}

    const storage_spy_chosen = (suggested_experiments_statistics || {}).storage_spy || {}
    const storage_spy_all = (all_experiments_statistics || {}).storage_spy || {}

    const security_spy_chosen = (suggested_experiments_statistics || {}).security_spy || {}
    const security_spy_all = (all_experiments_statistics || {}).security_spy || {}

    const laundry_spy_chosen = (suggested_experiments_statistics || {}).laundry_spy || {}
    const laundry_spy_all = (all_experiments_statistics || {}).laundry_spy || {}

    const updates_spy_chosen = (suggested_experiments_statistics || {}).updates_spy || {}
    const updates_spy_all = (all_experiments_statistics || {}).updates_spy || {}

    const fees_spy_chosen = (suggested_experiments_statistics || {}).fees_spy || {}
    const fees_spy_all = (all_experiments_statistics || {}).fees_spy || {}

    const utilities_spy_chosen = (suggested_experiments_statistics || {}).utilities_spy || {}
    const utilities_spy_all = (all_experiments_statistics || {}).utilities_spy || {}

    const temperature_spy_chosen = (suggested_experiments_statistics || {}).temperature_spy || {}
    const temperature_spy_all = (all_experiments_statistics || {}).temperature_spy || {}

    const noise_spy_chosen = (suggested_experiments_statistics || {}).noise_spy || {}
    const noise_spy_all = (all_experiments_statistics || {}).noise_spy || {}

    const lighting_spy_chosen = (suggested_experiments_statistics || {}).lighting_spy || {}
    const lighting_spy_all = (all_experiments_statistics || {}).lighting_spy || {}

    const spaciousness_spy_chosen = (suggested_experiments_statistics || {}).spaciousness_spy || {}
    const spaciousness_spy_all = (all_experiments_statistics || {}).spaciousness_spy || {}

    const cleanliness_spy_chosen = (suggested_experiments_statistics || {}).cleanliness_spy || {}
    const cleanliness_spy_all = (all_experiments_statistics || {}).cleanliness_spy || {}

    const privacy_spy_chosen = (suggested_experiments_statistics || {}).privacy_spy || {}
    const privacy_spy_all = (all_experiments_statistics || {}).privacy_spy || {}

    const h_security_spy_chosen = (suggested_experiments_statistics || {}).h_security_spy || {}
    const h_security_spy_all = (all_experiments_statistics || {}).h_security_spy || {}

    const doorman_spy_chosen = (suggested_experiments_statistics || {}).doorman_spy || {}
    const doorman_spy_all = (all_experiments_statistics || {}).doorman_spy || {}

    const parking_spy_chosen = (suggested_experiments_statistics || {}).parking_spy || {}
    const parking_spy_all = (all_experiments_statistics || {}).parking_spy || {}

    const h_entertain_spy_chosen = (suggested_experiments_statistics || {}).h_entertain_spy || {}
    const h_entertain_spy_all = (all_experiments_statistics || {}).h_entertain_spy || {}

    const h_fitness_spy_chosen = (suggested_experiments_statistics || {}).h_fitness_spy || {}
    const h_fitness_spy_all = (all_experiments_statistics || {}).h_fitness_spy || {}

    const h_comfort_spy_chosen = (suggested_experiments_statistics || {}).h_comfort_spy || {}
    const h_comfort_spy_all = (all_experiments_statistics || {}).h_comfort_spy || {}

    const h_work_spy_chosen = (suggested_experiments_statistics || {}).h_work_spy || {}
    const h_work_spy_all = (all_experiments_statistics || {}).h_work_spy || {}

    // const _chosen = (suggested_experiments_statistics || {}). || {}
    // const _all = (all_experiments_statistics || {}). || {}
    
    // const _chosen = (suggested_experiments_statistics || {}). || {}
    // const _all = (all_experiments_statistics || {}). || {}
    
    // const _chosen = (suggested_experiments_statistics || {}). || {}
    // const _all = (all_experiments_statistics || {}). || {}

    return(
          <div className={"apartment-item-amenities"}>
          <h4></h4>
          <ul style={{columnCount: 1}}>
          <StatsBreakdownItem
            key={'total'}
            title={'total'}
            current={ehours('total_seconds_per_year')}
            all={hours(approximate_costs_seconds_per_year_all['50%'])}
            chosen={hours(total_seconds_per_year_chosen['50%'])}
            std={hours(total_seconds_per_year_chosen['std'])}
            spread={hours(total_seconds_per_year_chosen['max']) - hours(total_seconds_per_year_chosen['min'])}
            showAdvantage={true}
            showDisadvantage={false}
            top={'хороший вариант'}
            shit={'неплохой вариант'}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'rent'}
            title={'rent'}
            current={ehours('rental_costs_seconds_per_year')}
            all={hours(rental_costs_all['50%'])}
            chosen={hours(rental_costs_chosen['50%'])}
            std={hours(rental_costs_chosen['std'])}
            spread={hours(rental_costs_chosen['max']) - hours(rental_costs_chosen['min'])}
            showAdvantage={true}
            showDisadvantage={true}
            top={'хорошая цена'}
            shit={'недешевая'}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'commute'}
            title={'commute'}
            current={echours('commute_time_total_if_car_is_an_option')}
            all={hours(commute_hours_all['50%'])}
            chosen={hours(commute_hours_chosen['50%'])}
            std={hours(commute_hours_chosen['std'])}
            spread={hours(commute_hours_chosen['max']) - hours(commute_hours_chosen['min'])}
            showAdvantage={true}
            showDisadvantage={true}
            top={'удобное место'}
            shit={'удаленное место'}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'interior'}
            title={'interior'}
            current={echours('interior_spy')}
            all={hours(interior_spy_all['50%'])}
            chosen={hours(interior_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(interior_spy_chosen['std'])}
            spread={hours(interior_spy_chosen['max']) - hours(interior_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'exterior'}
            title={'exterior'}
            current={echours('exterior_spy')}
            all={hours(exterior_spy_all['50%'])}
            chosen={hours(exterior_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(exterior_spy_chosen['std'])}
            spread={hours(exterior_spy_chosen['max']) - hours(exterior_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'sq m'}
            title={'sq m'}
            current={ehours('missing_space_utility_seconds_per_year')}
            all={hours(missing_space_utility_seconds_per_year_all['50%'])}
            chosen={hours(missing_space_utility_seconds_per_year_chosen['50%'])}
            std={hours(missing_space_utility_seconds_per_year_chosen['std'])}
            spread={hours(missing_space_utility_seconds_per_year_chosen['max']) - hours(missing_space_utility_seconds_per_year_chosen['min'])}
            showAdvantage={true}
            showDisadvantage={true}
            top={'просторная'}
            shit={'маленькая'}
            stats_debug={stats_debug}
          />

          {/* <div className="stats-breakdown-item">
            <div>commute amenities<br/>{Math.round(experiment.costs.data.find(({name}) => name === 'commute_to_amenity_locations').costs_in_seconds_per_year / 3600)}<br/></div>
          </div>

          <div className="stats-breakdown-item">
            <div>commute targets<br/>{Math.round(experiment.costs.data.find(({name}) => name === 'commute_to_exact_places').costs_in_seconds_per_year / 3600)}<br/></div>
          </div>

          <div className="stats-breakdown-item">
            <div>remoteness<br/>{Math.round(experiment.costs.data.find(({name}) => name === 'subway_based_remoteness').costs_in_seconds_per_year / 3600)}<br/></div>
          </div> */}

          {/* <StatsBreakdownItem
            key={'other total'}
            title={'other total'}
            current={echours('secondary_effects')}
            all={hours(secondary_effects_all['50%'])}
            chosen={hours(secondary_effects_chosen['50%'])}
          /> */}

          <StatsBreakdownItem
            key={'maintenance'}
            title={'maintenance'}
            current={echours('year_built_aging_building_maintenance_seconds_per_year')}
            all={hours(year_built_aging_building_maintenance_seconds_per_year_all['50%'])}
            chosen={hours(year_built_aging_building_maintenance_seconds_per_year_chosen['50%'])}
            std={hours(year_built_aging_building_maintenance_seconds_per_year_chosen['std'])}
            spread={hours(year_built_aging_building_maintenance_seconds_per_year_chosen['max']) - hours(year_built_aging_building_maintenance_seconds_per_year_chosen['min'])}
            showAdvantage={false}
            showDisadvantage={false}
            top={'новый дом'}
            shit={'чуть менее новый дом'}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'nbhd'}
            title={'nbhd'}
            current={echours('neighborhood_benefits_seconds_per_year')}
            all={hours(neighborhood_benefits_seconds_per_year_all['50%'])}
            chosen={hours(neighborhood_benefits_seconds_per_year_chosen['50%'])}
            multiplier={-1}
            std={hours(neighborhood_benefits_seconds_per_year_chosen['std'])}
            spread={hours(neighborhood_benefits_seconds_per_year_chosen['max']) - hours(neighborhood_benefits_seconds_per_year_chosen['min'])}
            showAdvantage={true}
            showDisadvantage={false}
            top={'престижный район'}
            shit={'неплохой район'}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'house'}
            title={'house'}
            current={echours('house_spy')}
            all={hours(house_spy_all['50%'])}
            chosen={hours(house_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(house_spy_chosen['std'])}
            spread={hours(house_spy_chosen['max']) - hours(house_spy_chosen['min'])}
            showAdvantage={ecvalue('house_apt_count') > 50}
            showDisadvantage={false}
            top={'престижный дом'}
            shit={'неплохой дом'}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'view'}
            title={'view'}
            current={echours('view_spy')}
            all={hours(view_spy_all['50%'])}
            chosen={hours(view_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(view_spy_chosen['std'])}
            spread={hours(view_spy_chosen['max']) - hours(view_spy_chosen['min'])}
            showAdvantage={true}
            showDisadvantage={false}
            top={'хороший вид из окна'}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'meta'}
            title={'meta'}
            current={echours('meta_spy')}
            all={hours(meta_spy_all['50%'])}
            chosen={hours(meta_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(meta_spy_chosen['std'])}
            spread={hours(meta_spy_chosen['max']) - hours(meta_spy_chosen['min'])}
            showAdvantage={false}
            showDisadvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'style'}
            title={'style'}
            current={echours('style_spy')}
            all={hours(style_spy_all['50%'])}
            chosen={hours(style_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(style_spy_chosen['std'])}
            spread={hours(style_spy_chosen['max']) - hours(style_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'kitchen_qty'}
            title={'kitchen_qty'}
            current={echours('kitchen_qty_spy')}
            all={hours(kitchen_qty_spy_all['50%'])}
            chosen={hours(kitchen_qty_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(kitchen_qty_spy_chosen['std'])}
            spread={hours(kitchen_qty_spy_chosen['max']) - hours(kitchen_qty_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'kitchen_qlt'}
            title={'kitchen_qlt'}
            current={echours('kitchen_qlt_spy')}
            all={hours(kitchen_qlt_spy_all['50%'])}
            chosen={hours(kitchen_qlt_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(kitchen_qlt_spy_chosen['std'])}
            spread={hours(kitchen_qlt_spy_chosen['max']) - hours(kitchen_qlt_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'furn_qty'}
            title={'furn_qty'}
            current={echours('furn_qty_spy')}
            all={hours(furn_qty_spy_all['50%'])}
            chosen={hours(furn_qty_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(furn_qty_spy_chosen['std'])}
            spread={hours(furn_qty_spy_chosen['max']) - hours(furn_qty_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'furn_qlt'}
            title={'furn_qlt'}
            current={echours('furn_qlt_spy')}
            all={hours(furn_qlt_spy_all['50%'])}
            chosen={hours(furn_qlt_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(furn_qlt_spy_chosen['std'])}
            spread={hours(furn_qlt_spy_chosen['max']) - hours(furn_qlt_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'finishes_qlt'}
            title={'finishes_qlt'}
            current={echours('finishes_qlt_spy')}
            all={hours(finishes_qlt_spy_all['50%'])}
            chosen={hours(finishes_qlt_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(finishes_qlt_spy_chosen['std'])}
            spread={hours(finishes_qlt_spy_chosen['max']) - hours(finishes_qlt_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'bath'}
            title={'bath'}
            current={echours('bath_spy')}
            all={hours(bath_spy_all['50%'])}
            chosen={hours(bath_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(bath_spy_chosen['std'])}
            spread={hours(bath_spy_chosen['max']) - hours(bath_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'entertain'}
            title={'entertain'}
            current={echours('entertain_spy')}
            all={hours(entertain_spy_all['50%'])}
            chosen={hours(entertain_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(entertain_spy_chosen['std'])}
            spread={hours(entertain_spy_chosen['max']) - hours(entertain_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'comfort'}
            title={'comfort'}
            current={echours('comfort_spy')}
            all={hours(comfort_spy_all['50%'])}
            chosen={hours(comfort_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(comfort_spy_chosen['std'])}
            spread={hours(comfort_spy_chosen['max']) - hours(comfort_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'work'}
            title={'work'}
            current={echours('work_spy')}
            all={hours(work_spy_all['50%'])}
            chosen={hours(work_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(work_spy_chosen['std'])}
            spread={hours(work_spy_chosen['max']) - hours(work_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'fitness'}
            title={'fitness'}
            current={0}
            current={echours('fitness_spy')}
            all={hours(fitness_spy_all['50%'])}
            chosen={hours(fitness_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(fitness_spy_chosen['std'])}
            spread={hours(fitness_spy_chosen['max']) - hours(fitness_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />
          <StatsBreakdownItem
            key={'storage'}
            title={'storage'}
            current={echours('storage_spy')}
            all={hours(storage_spy_all['50%'])}
            chosen={hours(storage_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(storage_spy_chosen['std'])}
            spread={hours(storage_spy_chosen['max']) - hours(storage_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />
          <StatsBreakdownItem
            key={'security'}
            title={'security'}
            current={echours('security_spy')}
            all={hours(security_spy_all['50%'])}
            chosen={hours(security_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(security_spy_chosen['std'])}
            spread={hours(security_spy_chosen['max']) - hours(security_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />
          <StatsBreakdownItem
            key={'laundry'}
            title={'laundry'}
            current={echours('laundry_spy')}
            all={hours(laundry_spy_all['50%'])}
            chosen={hours(laundry_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(laundry_spy_chosen['std'])}
            spread={hours(laundry_spy_chosen['max']) - hours(laundry_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'updates'}
            title={'updates'}
            current={echours('updates_spy')}
            all={hours(updates_spy_all['50%'])}
            chosen={hours(updates_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(updates_spy_chosen['std'])}
            spread={hours(updates_spy_chosen['max']) - hours(updates_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'fees'}
            title={'fees'}
            current={echours('fees_spy')}
            all={hours(fees_spy_all['50%'])}
            chosen={hours(fees_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(fees_spy_chosen['std'])}
            spread={hours(fees_spy_chosen['max']) - hours(fees_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'utilities'}
            title={'utilities'}
            current={echours('utilities_spy')}
            all={hours(utilities_spy_all['50%'])}
            chosen={hours(utilities_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(utilities_spy_chosen['std'])}
            spread={hours(utilities_spy_chosen['max']) - hours(utilities_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />


          <StatsBreakdownItem
            key={'temperature'}
            title={'temperature'}
            current={echours('temperature_spy')}
            all={hours(temperature_spy_all['50%'])}
            chosen={hours(temperature_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(temperature_spy_chosen['std'])}
            spread={hours(temperature_spy_chosen['max']) - hours(temperature_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'noise'}
            title={'noise'}
            current={echours('noise_spy')}
            all={hours(noise_spy_all['50%'])}
            chosen={hours(noise_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(noise_spy_chosen['std'])}
            spread={hours(noise_spy_chosen['max']) - hours(noise_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'lighting'}
            title={'lighting'}
            current={echours('lighting_spy')}
            all={hours(lighting_spy_all['50%'])}
            chosen={hours(lighting_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(lighting_spy_chosen['std'])}
            spread={hours(lighting_spy_chosen['max']) - hours(lighting_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'spaciousness'}
            title={'spaciousness'}
            current={echours('spaciousness_spy')}
            all={hours(spaciousness_spy_all['50%'])}
            chosen={hours(spaciousness_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(spaciousness_spy_chosen['std'])}
            spread={hours(spaciousness_spy_chosen['max']) - hours(spaciousness_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'cleanliness'}
            title={'cleanliness'}
            current={echours('cleanliness_spy')}
            all={hours(cleanliness_spy_all['50%'])}
            chosen={hours(cleanliness_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(cleanliness_spy_chosen['std'])}
            spread={hours(cleanliness_spy_chosen['max']) - hours(cleanliness_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'privacy'}
            title={'privacy'}
            current={echours('privacy_spy')}
            all={hours(privacy_spy_all['50%'])}
            chosen={hours(privacy_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(privacy_spy_chosen['std'])}
            spread={hours(privacy_spy_chosen['max']) - hours(privacy_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'h_security'}
            title={'h_security'}
            current={echours('h_security_spy')}
            all={hours(h_security_spy_all['50%'])}
            chosen={hours(h_security_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(h_security_spy_chosen['std'])}
            spread={hours(h_security_spy_chosen['max']) - hours(h_security_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'doorman'}
            title={'doorman'}
            current={echours('doorman_spy')}
            all={hours(doorman_spy_all['50%'])}
            chosen={hours(doorman_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(doorman_spy_chosen['std'])}
            spread={hours(doorman_spy_chosen['max']) - hours(doorman_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'parking'}
            title={'parking'}
            current={echours('parking_spy')}
            all={hours(parking_spy_all['50%'])}
            chosen={hours(parking_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(parking_spy_chosen['std'])}
            spread={hours(parking_spy_chosen['max']) - hours(parking_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'h_entertain'}
            title={'h_entertain'}
            current={echours('h_entertain_spy')}
            all={hours(h_entertain_spy_all['50%'])}
            chosen={hours(h_entertain_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(h_entertain_spy_chosen['std'])}
            spread={hours(h_entertain_spy_chosen['max']) - hours(h_entertain_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'h_fitness'}
            title={'h_fitness'}
            current={echours('h_fitness_spy')}
            all={hours(h_fitness_spy_all['50%'])}
            chosen={hours(h_fitness_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(h_fitness_spy_chosen['std'])}
            spread={hours(h_fitness_spy_chosen['max']) - hours(h_fitness_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'h_comfort'}
            title={'h_comfort'}
            current={echours('h_comfort_spy')}
            all={hours(h_comfort_spy_all['50%'])}
            chosen={hours(h_comfort_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(h_comfort_spy_chosen['std'])}
            spread={hours(h_comfort_spy_chosen['max']) - hours(h_comfort_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

          <StatsBreakdownItem
            key={'h_work'}
            title={'h_work'}
            current={echours('h_work_spy')}
            all={hours(h_work_spy_all['50%'])}
            chosen={hours(h_work_spy_chosen['50%'])}
            multiplier={-1}
            std={hours(h_work_spy_chosen['std'])}
            spread={hours(h_work_spy_chosen['max']) - hours(h_work_spy_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />

{/* 
          <StatsBreakdownItem
            key={''}
            title={''}
            current={echours('')}
            all={hours(_all['50%'])}
            chosen={hours(_chosen['50%'])}
            multiplier={-1}
            std={hours(_chosen['std'])}
            spread={hours(_chosen['max']) - hours(_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />


          <StatsBreakdownItem
            key={''}
            title={''}
            current={echours('')}
            all={hours(_all['50%'])}
            chosen={hours(_chosen['50%'])}
            multiplier={-1}
            std={hours(_chosen['std'])}
            spread={hours(_chosen['max']) - hours(_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />


          <StatsBreakdownItem
            key={''}
            title={''}
            current={echours('')}
            all={hours(_all['50%'])}
            chosen={hours(_chosen['50%'])}
            multiplier={-1}
            std={hours(_chosen['std'])}
            spread={hours(_chosen['max']) - hours(_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          />



          <StatsBreakdownItem
            key={''}
            title={''}
            current={echours('')}
            all={hours(_all['50%'])}
            chosen={hours(_chosen['50%'])}
            multiplier={-1}
            std={hours(_chosen['std'])}
            spread={hours(_chosen['max']) - hours(_chosen['min'])}
            showAdvantage={false}
            top={''}
            shit={''}
            stats_debug={stats_debug}
          /> */}

          {/* <div className="stats-breakdown-item">
            <div>wall type<br/>{Math.round(experiment.costs.data.find(({name}) => name === 'wall_type_noise_and_water_from_neighbors_seconds_per_year').costs_in_seconds_per_year / 3600)}<br/></div>
          </div>
          <div className="stats-breakdown-item">
            <div>ceiling height<br/>{Math.round(experiment.costs.data.find(({name}) => name === 'ceiling_height_penalty_on_creativity_seconds_per_year').costs_in_seconds_per_year / 3600)}<br/></div>
          </div> */}

          {/* <StatsBreakdownItem
            key={'info'}
            title={'info'}
            current={echours('info_uncovered_utility_penalty_seconds_per_year')}
            all={hours(info_uncovered_utility_penalty_seconds_per_year_all['50%'])}
            chosen={hours(info_uncovered_utility_penalty_seconds_per_year_chosen['50%'])}
          /> */}

          {/* <StatsBreakdownItem
            key={`nbhd(${ecvalue('neighborhood_apt_count')})`}
            title={`nbhd(${ecvalue('neighborhood_apt_count')})`}
            current={ecvalue('avg_neighborhood_price')}
            all={value(avg_neighborhood_price_all['50%'])}
            chosen={value(avg_neighborhood_price_chosen['50%'])}
            multiplier={-1}
          /> */}
          
          {/* <StatsBreakdownItem
            key={`house(${ecvalue('house_apt_count')})`}
            title={`house(${ecvalue('house_apt_count')})`}
            current={ecvalue('avg_house_price')}
            all={value(avg_house_price_all['50%'])}
            chosen={value(avg_house_price_chosen['50%'])}
            multiplier={-1}
          />

          <StatsBreakdownItem
            key={`nbhd/sqm(${ecvalue('neighborhood_apt_count')})`}
            title={`nbhd/sqm(${ecvalue('neighborhood_apt_count')})`}
            current={ecvalue('avg_neighborhood_price_per_sqm')}
            all={value(avg_neighborhood_price_per_sqm_all['50%'])}
            chosen={value(avg_neighborhood_price_per_sqm_chosen['50%'])}
            multiplier={-1}
          /> */}

          {/* <StatsBreakdownItem
            key={'house/sqm'}
            title={'house/sqm'}
            current={ecvalue('avg_house_price_per_sqm')}
            all={value(avg_house_price_per_sqm_all['50%'])}
            chosen={value(avg_house_price_per_sqm_chosen['50%'])}
            multiplier={-1}
          /> */}

          {/* <StatsBreakdownItem
            key={}
            title={}
            current={}
            all={}
            chosen={}
          /> */}


        </ul>



          {/* <div className="stats-breakdown-item">
            <div>rooms count<br/>{Math.round(experiment.costs.data.find(({name}) => name === 'missing_rooms_utility_seconds_per_year').costs_in_seconds_per_year / 3600)}<br/></div>
          </div>
          <div className="stats-breakdown-item">
            <div><br/><br/></div>
          </div> */}


          {/* <span>
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>listing: {listing.id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>place: {place.id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>experiment: {experiment.id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>aggregator: {listing.aggregator_listing_id}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>rent_hours/year: {Math.round(experiment.rental_costs_seconds_per_year / 360) / 10}
        </span>

        {experiment.costs && <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>commute_hours/year: {Math.round(experiment.commute_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> from which to amenities: {Math.round(experiment.costs.data.find(({name}) => name === 'commute_to_amenity_locations').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> to exact places: {Math.round(experiment.costs.data.find(({name}) => name === 'commute_to_exact_places').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> subway-based remoteness: {Math.round(experiment.costs.data.find(({name}) => name === 'subway_based_remoteness').costs_in_seconds_per_year / 360) / 10}
        </span>}

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>missing_space_utility_hours/year: {Math.round(experiment.missing_space_utility_seconds_per_year / 360) / 10}
        </span>

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>
          <CachedIcon style={{display: 'inline-block', fontSize: '0.7rem', verticalAlign: 'text-bottom'}} />
          <PublishedTimeAgo last_updated={listing.listing_updated_at} fontSize={'0.7rem'}/>
        </span>

        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_in_usd_per_year') &&
            experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_usd_per_year') &&
            experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car') &&
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span> whether to buy or sell car: <span style={{fontSize: '0.7rem'}}>{experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car').value}</span>
          <span className='miniseparator'>•</span> <span className='wayleap-logo'>Wayleap</span> score bumped by: {Math.round((
            experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_seconds_per_year').costs_in_seconds_per_year -
              experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_seconds_per_year').costs_in_seconds_per_year)/360.0)/10.0}
          <span className='miniseparator'>~</span> {Math.round(
            experiment.costs.data.find(({name}) => name === 'amortization_free_car_value_in_usd_per_year').in_usd_per_year -
            experiment.costs.data.find(({name}) => name === 'car_amortization_regardless_of_the_usage_costs_in_usd_per_year').in_usd_per_year)}$
        </span>}

        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'secondary_effects') &&
            experiment.costs.data.find(({name}) => name === 'year_built_aging_building_maintenance_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'wall_type_noise_and_water_from_neighbors_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'ceiling_height_penalty_on_creativity_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'info_uncovered_utility_penalty_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'missing_rooms_utility_seconds_per_year') &&
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>secondary_effects_hours/year: {Math.round(experiment.costs.data.find(({name}) => name === 'secondary_effects').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> from which year_built: {Math.round(experiment.costs.data.find(({name}) => name === 'year_built_aging_building_maintenance_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> wall_type: {Math.round(experiment.costs.data.find(({name}) => name === 'wall_type_noise_and_water_from_neighbors_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> ceiling_height: {Math.round(experiment.costs.data.find(({name}) => name === 'ceiling_height_penalty_on_creativity_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> info: {Math.round(experiment.costs.data.find(({name}) => name === 'info_uncovered_utility_penalty_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span> missing rooms: {Math.round(experiment.costs.data.find(({name}) => name === 'missing_rooms_utility_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
        </span>}


        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'upper_secondary_school_amenity_commute_seconds_per_year') &&
            experiment.costs.data.find(({name}) => name === 'upper_secondary_school_quality_penalty') &&
            experiment.costs.data.find(({name}) => name === 'upper_secondary_school_score') &&
            experiment.costs.data.find(({name}) => name === 'upper_secondary_school_short_name') &&

        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span>upper_secondary_school_commute_hours/year: {Math.round(experiment.costs.data.find(({name}) => name === 'upper_secondary_school_amenity_commute_seconds_per_year').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span>upper_secondary_school_quality_penalty: {Math.round(experiment.costs.data.find(({name}) => name === 'upper_secondary_school_quality_penalty').costs_in_seconds_per_year / 360) / 10}
          <span className='miniseparator'>•</span>upper_secondary_school_score: {Math.round(experiment.costs.data.find(({name}) => name === 'upper_secondary_school_score').value)}
          <span className='miniseparator'>•</span>upper_secondary_school_short_name: {experiment.costs.data.find(({name}) => name === 'upper_secondary_school_short_name').value}
        </span>}

        { experiment.costs &&
          experiment.costs.data.find(({name}) => name === 'not_preferred_location_penalty') &&
        <span style={{fontSize: '0.7rem'}}>
          <span className='miniseparator'>•</span> not_preferred_location_penalty: {Math.round(experiment.costs.data.find(({name}) => name === 'not_preferred_location_penalty').costs_in_seconds_per_year / 3600)}
        </span>} */}




      </div>
    )
  }
}
