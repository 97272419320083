import axios from 'axios'
import config from '../config'
import { setLocale } from 'react-redux-i18n'
import { FETCH_UPDATE_USER_START, FETCH_UPDATE_USER_ERROR,
  FETCH_CREATE_USER_START,
  FETCH_CREATE_USER_ERROR } from '../store/reducers/requestTourModal'
import { UPDATE_USER } from '../store/reducers/user'
import queryString from 'query-string'
import Rollbar from 'rollbar'

export const CREATE_EXPERIMENT_SEARCH = 'CREATE_EXPERIMENT_SEARCH'
export const UPDATE_EXPERIMENT_SEARCH = 'UPDATE_EXPERIMENT_SEARCH'
export const SET_EXPERIMENT_SEARCH = 'SET_EXPERIMENT_SEARCH'
export const UPDATE_SUGGESTED_EXPERIMENTS = 'UPDATE_SUGGESTED_EXPERIMENTS'
export const UPDATE_LISTINGS = 'UPDATE_LISTINGS'
export const UPDATE_LISTING = 'UPDATE_LISTING'
export const SET_LISTING = 'SET_LISTING'
export const UPDATE_PLACES = 'UPDATE_PLACES'
export const UPDATE_LISTING_ENTITIES = 'UPDATE_LISTING_ENTITIES'
export const UPDATE_PLACE_SEARCHES = 'UPDATE_PLACE_SEARCHES'
export const SET_ACTIVE = 'SET_ACTIVE'
export const SET_LOADING = 'SET_LOADING'
export const SET_AMENITIES = 'SET_AMENITIES'
export const SET_AMENITY_LOCATIONS = 'SET_AMENITY_LOCATIONS'
export const SET_AMENITY_TRIPS = 'SET_AMENITY_TRIPS'
export const SET_PLACE_SEARCHES = 'SET_PLACE_SEARCHES'
export const SET_PLACES = 'SET_PLACES'
export const ADD_PLACES = 'ADD_PLACES'
export const SET_AMENITY_ROUTES = 'SET_AMENITY_ROUTES'
export const FETCH_EXPERIMENT = 'FETCH_EXPERIMENT'
export const SET_USER = 'SET_USER'
export const SET_ROUTE_STOPS = 'SET_ROUTE_STOPS'
export const SET_EXPECTATIONS = 'SET_EXPECTATIONS'

const HOST = config.HOST
// const language = window.navigator.languages.some(item => ['ru', 'ua', 'ru-RU', 'ru-UA', 'uk-UA', 'be-BY' ].includes(item)) ? config.LANGUAGE.RU : config.LANGUAGE.ENG
const language = config.LANGUAGE.RU

export const createExperimentSearch = (params={}) => dispatch => {
  const contextId = queryString.parse(window.location.search).c
  if (contextId) {
    return axios.get(`${HOST}api/experiment_searches/${contextId}/`).then(response => {
    const [experimentSearch] = response.data.data
    dispatch([
      setLocale(experimentSearch.attributes.locale),
      { type: SET_AMENITIES, payload: response.data.included.filter(item => item.type === 'amenity')},
      { type: UPDATE_SUGGESTED_EXPERIMENTS, payload: response.data.included.filter(item => item.type === 'suggested_experiment')},
      { type: ADD_PLACES, payload: response.data.included.filter(item => item.type === 'place')},
      { type: UPDATE_LISTINGS, payload: response.data.included.filter(element => element.type === 'listing')},
      { type: UPDATE_LISTING_ENTITIES, payload: response.data.included.filter(element => element.type === 'listing_entity')},
      { type: SET_AMENITY_ROUTES, payload: response.data.included.filter(element => element.type === 'amenity_route')},
      { type: SET_AMENITY_TRIPS, payload: response.data.included.filter(item => item.type === 'amenity_trip') },
      { type: SET_PLACE_SEARCHES, payload: response.data.included.filter(item => item.type === 'place_search') },
      { type: SET_ACTIVE, payload: experimentSearch.id },
      { type: SET_USER, payload: response.data.included.filter(item => item.type === 'user') },
      { type: SET_AMENITY_LOCATIONS, payload: response.data.included.filter(item => item.type === 'amenity_location') },
      { type: UPDATE_EXPERIMENT_SEARCH, payload: response.data.data }
    ])
  })
}

  const isRealClient = (window.location.hostname === "www.imbadom.com") || (window.location.hostname === "imbadom.com") || (window.location.hostname === "www.wayleap.com") || (window.location.hostname === "wayleap.com")
  const keyword = queryString.parse(window.location.search).q
  const isUAH = params.currency_bias === 'UAH'
  const price = params.price_per_month_max

  return axios.post(`${HOST}api/experiment_searches/`, { experiment_search: { ...params, is_production: isRealClient, keyword: keyword, locale: language, limit_results_to_city_bias: true, earnings_per_hour_in_future: isUAH ? ((price || 2620*27.98) - 120*27.98)/40 : ((price || 2620) - 120)/40 } }).then(response => {
    const [experimentSearch] = response.data.data
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?c=${experimentSearch.id}`
    window.history.pushState({ path: newurl }, '', newurl)
    dispatch([
      setLocale(experimentSearch.attributes.locale),
      { type: SET_AMENITIES, payload: response.data.included.filter(item => item.type === 'amenity')},
      { type: UPDATE_SUGGESTED_EXPERIMENTS, payload: response.data.included.filter(item => item.type === 'suggested_experiment')},
      { type: ADD_PLACES, payload: response.data.included.filter(item => item.type === 'place')},
      { type: UPDATE_LISTINGS, payload: response.data.included.filter(element => element.type === 'listing')},
      { type: UPDATE_LISTING_ENTITIES, payload: response.data.included.filter(element => element.type === 'listing_entity')},
      { type: SET_AMENITY_ROUTES, payload: response.data.included.filter(element => element.type === 'amenity_route')},
      { type: SET_AMENITY_TRIPS, payload: response.data.included.filter(item => item.type === 'amenity_trip') },
      { type: SET_AMENITY_LOCATIONS, payload: response.data.included.filter(item => item.type === 'amenity_location') },
      { type: SET_PLACE_SEARCHES, payload: response.data.included.filter(item => item.type === 'place_search') },
      { type: SET_ACTIVE, payload: experimentSearch.id },
      { type: SET_USER, payload: response.data.included.filter(item => item.type === 'user') },
      { type: UPDATE_EXPERIMENT_SEARCH, payload: response.data.data }
    ])
  })
}

export const setExperimentSearch = (experimentSearch) => dispatch => {
  dispatch({type: SET_EXPERIMENT_SEARCH, payload: [ experimentSearch ]}) }

export const updateExperimentSearch = (experimentSearch, isSetPlaceSearches=true) => dispatch => {
  axios.put(`${HOST}api/experiment_searches/${experimentSearch.id}/`, experimentSearch)
  .then(response => {
    const [experimentSearch] = response.data.data

    dispatch([
      setLocale(experimentSearch.attributes.locale),
      // { type: SET_AMENITY_TRIPS, payload: response.data.included.filter(item => item.type === 'amenity_trip')},
      // { type: SET_AMENITY_LOCATIONS, payload: response.data.included.filter(item => item.type === 'amenity_location')},
      // { type: SET_AMENITIES, payload: response.data.included.filter(item => item.type === 'amenity')},
      // { type: SET_PLACES, payload: response.data.included.filter(item => item.type === 'place')},
      // { type: SET_USER, payload: response.data.included.filter(item => item.type === 'user')},
      { type: UPDATE_EXPERIMENT_SEARCH, payload: response.data.data}
    ])
     // isSetPlaceSearches && dispatch({ type: SET_PLACE_SEARCHES, payload: response.data.included.filter(item => item.type === 'place_search') })
    })
  }

export const performExperimentSearch = (experimentSearch, updateLocalExperimentSearch=true) => dispatch => {
  const contextId = parseInt(queryString.parse(window.location.search).c, 10)
  const isUAH = experimentSearch.currency_bias === 'UAH'
  const price = experimentSearch.price_per_month_max
  return axios.put(`${HOST}api/experiment_searches/${experimentSearch.id}/refresh`, {...experimentSearch, earnings_per_hour_in_future: isUAH ? ((price || 2620*27.98) - 120*27.98)/40 : ((price || 2620) - 120)/40})
  // return axios.put(`${HOST}api/experiment_searches/${experimentSearch.id}/refresh`, experimentSearch)
    .then(response => {

      const [experimentSearch] = response.data.data

      if (updateLocalExperimentSearch) {
        dispatch({ type: CREATE_EXPERIMENT_SEARCH, payload: response.data.data})
      }
      if (contextId !== experimentSearch.id) {
        const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?c=${experimentSearch.id}`
        window.history.pushState({ path: newurl }, '', newurl)
      }
      if (!!response.data.included) {
        dispatch([
          { type: SET_AMENITY_LOCATIONS, payload: response.data.included.filter(item => item.type === 'amenity_location')},
          { type: SET_AMENITY_ROUTES, payload: response.data.included.filter(element => element.type === 'amenity_route')},
          { type: ADD_PLACES, payload: response.data.included.filter(element => element.type === 'place')},
          { type: SET_ACTIVE, payload: experimentSearch.id },
          { type: UPDATE_LISTINGS, payload: response.data.included.filter(element => element.type === 'listing')},
          { type: UPDATE_LISTING_ENTITIES, payload: response.data.included.filter(element => element.type === 'listing_entity')},
          { type: UPDATE_SUGGESTED_EXPERIMENTS, payload: response.data.included.filter(item => item.type === 'suggested_experiment')},
        ])
      } else {
        Rollbar.critical('Included array is empty')
      }
    })
  }

export const fetchRouteStops = () => dispatch => {
  axios.get(`${HOST}api/route_stops`)
    .then(response => {
    dispatch({ type: SET_ROUTE_STOPS, payload: response.data.data })
  })
}

export const fetchExpectations = (params) => dispatch => {
  axios.get(`${HOST}api/expectations`, {params: {offset: params['offset'], limit: params['limit'], type: params['type'] }})
    .then(response => {
    dispatch({ type: SET_EXPECTATIONS, payload: response.data.data })
  })
}

export const fetchExperiment = (id) => dispatch => {
  axios.get(`${HOST}api/suggested_experiments/${id}`)
    .then(response => {
    dispatch({ type: FETCH_EXPERIMENT, payload: response.data })
  })
}

export const setLoading = isLoading => dispatch => {
  dispatch({ type: SET_LOADING, payload: isLoading})
}

export const setListing = (listing_id, option) => dispatch => {
  axios.put(`${HOST}api/listings/${listing_id}/`, option).then((response) => {
    dispatch({ type: SET_LISTING, payload: response.data })
    localStorage.clear('persist:persistedStore') 
    // this.props.performExperimentSearch(experimentSearch, false)
  })
}
export const sendSubscribeRequest = ( userId, shouldUserUpdate, user, experimentSearch, updateExperimentSearch) => dispatch => {
  if (userId) {
    if (shouldUserUpdate) {
    dispatch({type: FETCH_UPDATE_USER_START})
    axios.put(`${HOST}api/users/${parseInt(userId, 10)}`, user)
        .then((response) => {
          dispatch({type: UPDATE_USER, payload: response.data.data})
        })
        .catch(error => dispatch({type: FETCH_UPDATE_USER_ERROR, payload: error}))
    }
    const newExperimentSearch = experimentSearch
    newExperimentSearch.is_subscribed = true
    updateExperimentSearch(newExperimentSearch)
  } else {
    dispatch({type: FETCH_CREATE_USER_START})
    axios.post(`${HOST}api/users/`, user)
        .then((response) => {
              dispatch([
                // {type: FETCH_CREATE_USER_SUCCESS, payload: response.data.data},
                {type: SET_USER, payload: response.data.data},
              ])
              const newExperimentSearch = {
                ...experimentSearch,
                user_id: parseInt(response.data.data[0].id, 10)
              }
              newExperimentSearch.is_subscribed=true
              updateExperimentSearch(newExperimentSearch)
        })
        .catch(error => {
          dispatch({type: FETCH_CREATE_USER_ERROR, payload: error})
        }
        )
   }
}
