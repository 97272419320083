import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button'

import * as actions from '../../actions/experimentSearches'
import config from '../../config'
import NearbyAmenities from '../../components/NearbyAmenities'
import NearbyMetroStationOrStreetName from '../../components/NearbyMetroStationOrStreetName'
import Highlights from '../../components/Highlights'
import Buttons from '../../components/Buttons'
import ImagesGallery from '../../components/ImagesGallery'
import RecommendationRankAndAddress from '../../components/RecommendationRankAndAddress'
import AgentApartmentsInfo from '../../components/AgentApartmentsInfo'
import AgentLinks from '../../components/AgentLinks'
import ApartmentsInfo from '../../components/ApartmentsInfo'
import howerObserver from '../../components/HowerObserver'
import likeFilled from '../../assets/heartFilled.svg'
import mapIcon from '../../assets/map.png'

import Fab from '@material-ui/core/Fab';
import FavoriteIcon from '@material-ui/icons/Favorite';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

class ExperimentItem extends PureComponent {
  constructor(props) {
      super(props)
      this.state = {
        isShowDetailsExpanded: false,
        isNoLongerAvailable: false,
        isShowInactive: false,
        agentPhoneNumberCopied: false,
        isAgent: config.AGENT || window.location.hostname === "agent.imbadom.com" || window.location.hostname === "agent.wayleap.com",
        showRequestModal: false,
        experiment: {},
        place: {},
        listing: {},
        amenityLocations: [],
        experimentSearch: {},
        amenityRoutes: {},
        listingPhotos: [],
        listingEntity: {},
        isSet: false,
        isActive: true,
        isScam: false
      }
    this.setInactive = this.setInactive.bind(this)
  }
  componentDidMount() {
    const {dataId, scrollToElement, lastId } = this.props
    if (dataId === lastId) {
      scrollToElement()
    }
  }

  showOnMap = (place) => {
    const [ experimentSearch ] = this.props.experimentSearches
    this.props.setExperimentSearch({
      ...experimentSearch,
      map_latitude: place.latitude,
      map_longitude: place.longitude,
      map_zoom: 14
    })
    if (!this.props.experimentSingle) {
      this.props.toggleShowRight()
    }
  }

  toggleShowModal() {
    this.setState({
      showRequestTourModal: !this.state.showRequestTourModal
    })
  }
  setInactive(name, value) {
    this.setState({
      [name]: value
    })
  }

  setExperiment = (experimentListItem, places, listings, amenityRoutes, listingEntities, experimentSearches, suggestedExperiments, amenityLocations) => {
    const place = places.find(place => place.id === experimentListItem.place_id)
    const listing = listings.find(listing => listing.id === experimentListItem.listing_id)
    const [ experimentSearch ] = experimentSearches
    this.setState({
      place,
      amenityRoutes: amenityRoutes.filter(amenityRoute => amenityRoute.place_id === place.id),
      experiment: experimentListItem,
      listing,
      listingPhotos: listing.photo_urls,
      listingEntity: listingEntities.find(entity => entity.id === experimentListItem.listing_entity_id),
      experimentSearch,
      isSet: true
    })
  }

  componentDidUpdate(prevProps) {
     if (this.props.isHovering !== prevProps.isHovering && this.props.isHovering) {
       this.props.setExperimentItemIdOnScroll(this.props.experimentListItem.id, true)
     }
     if (this.props.isHovering !== prevProps.isHovering && !this.props.isHovering) {
       this.props.setExperimentItemIdOnScroll(this.props.experimentListItem.id, false)
     }
   }

  render() {
    const { experimentListItem, listings, listingEntities, places, amenityRoutes, amenities, amenityLocations, isNew, toggleShowRight, setExperimentItemIdOnScroll, placeSearches } = this.props
    const [ experimentSearch ] = this.props.experimentSearches
    const experiment = experimentListItem
    const { isScam, isActive, isAgent } = this.state

    const listing =  listings.find(listing => listing.id === experiment.listing_id)
    const place = places.find(place => place.id === experiment.place_id)

    const DISPLAY_VALUE_BREAKDOWN = config.DISPLAY_VALUE_BREAKDOWN && ((window.location.hostname === "demo.imbadom.com") || (window.location.hostname === "demo.wayleap.com") || (window.location.hostname === "localhost") )

    if (!listing || !place) {
      return (<span/>)
    }
    if (Object.keys(experimentSearch).length <= 0 ) {
      return (<span/>)
    }

    // const amenityRoutes = amenityRoutes.filter(amenityRoute => amenityRoute.place_id === place.id)
    const listingEntity = listingEntities.find(entity => entity.id === experiment.listing_entity_id)
    const isInFavourites = experimentSearch.favorited_listing_ids && experimentSearch.favorited_listing_ids.includes(`${experiment.listing_id}`)

    return (
      <div style={{ paddingTop: '15px' }}>
        {this.state.isNoLongerAvailable && <div>Фотографии не подгружаются. Возможно есть проблемы с интернет соединением.</div>}

        {/*
          {(listing.is_scam || isScam) && <div>Объявление помечено как мошенническое</div>}
        */}

        {((listing.is_active === false || isActive === false) && !this.state.isShowInactive) && 
            <div  className='recommendation middle-block_row-wrap_list middle-block_row-wrap'>
            <span className="ranking">
              № {experiment.rank}
            </span>
        <span onClick={() => this.setState({isShowInactive: true})} style={{cursor: 'pointer', textDecoration: 'underline'} } className="new-construction" >Предложение не актуально. Нажмите, чтобы посмотреть либо повторите поиск.</span>
          </div>
        }
     
     
        {(listing.is_scam || isScam) && <div></div>}
        {!this.state.isNoLongerAvailable && !listing.is_scam && (listing.is_active !== false || this.state.isShowInactive) && isActive && !isScam &&
          <div className='middle-block_row-wrap_list middle-block_row-wrap' style={this.state.isShowInactive ? { paddingTop: 5, backgroundColor: 'lightgray' } : { paddingTop: 5 }} id={this.props.dataId}>
            {this.state.isShowInactive && <span style={{color: 'red'}}>Это предложение неактуально</span>}
            <div style={{display: 'flex'}}>
            
              <RecommendationRankAndAddress
                experiment={experiment}
                listing={listing}
                place={place}
                isAgent={isAgent}
                amenityRoutes={amenityRoutes}
                amenities={amenities}
                amenityLocations={amenityLocations}
                placeSearches={placeSearches}
                experimentSearch={experimentSearch}
                places={places}
              />
              {config.SHOW_SHIT && isNew && <span className='new-ad-highlight' style={{color: 'orange', marginLeft: 10}}><sup>new</sup></span>}
              {isInFavourites && <Fab size="small"  disabled={true} style={{float: 'right', marginLeft: 'auto'}} aria-label="like">
                <FavoriteIcon />
              </Fab>}
              {DISPLAY_VALUE_BREAKDOWN && <FormControlLabel style={{float: 'right', marginLeft: 'auto'}} control={<Checkbox style={{padding: '5px'}} icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />} label="" />}
              {isInFavourites && <img src={likeFilled} height={15} width={23} alt='' style={{marginLeft: 'auto'}}/>}
            </div>

            <ImagesGallery
              experiment={experiment}
              experimentSearch={experimentSearch}
              onImageLoad={this.props.onImageLoad}
              listing={listing}
              isListItem={true}
              isFirstItem={experiment.rank === 1}

            />
            <div className='middle-block_row-info'>
              <ApartmentsInfo
                listing={listing}
                place={place}
                experiment={experiment}
                experimentSearch={experimentSearch}
                placeSearches={placeSearches}
                places={places}
                amenityRoutes={amenityRoutes}
                amenities={amenities}
                amenityLocations={amenityLocations}
              />

              {config.SHOW_SHIT && <div className='description_row_item_page'>
                <div className='description_row-item'>
                  <NearbyMetroStationOrStreetName
                    place={place}
                    amenityRoutes={amenityRoutes}
                    amenities={amenities}
                    amenityLocations={amenityLocations}
                    />
                </div>
              </div>}

              {<div className='description_row_links'>
                <div className='description_row-item'>
                  <Highlights/>
                </div>

                <div className='description_row-item'>
                  {config.SHOW_LINKS && listing.source &&
                     <AgentLinks
                       listing={listing}
                       place={place}
                       listingEntity={listingEntity}
                     />
                  }
                </div>
              </div>}
              {listing.is_active !== false && config.SHOW_SHIT &&
                 <Buttons
                   experiment={experiment}
                   listing={listing}
                   experimentSearch={experimentSearch}
                   isListView={true}
                   setExperimentSearch={this.props.setExperimentSearch}
                   updateExperimentSearch={this.props.updateExperimentSearch}
                   isAgent={isAgent}
                   setListing={this.props.setListing}
                   setInactive={this.setInactive}
                 />
              }

                {this.state.isShowDetailsExpanded &&
                    <div className='description_row time-to-object'>
                      <NearbyAmenities
                        amenityRoutes={amenityRoutes}
                        amenities={amenities}
                        amenityLocations={this.props.amenityLocations}
                        suggestedTransportationModes={experiment.costs.amenities}
                        suggestionOnWhetherToBuyOrSellCar={experiment.costs.data.find(({name}) => name === 'suggestion_on_whether_to_buy_or_sell_car').value}
                      />
                      <div className='description_row-item'>
                      </div>
                    </div>
                }


{!this.props.isShowRight &&
 <Button  

 style={{maxHeight: '30px', marginBottom: '5px', marginTop: '5px'}}
 variant="contained"
 color="primary"
 onClick={() => {
  setExperimentItemIdOnScroll(experiment.id, true);
 toggleShowRight();}}
>
            <img width={25} height={25} src={mapIcon} alt=""/>
            <span className=''>{/*I18n.t('experimentList.showOnMap')*/}Посмотреть на карте</span>
          </Button>}



                {this.state.isShowDetailsExpanded && isAgent &&
                  <div className='details-block'>
                    <div className='description_row'>
                      <div className='description_row-item listing-info'>
                        {listing.info}
                      </div>
                    </div>
                  </div>
                }
                {isAgent &&
                  <AgentApartmentsInfo
                    listing={listing}
                    listingEntity={listingEntity}
                    place={place}
                    experimentSearch={experimentSearch}
                  />
                }
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  state: state,
  experimentSearches: state.experimentSearches,
  photos: state.photos,
  listings: state.listings,
  listingEntities: state.listingEntities,
  places: state.places,
  placeSearches: state.placeSearches,
  amenityRoutes: state.amenityRoutes,
  amenities: state.amenities,
  suggestedExperiments: state.suggestedExperiments,
  amenityLocations: state.amenityLocations,
})

export default connect(mapStateToProps, { ...actions })(withRouter(howerObserver(ExperimentItem)))
