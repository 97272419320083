import React, {Component} from 'react'
// import { Translate } from 'react-redux-i18n'
import Button from '@material-ui/core/Button'
import {withRouter} from 'react-router-dom'
import togleShowAllMap from '../TogleShowAllMap'
import howerObserver from '../HowerObserver'

class CreateRenterProfile extends Component {
  render() {
    const {experimentSearch} = this.props
    return (
      <div className='infrastructure-info middle-block_row-wrap_list middle-block_row-wrap'>
        <div>
          {/* <Translate value='experimentSearch.xxxxxx' /> */}
          Быстро представьте сeбя серьезными арендаторами, привлеките внимание потенциальных арендодателей и увеличьте свои шансы на получение идеальной арендуемой недвижимости.
          {/* Quickly provide your qualifications, grab the attention of potential landlords and increase your chances of securing the perfect rental property. */}
        </div>
        <div className='call-buttons'>
          <div className=''>
            <Button
              onClick={() => this.props.history.push(`/renter-profile?c=${experimentSearch.id}`)}
              style={{padding: "5px 5px", minHeight: "35px"}} variant="text" color="primary" className=''>
              {/*<Translate value='experimentSearch.send'/>*/}Создать профиль арендатора&nbsp;>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default howerObserver(togleShowAllMap(withRouter(CreateRenterProfile)))
