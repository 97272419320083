import {
  CREATE_EXPERIMENT_SEARCH,
  UPDATE_EXPERIMENT_SEARCH,
  SET_EXPERIMENT_SEARCH
} from '../../actions/experimentSearches'
import { deserialize } from '../utils/deserialize'

const initialState = [{}]

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EXPERIMENT_SEARCH:
      return deserialize(action.payload)
    case UPDATE_EXPERIMENT_SEARCH:
      return deserialize(action.payload)
    case SET_EXPERIMENT_SEARCH:
      return action.payload
    default:
      return state
  }
}
