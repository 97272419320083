import { UPDATE_LISTING_ENTITIES } from '../../actions/experimentSearches'
import { deserialize } from '../utils/deserialize'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LISTING_ENTITIES:
      return deserialize(action.payload)
    default:
      return state
  }
}
