import React, {Component} from 'react'
import CloseIcon from '../../assets/cancelWhite.svg'
import LikeIcon from '../../assets/LikeIcon'
import config from '../../config'
import howerObserver from '../HowerObserver'

class ImagesGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classHint: 'hint',
      imgHeight: window.innerWidth < 600 ? '336px' : '336px'
    }
  }

  renderNav = (side) => (onClick, disabled) => {
    const newClassName = side === 'left' ? 'image-gallery-left-nav' : 'image-gallery-right-nav'
    const newAriaLabel = side === 'left' ? 'Previous Photo' : 'Next Photo'

    return (
      <button
        type='button'
        className={newClassName}
        onClick={onClick}
        disabled={disabled}
        aria-label={newAriaLabel}
      />
    )
  }

  renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <button
        type='button'
        className={
          `image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`}
        onClick={onClick}
        aria-label='Open Fullscreen'
      />
    )
  }
  renderItem = (item, experiment, experimentSearch, i) => {
    const {onImageLoad, isListItem} = this.props
    const { imgHeight } = this.state

    // const onImageError = this.props.onImageError || this._handleImageError
    // const onImageError = () => this.setState({isNoLongerAvailable: true})
    return (
      <div className='image-gallery-image' id={this.props.dataId} key={i}>

        {config.SHOW_LIKE_BUTTON && <LikeIcon />}
        {config.SHOW_LIKE_BUTTON && <CloseIcon />}
        {isListItem &&
        <div onClick={()=> sessionStorage.setItem('id', `${experiment.id}`)}>
          <a href={config.SHOW_INDIVIDUAL_LISTING ? `listing/?c=${experimentSearch.id}&i=${experiment.listing_id}` : null} rel="noreferrer">
            <img
              className='middle-block_row_img map_img'
              src={item.original}
              rel="noreferrer"
              alt={item.originalAlt}
              style={{ height: `${imgHeight}`}}
              srcSet={item.srcSet}
              sizes={item.sizes}
              title={item.originalTitle}
              onLoad={onImageLoad}
              onClick={this.onClick}
            />
          </a>
        </div>}
        {!isListItem &&
        <img
          className='middle-block_row_img map_img'
          src={item.original}
          rel="noreferrer"
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          title={item.originalTitle}
          onLoad={onImageLoad}
          onClick={this.onClick}
        />}

        {item.description &&
            <span className='image-gallery-description'>
              {item.description}
            </span>
        }
      </div>
    )
  }
  updateDimensions() {
    if (window.innerWidth < 600) {
      this.setState({ imgHeight: '336px' })
    } else {
      this.setState({ imgHeight: '336px' })
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  render() {
    const {listing, experiment, experimentSearch, isFirstItem} = this.props
    const {classHint, imgHeight} = this.state

    const imageGalleryCollection = listing.photo_urls ? listing.photo_urls.sort((a,b) => a- b).map(photo =>
      ({ original: photo.replace("http://", "https://"), thumbnail: photo.replace("http://", "https://"), listing_id: listing.id })) : []
    const items = imageGalleryCollection.map((item, i) => this.renderItem(item, experiment, experimentSearch, i))
    if (imageGalleryCollection.length === 0) {

      return (listing.id ? <div style={{textAlign: 'center'}}> Фотографии не были добавлены </div> : <div style={{textAlign: 'center'}}> Выберите квартиру или добавьте свою </div>)
    } else {
      return (
        <div
         key={listing.id}
         className="image-carousel"
         onClick={()=>this.setState({classHint: ''})}
         onWheel={(e)=>{
           if (e.deltaX >10) {
             this.setState({classHint: ''})}
         }}>
          <amp-carousel
            layout="fixed-height"
            height={imgHeight}
            controls
            id={listing.id}
            type="carousel">
            {items}
            {isFirstItem && listing.photo_urls.lenhth > 0 && <div className={classHint}>Проведите мышкой или свайпните влево, чтобы посмотреть фото</div>}

          </amp-carousel>
          {/* <img src={photos[0].medium} alt='' style={{ width:'100%', objectFit: 'contain', backgroundColor: 'rgba(0,0,0,0.1)'}}/> */}
        </div>
      )
    }
  }
}
export default howerObserver(ImagesGallery)
